import React from "react";

const InteractiveText = ({ variant, children, className, onClick }) => {
  return (
    <>
      {/* o	Usage: Styling for FAQ section headers or accordion headers. */}
      {variant === "FAQHeader" && (
        <p
          className={`
          ${className} font-inter font-normal leading-[1.4] tracking-[0.1px] text-[13px] sm:text-[13px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[15px] 3xl:text-[15px]`}
        >
          {children}
        </p>
      )}
      {/* o	Usage: Tabs, accordion controls • FAQ Answers */}
      {variant === "FAQAnswer" && (
        <p
          className={`
          ${className} font-inter font-normal leading-[1.4] tracking-[0.1px] text-[12px] sm:text-[12px] md:text-[13px] lg:text-[13px] xl:text-[13px] 2xl:text-[14px] 3xl:text-[14px]`}
        >
          {children}
        </p>
      )}

      {/* o	Usage: Small interactive buttons, links within text */}
      {variant === "ActionLink" && (
        <p
          className={`
          ${className} font-inter font-semibold uppercase leading-[1.4] tracking-[0.5px] text-[10px] sm:text-[10px] md:text-[11px] lg:text-[10px] xl:text-[11px] 2xl:text-[12px] 3xl:text-[12px]`}
          onClick={onClick}
        >
          {children}
        </p>
      )}
    </>
  );
};

export default InteractiveText;
