import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGraphQL } from "../../../context/GraphQLProvider";
import useAuth from "../../../Hooks/useAuth";
import { handleLogin } from "../../../utils/authUtils";
import Heading from "../../../components/common/Typography/Heading";
import Paragraph from "../../../components/common/Typography/Paragraph";
import SpecialText from "../../../components/common/Typography/SpecialText";
import MainButton from "../../../components/common/MainButton/MainButton";
import WrittenStepper from "../../../components/common/StepIndicater/WrittenStepper";
import { fetchServiceCategories } from "../../../Redux/Slice/serviceCategoriesSlice";
import StepBackButton from "../../../components/common/StepBackButton/StepBackButton";
import { setStep } from "../../../Redux/Slice/SignUp";
import Alerts from "../../../components/common/Alerts/Alerts";

const USER_TYPE = {
  CUSTOMER: "customer",
  SELLER: "seller",
};

const ProfilePreview = ({ step, lastStep, onClickHandler }) => {
  const navigate = useNavigate();
  const graphqlService = useGraphQL();
  const { setAuth } = useAuth();
  const dispatch = useDispatch();
  const [RequestStart, setRequetStart] = useState(false);
  
  // Get the entire signup state first
  const signupState = useSelector((state) => state.signup);
  const { categories } = useSelector((state) => state.serviceCategories);

  // Fetch service categories only when categories array is empty
  useEffect(() => {
    if (!categories || categories.length === 0) {
      dispatch(fetchServiceCategories(graphqlService));
    }
  }, [dispatch, graphqlService, categories]);

  // Destructure with default values to prevent undefined errors
  const {
    roleType = "",
    firstName = "",
    lastName = "",
    businessName = "",
    location = "",
    profilePicture = null,
    serviceCategoryId = "",
    startDate = ""
  } = signupState || {};

  const isCustomer = roleType === USER_TYPE.CUSTOMER;

  // Find the service category name by ID
  const serviceCategory = categories.find(cat => cat.id === serviceCategoryId);
  const serviceCategoryName = serviceCategory?.name || "";

  // Prepare display names with fallbacks
  const displayName = isCustomer 
    ? `${firstName} ${lastName}`.trim() || "User"
    : businessName || "Business";

  const handleDashboardClick = async () => {
    // Only attempt login if we have credentials
    if (signupState.email && signupState.password) {
      setRequetStart(true);
      // Add any specific fields needed for profile preview update
      
      try {
        const loginSuccess = await handleLogin(graphqlService, signupState.email, signupState.password, {
          setRequetStart,
          setAuth,
          navigate,
          dispatch
        });

        // Only call onClickHandler if login was successful
        if (loginSuccess && onClickHandler) {
          onClickHandler();
        }
      } catch (error) {
        Alerts.error(error);
      } finally {
        setRequetStart(false);
      }
    }
  };

  return (
    <>
      <Heading
        variant={"h6"}
        className={
          "flex items-center justify-center text-center mt-[60px] xl:mt-[120px] text-offblack"
        }
      >
        Confirmation & Profile preview
      </Heading>
      <div className="max-w-[400px] mx-auto mt-10 xl:mt-[60px]">
        <Paragraph
          variant={"MainParagraph"}
          className={"text-offblack text-start"}
        >
          {isCustomer ? (
            `${displayName}, your account is ready! You can now search for services, connect with businesses, and hire trusted professionals.`
          ) : (
            `${displayName}, your profile is ready! You can now explore your dashboard, complete your profile, create listings, and connect with customers.`
          )}
        </Paragraph>
      </div>
      <div className="size-[96px] mx-auto mt-5">
        {profilePicture ? (
          <img
            className="object-center rounded-full w-full h-full bg-white"
            src={profilePicture}
            alt="Profile"
          />
        ) : (
          <div className="object-center rounded-full w-full h-full bg-white" />
        )}
      </div>
      <div className="w-full text-center">
        <div className="mt-3">
          <SpecialText variant={"OccupationLocation"}>
            {displayName}
          </SpecialText>
        </div>
        {!isCustomer && serviceCategoryName && (
          <div className="mt-1">
            <SpecialText variant={"OccupationLocation"}>
              {serviceCategoryName}
            </SpecialText>
          </div>
        )}
        {location && (
          <div className="mt-1">
            <SpecialText variant={"OccupationLocation"}>
              {location}
            </SpecialText>
          </div>
        )}
        <div className="mt-1">
          <SpecialText variant={"Date"}>
            Member since {startDate ? new Date(startDate).toLocaleDateString('en-US', { month: 'short', year: 'numeric' }) : 'Mar 2025'}
          </SpecialText>
        </div>
      </div>
      <div className="flex items-center justify-between mt-10 w-full">
        <StepBackButton onClick={() => dispatch(setStep(signupState.step - 1))} />
        <MainButton 
          variant={"medium"} 
          onClick={handleDashboardClick}
          disabled={RequestStart}
        >
          {RequestStart ? "Loading..." : "Dashboard"}
        </MainButton>
      </div>
    </>
  );
};

export default ProfilePreview; 