import axios from "axios";
const LocalURL = "https://localhost:44348/api/";
const LocalImageURL = "https://localhost:44348/";
const LiveImageURL = "https://tradesposter.azurewebsites.net/";
const LiveUrl = "https://tradesposter.azurewebsites.net/api";
const instance = axios.create({ baseURL: LiveUrl });
const GraphQLLiveUrl = "https://tp-admin-api.azurewebsites.net/graphql";

export default instance;
export const ImgUrl = LiveImageURL;
export const GraphQLUrl = GraphQLLiveUrl
