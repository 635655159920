import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Login from "../../sections/Login/Login";
// import { useSelector } from "react-redux";
import Paragraph from "../common/Typography/Paragraph";
import { SignUp as SignUpRegister } from "../../sections/SignUp/SignUp";
import useAuth from "../../Hooks/useAuth";
import SecondaryHeading from "../common/Typography/SecondaryHeading";
import SpecialText from "../common/Typography/SpecialText";
import { classNames } from "primereact/utils";
import { businessCategories } from "../../sections/Home/BusinessCategories";

const Footer = () => {
  const location = useLocation();
  const [IsHomePage, setIsHomePage] = useState(
    location.pathname === "/" ? true : false
  );
  // const businessCategories = useSelector(
  //   (category) => category.BusinessCategoryReducer
  // );
  const { auth, setAuth } = useAuth();
  const [SignUp, setSignUp] = useState(false);
  const [SignIn, setSignIn] = useState(false);
  useEffect(() => {
    setIsHomePage(
      location.pathname === "/" && location.hash === "" ? true : false
    );
  }, []);
  const sortOrderByDescbyRating = (List) => {
    let sortedDsc = List.sort((a, b) => {
      return parseInt(b?.listRating) - parseInt(a?.listRating);
    });
    return sortedDsc;
  };
  useEffect(() => {
    if (SignIn || SignUp) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [SignIn, SignUp]);
  return (
    <>
      <footer
        className={
          "bg-pastalblue text-offblack pb-10 pt-20 lg:pt-20 xl:pt-40 2xl:pt-40"
        }
      >
        <div className=" bg-brightblue text-white rounded-3xl pt-10 md:pt-16 xl:pt-0 xl mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] h-auto xl:h-[450px] 2xl:h-[500px] 4k:h-[550px] flex flex-col items-center justify-center">
          <div className="flex flex-wrap justify-center w-[350px] xs:w-[410px] sm:w-[550px] md:w-full gap-10 lg:gap-10 xl:gap-16 2xl:gap-16">
            <div className="order-1 w-[150px] sm:w-auto">
              <SecondaryHeading variant={"FooterHeading"}>
                Company
              </SecondaryHeading>
              <ul className="flex flex-col mt-3">
                <li>
                  <Link to="/about-us">
                    <Paragraph variant={"FooterParagraph"}>About</Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to="HelpCentre/contact-us">
                    <Paragraph variant={"FooterParagraph"}>Contact us</Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to="/blogs">
                    <Paragraph variant={"FooterParagraph"}>Blog</Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to="/HelpCentre">
                    <Paragraph variant={"FooterParagraph"}>
                      Help Centre
                    </Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to="/privacy-policy">
                    <Paragraph variant={"FooterParagraph"}>
                      Privacy Policy
                    </Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to="/terms-&-conditions">
                    <Paragraph variant={"FooterParagraph"}>
                      Terms & Conditions
                    </Paragraph>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="order-2 w-[100px] sm:w-auto">
              <SecondaryHeading variant={"FooterHeading"}>
                Quick Links
              </SecondaryHeading>
              <ul
                className={
                  !auth?.user ? "flex flex-col mt-3" : "flex flex-col mt-1"
                }
              >
                {!auth?.user && (
                  <li className="cursor-pointer">
                    <div
                      onClick={() => {
                        setSignIn(!SignIn);
                      }}
                    >
                      <Paragraph
                        variant={"FooterParagraph"}
                        className={"cursor-pointer"}
                      >
                        Sign In
                      </Paragraph>
                    </div>
                  </li>
                )}
                <li className="mt-2">
                  <Link to={"/pricing"}>
                    <Paragraph variant={"FooterParagraph"}>
                      Pricing
                    </Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to={"/pro"}>
                    <Paragraph variant={"FooterParagraph"}>
                      Go Pro
                    </Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to={"/business-support-centre"}>
                    <Paragraph variant={"FooterParagraph"}>
                      For Businesses
                    </Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to={"/customer-support-centre"}>
                    <Paragraph variant={"FooterParagraph"}>
                      For Customers
                    </Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to={"/services-guide"}>
                    <Paragraph variant={"FooterParagraph"}>
                      Service Guide
                    </Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to={"/cost-estimation"}>
                    <Paragraph variant={"FooterParagraph"}>
                      Cost Estimate
                    </Paragraph>
                  </Link>
                </li>

                <li className="mt-2">
                  <Link to={"/councils-regulations"}>
                    <Paragraph variant={"FooterParagraph"}>
                      Council Regulations
                    </Paragraph>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="order-3 w-[150px] sm:w-auto">
              <SecondaryHeading variant={"FooterHeading"}>
                Popular Services
              </SecondaryHeading>
              <ul className="flex flex-col  mt-3">
                {/* businessCategories?.slice(0, 7)?.map((e) => { */}
                {React.Children.toArray(
                  businessCategories?.slice(0, 7)?.map((e, index) => {
                    return (
                      <>
                        <li className={classNames({ "mt-2": index > 0 })}>
                          <Paragraph variant={"FooterParagraph"}>
                            {e?.businessCategoryName}
                          </Paragraph>
                        </li>
                      </>
                    );
                  })
                )}
              </ul>
            </div>
            <div className="order-4 w-[100px] sm:w-auto">
              <SecondaryHeading variant={"FooterHeading"}>
                Popular Locations
              </SecondaryHeading>
              <ul className="flex flex-col mt-3">
                <li >
                  <Link
                    to={"/Services/available-categories-in-the-selected-city"}
                  >
                    <Paragraph variant={"FooterParagraph"}>Sydney</Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to={"/Services/available-categories-in-the-selected-city"}
                  >
                    <Paragraph variant={"FooterParagraph"}>Melbourne</Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to={"/Services/available-categories-in-the-selected-city"}
                  >
                    <Paragraph variant={"FooterParagraph"}>Brisbane</Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to={"/Services/available-categories-in-the-selected-city"}
                  >
                    <Paragraph variant={"FooterParagraph"}>Perth</Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to={"/Services/available-categories-in-the-selected-city"}
                  >
                    <Paragraph variant={"FooterParagraph"}>Adelaide</Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to={"/Services/available-categories-in-the-selected-city"}
                  >
                    <Paragraph variant={"FooterParagraph"}>
                      Gold Coast
                    </Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to={"/Services/available-categories-in-the-selected-city"}
                  >
                    <Paragraph variant={"FooterParagraph"}>Canberra</Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to={"/Services/available-categories-in-the-selected-city"}
                  >
                    <Paragraph variant={"FooterParagraph"}>Newcastle</Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to={"/Services/available-categories-in-the-selected-city"}
                  >
                    <Paragraph variant={"FooterParagraph"}>Hobart</Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link
                    to={"/Services/available-categories-in-the-selected-city"}
                  >
                    <Paragraph variant={"FooterParagraph"}>Geelong</Paragraph>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="order-5 hidden md:block">
              <SecondaryHeading variant={"FooterHeading"}>
                Follow
              </SecondaryHeading>
              <ul className="flex flex-col  mt-3">
                <li>
                  <Link to={"/"}>
                    <Paragraph variant={"FooterParagraph"}>Facebook</Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to={"/"}>
                    <Paragraph variant={"FooterParagraph"}>Instagram</Paragraph>
                  </Link>
                </li>
                <li className="mt-2">
                  <Link to={"/"}>
                    <Paragraph variant={"FooterParagraph"}>LinkedIn</Paragraph>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="sticky top-[100vh] mb-4 mt-20 lg:mt-5">
            <div className="flex items-center justify-center space-x-4 mb-1 md:hidden">
              <i className="fi fi-brands-facebook text-white text-base cursor-pointer"></i>
              <i className="fi fi-brands-instagram text-white text-base cursor-pointer"></i>
              <i className="fi fi-brands-linkedin text-white text-base cursor-pointer"></i>
            </div>
            <div className="flex justify-center">
              <i class="fi fi-rr-copyright text-[10px] mr-1"></i>
              <SpecialText variant={"FootNoteDisclaimer"}>
                2025 Trades Poster. All Rights Reserved.
              </SpecialText>
            </div>
          </div>
        </div>
      </footer>
      {SignIn && (
        <Login
          SignIn={SignIn}
          setSignIn={setSignIn}
          SignUpState={SignUp}
          setSignUpState={setSignUp}
        />
      )}

      {SignUp && (
        <SignUpRegister
          SignUpState={SignUp}
          setSignUpState={setSignUp}
          SignIn={SignIn}
          setSignIn={setSignIn}
        />
      )}
    </>
  );
};

export default Footer;
