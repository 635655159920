import React, { createContext, useContext } from "react";
import { ApolloProvider } from "@apollo/client";
import GraphQLService from "../services/GraphQLService";
import { GraphQLUrl } from "../Apiconfig";

const graphqlService = new GraphQLService(GraphQLUrl);

const GraphQLContext = createContext(null);

export const GraphQLProvider = ({ children }) => {
    return (
        <GraphQLContext.Provider value={graphqlService}>
            <ApolloProvider client={graphqlService.getClient()}>{children}</ApolloProvider>
        </GraphQLContext.Provider>
    );
};

export const useGraphQL = () => {
    const context = useContext(GraphQLContext);
    if (!context) {
        throw new Error("useGraphQL must be used within a GraphQLProvider");
    }
    return context;
};
