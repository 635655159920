import React, { useRef } from "react";
import { useSelector } from "react-redux";
import CircularButton from "../../../../components/common/MainButton/CircularButton";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from 'swiper/modules';
import PriceCard from "../../../../components/common/PriceCard/PriceCard";
import { useLocation } from "react-router-dom";

const PricingSlider = ({ showheading }) => {
  const getFreePackageDetail = useSelector((plan) =>
    plan?.PricingPlanReducer.find((item) => item.name === "FREE")
  );
  const getBusinessPackageDetail = useSelector((plan) =>
    plan?.PricingPlanReducer.find((item) => item.name === "BUSINESS")
  );
  const getPremiumPackageDetail = useSelector((plan) =>
    plan?.PricingPlanReducer.find((item) => item.name === "PREMIUM")
  );
  const swiperRef = useRef();
  const location = useLocation();
  return (
    <>
    <div>
      <Swiper
        className="w-[300px] sm:w-[350px] 2xl:w-[400px] 3xl:w-[450px]"
        slidesPerView={1}
        loop
        speed={500}
        spaceBetween={20}
        modules={[Pagination, Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        <SwiperSlide>
          <PriceCard
            bottomLine={"border-b border-b-mediumgray pb-3 sm:pb-5"}
            MainDiveClasses={
              "bg-white lg:bg-pastalblue text-offblack w-[300px] h-[450px] sm:w-[350px] sm:h-[650px] 2xl:w-[400px] 2xl:h-[650px] 3xl:w-[450px] pb-5 sm:pb-10 xl:pb-[50px]"
            }
            listClasses={"pl-[30px] 2xl:pl-[50px]"}
            pricingColor={"text-offblack"}
            tagging={true}
            label={"Basic"}
              price={"XX"}
              duration={1}
              description={
                "Upgrade to the Basic Plan and unlock more tools to grow your visibility and engage customers."
              }
              buttonText={"Upgrade to Basic"}
              listItems={[
                `All free features`,
                `3 Keywords`,
                // `1 Featured post`,
                `Unlimited regular posts`,
                `Limited portfolio gallery`,
                `Direct contact details`,
                `Certifications & success stories`,
              ]}
              // listItemsValue={[
              //   `${getPremiumPackageDetail?.regularListing}`,
              //   `${getPremiumPackageDetail?.featureListing}`,
              //   `${getPremiumPackageDetail?.noOfImages}`,
              // ]}
            />
        </SwiperSlide>
        <SwiperSlide>
          <PriceCard
            bottomLine={"border-b border-b-mediumgray pb-3 sm:pb-5"}
            MainDiveClasses={
              "bg-white lg:bg-pastalblue text-offblack w-[300px] h-[450px] sm:w-[350px] sm:h-[650px] 2xl:w-[400px] 2xl:h-[650px] 3xl:w-[450px] pb-5 sm:pb-10 xl:pb-[50px]"
            }
            listClasses={"pl-[30px] 2xl:pl-[50px]"}
            pricingColor={"text-offblack"}
            label={"Pro"}
              price={"XX"}
              duration={1}
              description={
                "Gain unmatched visibility, stand out, and grow your business with Pro’s advanced features."
              }
              buttonText={"Upgrade to Pro"}
              listItems={[
                `All basic features`,
                `Priority search placement`,
                `10x Visibility boost`,
                `Unlimited Featured posts`,
                `10 Keywords`,
                `Expanded portfolio`,
                `Analytics & insights`,
              ]}
              // listItemsValue={[
              //   `${getPremiumPackageDetail?.regularListing}`,
              //   `${getPremiumPackageDetail?.featureListing}`,
              //   `${getPremiumPackageDetail?.noOfImages}`,
              // ]}
            />
        </SwiperSlide>
      </Swiper>
        <div className="flex items-center justify-center gap-x-2 mt-5">
          <CircularButton
            variant={
              location.pathname ==="/pricing"
                ? "prev30White"
                : "prev30"
            }
            onClick={() => swiperRef.current?.slideNext()}
          />
          <CircularButton
            variant={
              location.pathname ==="/pricing"
                ? "next30White"
                : "next30"
            }
            onClick={() => swiperRef.current?.slidePrev()}
          />
        </div>

    </div>
    </>
  );
};

export default PricingSlider;
