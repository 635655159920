import * as Yup from "yup";
// export const InitialValues = {
//   email: "",
//   password: "",
//   repeatPassword: "",
// };
export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is Required")
    .label("Email"),
});
