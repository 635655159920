import { jwtDecode } from "jwt-decode";
import { LoginQuery } from "../services/LoginService";
import Alerts from "../components/common/Alerts/Alerts";
import { setUserDetails } from "../Redux/Slice/userSlice";

export const USER_TYPE = {
  CUSTOMER: "customer",
  SELLER: "seller",
};

export const handleLogin = async (graphqlService, email, password, callbacks) => {
  const { 
    setRequetStart, 
    setAuth, 
    setSignIn = () => {}, 
    navigate,
    dispatch = null
  } = callbacks;

  setRequetStart(true);

  try {
    const response = await LoginQuery(graphqlService, { email, password });
    
    if (response.loginUser?.access_token) {
      const accessToken = response.loginUser.access_token;
      const decodedToken = jwtDecode(accessToken);
      const userDetails = {
        accessToken,
        roleType: response.loginUser.roleType,
        expireAt: decodedToken.exp,
        // Add other user details as needed
        user: response.loginUser.id,
        personalEmail: response.loginUser.personalEmail,
        firstName: response.loginUser.firstName,
        lastName: response.loginUser.lastName,
        location: response.loginUser.location,
        abn: response.loginUser.abn,
        businessName: response.loginUser.businessName,
        companyName: response.loginUser.companyName,
        profilePicture: response.loginUser.profilePicture,
        personalEmail: response.loginUser.personalEmail,
        startDate: response.loginUser.startDate,
        serviceCategory: response.loginUser.serviceCategory,
        userPlan: response.loginUser.userPlan,
      };

      // Save to localStorage
      localStorage.setItem("LoginSession", JSON.stringify(userDetails));
      
      // Update auth context
      setAuth(userDetails);
      
      // Update Redux state if dispatch is provided
      if (dispatch) {
        dispatch(setUserDetails(userDetails));
      }

      // Close login modal if needed
      setSignIn(false);

      // Redirect based on role
      if (response.loginUser.roleType === USER_TYPE.SELLER) {
        navigate("/business-dashboard/dashboard", { replace: true });
      } else {
        navigate("/customer-dashboard/dashboard", { replace: true });
      }

    //   Alerts.success("Login successful!");
      return true;
    } else {
      Alerts.error(response.login.message || "Login failed");
      return false;
    }
  } catch (error) {
    Alerts.error(error.message || "Login failed");
    return false;
  } finally {
    setRequetStart(false);
  }
}; 