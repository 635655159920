export const LatestData = [
  {
    primaryCategory: "Air conditioning & Heating",
    id: 1,
    subList: [
      {
        id: 1,
        name: "HVAC Installation & Repair",
        keywords: [
          "HVAC system setup",
          "HVAC maintenance",
          "Heating and cooling installation",
          "Emergency HVAC repair",
          "Climate control solutions",
          "System replacement",
          "HVAC diagnostics",
          "Residential HVAC services",
          "Commercial HVAC installation",
          "HVAC troubleshooting",
        ],
      },
      {
        id: 2,
        name: "Air Conditioning Services",
        keywords: [
          "Aircon installation",
          "AC servicing",
          "Split system repair",
          "Ductless AC maintenance",
          "Cooling system check",
          "AC unit replacement",
          "Air conditioning upgrade",
          "Eco-friendly aircon",
          "Air filter replacement",
          "AC tune-up",
        ],
      },
      {
        id: 3,
        name: "Heating Services",
        Keywords: [
          "Heater installation",
          "Gas heating repair",
          "Radiant floor heating",
          "Electric heater maintenance",
          "Furnace servicing",
          "Boiler installation",
          "Winter heating checkup",
          "Energy-efficient heating",
          "Heat pump setup",
          "Heating system upgrade",
        ],
      },
      {
        id: 4,
        name: "Ducted & Ductless Systems",
        Keywords: [
          "Ducted aircon",
          "Mini-split systems",
          "Duct cleaning",
          "Air duct sealing",
          "Ductwork installation",
          "Multi-zone systems",
          "Ventless cooling",
          "Central ducted system",
          "Retrofit ductless units",
          "Whole-house cooling",
        ],
      },
      {
        id: 5,
        name: "Ventilation & Air Quality",
        Keywords: [
          "Air quality testing",
          "Ventilation system install",
          "Air purifier setup",
          "Humidity control",
          "Exhaust fan installation",
          "Mold prevention",
          "Fresh air ventilation",
          "Indoor air assessment",
          "Carbon monoxide monitoring",
          "Air exchanger services",
        ],
      },
      {
        id: 6,
        name: "Smart Controls & Automation",
        keywords: [
          "Smart thermostat setup",
          "Home automation HVAC",
          "Wi-Fi temperature control",
          "Remote access climate",
          "Smart heating solutions",
          "Energy monitoring devices",
          "Automated air conditioning",
          "App-controlled HVAC",
          "Learning thermostats",
          "System integration",
        ],
      },
      {
        id: 7,
        name: "Energy-Efficient & Renewable",
        keywords: [
          "Solar air conditioning",
          "Heat recovery systems",
          "Geothermal heating",
          "Energy-saving aircon",
          "Green HVAC solutions",
          "Low-energy cooling",
          "Renewable heat pumps",
          "Solar panel HVAC",
          "Eco-friendly heating",
          "Off-grid climate control",
        ],
      },
      {
        id: 8,
        name: "Commercial HVAC",
        keywords: [
          "Industrial air conditioning",
          "Commercial furnace repair",
          "Rooftop unit installation",
          "Building climate control",
          "Large-scale ventilation",
          "Warehouse heating",
          "Office AC maintenance",
          "Commercial energy audits",
          "Multi-story building HVAC",
          "Data centre cooling",
        ],
      },
      {
        id: 9,
        name: "Emergency HVAC Services",
        keywords: [
          "24/7 HVAC repair",
          "Emergency cooling service",
          "Urgent heater repair",
          "Rapid AC replacement",
          "Fast furnace fix",
          "On-call air conditioning",
          "Quick climate control",
          "Emergency air quality",
          "Immediate diagnostics",
          "After-hours service",
        ],
      },
    ],
  },
  {
    primaryCategory: "Architectural Services",
    id: 2,
    subList: [
      {
        id: 1,
        name: "Residential Design",
        keywords: [
          "Home design plans",
          "Custom house architecture",
          "Interior layout optimization",
          "Open-plan living design",
          "Modern home concepts",
          "Granny flat designs",
          "Sustainable home architecture",
          "Heritage home restoration",
          "Multi-story house plans",
          "3D home renderings",
        ],
      },
      {
        id: 2,
        name: "Commercial Architecture",
        keywords: [
          "Office building design",
          "Retail space planning",
          "Warehouse architecture",
          "Restaurant layout design",
          "Shopping centre architecture",
          "Sustainable commercial design",
          "Multi-use building plans",
          "Industrial facility planning",
          "Tenant improvement design",
          "Commercial façade renovation",
        ],
      },
      {
        id: 3,
        name: "Drafting & Planning",
        keywords: [
          "CAD drafting services",
          "Permit-ready plans",
          "Floor plan drafting",
          "Construction drawing set",
          "As-built documentation",
          "Detailed site plans",
          "Building regulation compliance",
          "Zoning approval drawings",
          "Architectural drafting solutions",
          "Council submission plans",
        ],
      },
      {
        id: 4,
        name: "Building Permits & Approvals",
        keywords: [
          "Permit application support",
          "Building code compliance",
          "Council approval management",
          "Regulatory paperwork handling",
          "Zoning permit assistance",
          "Construction permit coordination",
          "Planning permit services",
          "Heritage overlay considerations",
          "Development application preparation",
          "Building regulation consultancy",
        ],
      },
      {
        id: 5,
        name: "Heritage Conservation",
        keywords: [
          "Historical building restoration",
          "Heritage overlay assessments",
          "Conservation design plans",
          "Façade preservation",
          "Adaptive reuse architecture",
          "Period-appropriate renovations",
          "Heritage site compliance",
          "Structural integrity assessments",
          "Conservation material selection",
          "Heritage listing applications",
        ],
      },
      {
        id: 6,
        name: "Sustainable & Green Design",
        keywords: [
          "Eco-friendly building materials",
          "Passive solar design",
          "Energy-efficient architecture",
          "Sustainable construction methods",
          "Green roof design",
          "Rainwater harvesting systems",
          "Net-zero energy buildings",
          "Recycled material integration",
          "Climate-responsive design",
          "LEED certification assistance",
        ],
      },
      {
        id: 7,
        name: "Project Management",
        keywords: [
          "Construction project coordination",
          "Budget management",
          "Timeline scheduling",
          "Quality assurance checks",
          "Contractor liaison",
          "Site inspection services",
          "Progress reporting",
          "Risk management planning",
          "Resource allocation",
          "Post-construction evaluation",
        ],
      },
      { id: 8, name: "Custom Services", keywords: [] },
    ],
  },
  {
    primaryCategory: "Engineering Services",
    id: 3,
    subList: [
      {
        id: 1,
        name: "Structural Engineering",
        keywords: [
          "Structural safety checks",
          "Home foundation design",
          "Load-bearing wall analysis",
          "Wall and beam reinforcement",
          "House extension support",
          "Retaining wall engineering",
          "Deck and pergola support",
          "Pool structure assessments",
          "Seismic safety reviews",
          "Structural repair plans",
        ],
      },
      {
        id: 2,
        name: "Civil Engineering",
        keywords: [
          "Driveway and pathway design",
          "Residential drainage planning",
          "Stormwater solutions for homes",
          "Retaining wall construction",
          "Site grading and leveling",
          "Erosion prevention methods",
          "Backyard paving plans",
          "Small footpath projects",
          "Waterway and pond planning",
          "Rainwater management systems",
        ],
      },
      {
        id: 3,
        name: "Mechanical Engineering",
        keywords: [
          "Home HVAC system design",
          "Mechanical services for small builds",
          "Heat transfer solutions",
          "Energy-efficient upgrades",
          "Residential ventilation systems",
          "Water pump setup",
          "Fire safety engineering",
          "Air conditioning analysis",
          "Small boiler systems",
          "Heating and cooling solutions",
        ],
      },
      {
        id: 4,
        name: "Electrical Engineering",
        keywords: [
          "Home electrical design",
          "Circuit testing and analysis",
          "Power distribution planning",
          "Solar energy integration",
          "Lighting design for efficiency",
          "Electrical safety inspections",
          "Backup generator setup",
          "Smart home automation",
          "Energy management systems",
          "Wiring plans for renovations",
        ],
      },
      {
        id: 5,
        name: "Geotechnical Engineering",
        keywords: [
          "Soil testing for extensions",
          "Slope stability analysis",
          "Ground movement monitoring",
          "Foundation soil checks",
          "Retaining wall soil support",
          "Land inspection services",
          "Soil compaction testing",
          "Site preparation guidance",
          "Erosion risk assessments",
          "Backyard stability planning",
        ],
      },
      {
        id: 6,
        name: "Environmental Engineering",
        keywords: [
          "Home water treatment systems",
          "Waste management solutions",
          "Air quality testing for homes",
          "Pollution control measures",
          "Sustainable resource planning",
          "Rainwater harvesting design",
          "Greywater recycling ideas",
          "Environmental impact studies",
          "Eco-friendly waste solutions",
          "Backyard composting setup",
        ],
      },
      {
        id: 7,
        name: "Hydraulic Engineering",
        keywords: [
          "Flood risk assessments",
          "Water system planning",
          "Stormwater drainage design",
          "Garden irrigation setup",
          "Water flow analysis",
          "Pond and water feature design",
          "Rainwater storage solutions",
          "Driveway drainage planning",
          "Backyard water management",
          "Small dam inspections",
        ],
      },
      {
        id: 8,
        name: "Project Management",
        keywords: [
          "Home renovation management",
          "Risk assessment planning",
          "Budget control for projects",
          "Construction timeline scheduling",
          "Quality assurance checks",
          "Contractor coordination",
          "Site supervision and reporting",
          "Resource management strategies",
          "Progress tracking for builds",
          "Feasibility studies for homes",
        ],
      },
      { id: 9, name: "Custom Engineering Services", keywords: [] },
    ],
  },
  {
    primaryCategory: "Curtains, Blinds & Awnings",
    id: 4,
    subList: [
      {
        id: 1,
        name: "Blind Installation & Repair",
        keywords: [
          "Roller blinds installation",
          "Vertical blinds setup",
          "Venetian blinds repair",
          "Motorized blinds installation",
          "Blind adjustment services",
          "Blind cord safety checks",
          "Outdoor blinds installation",
          "Office blinds setup",
          "Blind reinstallation",
          "Blinds cleaning and maintenance",
        ],
      },
      {
        id: 2,
        name: "Curtain Installation & Repair",
        keywords: [
          "Custom curtain installation",
          "Curtain rod fitting",
          "Curtain hemming",
          "Curtain track installation",
          "Sheer curtains setup",
          "Heavy drape installation",
          "Curtain repair services",
          "Curtain tie-back installation",
          "Thermal curtains setup",
          "Curtain cleaning solutions",
        ],
      },
      {
        id: 3,
        name: "Awnings & Outdoor Shades",
        keywords: [
          "Retractable awning installation",
          "Fixed awning setup",
          "Patio awning repair",
          "Motorized awnings",
          "Shade sail installation",
          "Outdoor roller shades",
          "Window awning fitting",
          "Awning maintenance",
          "Waterproof awnings",
          "UV protection shades",
        ],
      },
      {
        id: 4,
        name: "Shutter Services",
        keywords: [
          "Plantation shutter installation",
          "Shutter repair services",
          "Custom shutter fitting",
          "Outdoor shutters",
          "Interior window shutters",
          "Motorized shutters",
          "Shutter cleaning",
          "Shutter hinge replacement",
          "Privacy shutters",
          "Wooden shutter maintenance",
        ],
      },
      {
        id: 5,
        name: "Custom Window Coverings",
        keywords: [
          "Bespoke blinds design",
          "Tailored curtain solutions",
          "Custom awning creation",
          "Unique shade designs",
          "Personalized window treatments",
          "Fabric selection advice",
          "Custom tracks and rods",
          "Color-matching services",
          "Home décor consultation",
          "Custom motorized shades",
        ],
      },
      {
        id: 6,
        name: "Automation & Smart Controls",
        keywords: [
          "Smart blind setup",
          "Automated curtain systems",
          "Remote-controlled shades",
          "Voice-activated blinds",
          "Timer-based curtain controls",
          "Smartphone app integration",
          "Light-sensing window coverings",
          "Automated awning systems",
          "Energy-saving window solutions",
          "Smart home window integration",
        ],
      },
      {
        id: 7,
        name: "Repair & Maintenance",
        keywords: [
          "Blind re-cording",
          "Curtain rod replacement",
          "Awning fabric replacement",
          "Shade sail tightening",
          "Motor repair for blinds",
          "Bracket replacement",
          "Track realignment",
          "Fabric stain removal",
          "Mechanism lubrication",
          "Regular maintenance checks",
        ],
      },
      {
        id: 8,
        name: "Outdoor Living Enhancements",
        keywords: [
          "Patio blinds installation",
          "Outdoor curtain setup",
          "Gazebo shade solutions",
          "Balcony privacy screens",
          "Pergola shade sails",
          "Waterproof outdoor curtains",
          "Garden awning fitting",
          "Weatherproof blinds",
          "UV-blocking shades",
          "Outdoor area transformations",
        ],
      },
      { id: 9, name: "Custom Services", keywords: [] },
    ],
  },
  {
    primaryCategory: "Bricklaying & Blockwork",
    id: 5,
    subList: [
      {
        id: 1,
        name: "Bricklaying & Blockwork",
        keywords: [
          "Brick wall construction",
          "Blockwork foundations",
          "Decorative brickwork",
          "Retaining wall bricklaying",
          "Brick fence building",
          "Garden wall blockwork",
          "Structural bricklaying",
          "Brick feature walls",
          "Residential blockwork",
          "Brick and mortar repairs",
        ],
      },
      {
        id: 2,
        name: "Brick & Block Repairs",
        keywords: [
          "Cracked brick repair",
          "Mortar repointing",
          "Brick wall restoration",
          "Block repair services",
          "Brick patching",
          "Wall crack fixes",
          "Re-mortaring joints",
          "Structural crack repair",
          "Brick resurfacing",
          "Chimney brick repair",
        ],
      },
      {
        id: 3,
        name: "Retaining Walls",
        keywords: [
          "Retaining wall construction",
          "Garden retaining walls",
          "Brick retaining walls",
          "Block retaining walls",
          "Reinforced retaining walls",
          "Tiered retaining wall design",
          "Small retaining walls",
          "Driveway retaining walls",
          "Decorative retaining walls",
          "Outdoor retaining wall solutions",
        ],
      },
      {
        id: 4,
        name: "Brick & Block Paving",
        keywords: [
          "Brick driveway paving",
          "Patio brick paving",
          "Walkway block paving",
          "Garden path paving",
          "Brick paver installation",
          "Block paving patterns",
          "Outdoor brick tiles",
          "Permeable block paving",
          "Courtyard paving",
          "Pool area paving",
        ],
      },
      {
        id: 5,
        name: "Decorative & Feature Walls",
        keywords: [
          "Decorative brick walls",
          "Interior brick features",
          "Outdoor brick feature walls",
          "Textured block walls",
          "Accent brickwork",
          "Garden feature walls",
          "Exposed brick walls",
          "Custom brick designs",
          "Brick cladding",
          "Artistic bricklaying",
        ],
      },
      {
        id: 6,
        name: "Commercial Bricklaying",
        keywords: [
          "Commercial blockwork",
          "Large-scale bricklaying",
          "Office brick walls",
          "Warehouse blockwork",
          "Retail space bricklaying",
          "Industrial brick projects",
          "Brickwork for commercial builds",
          "Concrete block installations",
          "Brickwork site preparation",
          "Structural block foundations",
        ],
      },
      {
        id: 7,
        name: "Repointing & Restoration",
        keywords: [
          "Brick repointing",
          "Heritage brick restoration",
          "Mortar restoration",
          "Wall stabilization",
          "Weathered brick fixes",
          "Repointing stone walls",
          "Chimney repointing",
          "Historic brick preservation",
          "Aging brick restoration",
          "Restoration brickwork",
        ],
      },
      {
        id: 8,
        name: "Custom Brickwork Services",
        keywords: [
          "Custom brick designs",
          "Unique blockwork patterns",
          "Tailored bricklaying projects",
          "Bespoke brick features",
          "Creative brick layouts",
          "Personalized brick walls",
          "Themed brickwork",
          "Custom-built garden walls",
          "Artistic brick and block designs",
          "Special bricklaying requests",
        ],
      },
      {
        id: 9,
        name: "Bricklaying for Outdoor Areas",
        keywords: [
          "Brick BBQ areas",
          "Outdoor kitchen brickwork",
          "Poolside bricklaying",
          "Garden edging bricks",
          "Brick patio construction",
          "Outdoor fireplace bricks",
          "Alfresco area brick walls",
          "Brick seating areas",
          "Courtyard wall bricklaying",
          "Pergola brick supports",
        ],
      },
    ],
  },
  {
    primaryCategory: "Cabinet Making",
    id: 6,
    subList: [
      {
        id: 1,
        name: "Custom Cabinet Design",
        keywords: [
          "Bespoke cabinet design",
          "Custom kitchen cabinets",
          "Tailored wardrobe design",
          "Built-in shelving units",
          "Bathroom cabinet solutions",
          "Home office cabinetry",
          "Unique storage designs",
          "Custom pantry cabinets",
          "Entertainment unit cabinets",
          "Personalized cabinet plans",
        ],
      },
      {
        id: 2,
        name: "Cabinet Installation",
        keywords: [
          "Kitchen cabinet installation",
          "Wardrobe fitting",
          "Laundry cabinet setup",
          "Bathroom vanity installation",
          "Built-in unit assembly",
          "Office cabinet installation",
          "Garage storage fitting",
          "Pantry cabinet installation",
          "Floating shelves mounting",
          "Custom cabinet placement",
        ],
      },
      {
        id: 3,
        name: "Cabinet Refacing & Refinishing",
        keywords: [
          "Cabinet door replacement",
          "Cabinet refinishing",
          "Kitchen cabinet makeover",
          "Cabinet repainting",
          "Veneer cabinet refacing",
          "Bathroom cabinet refresh",
          "Wood staining and polishing",
          "Wardrobe door upgrade",
          "Matte or gloss finish options",
          "Custom cabinet restoration",
        ],
      },
      {
        id: 4,
        name: "Kitchen Cabinetry & Renovations",
        keywords: [
          "Custom kitchen islands",
          "Cabinet layout redesign",
          "Modern kitchen cabinetry",
          "Space-saving kitchen storage",
          "Pull-out pantry solutions",
          "Soft-close cabinet hinges",
          "Drawer organizer cabinets",
          "Overhead kitchen cabinets",
          "Modular kitchen designs",
          "Luxury kitchen cabinets",
        ],
      },
      {
        id: 5,
        name: "Wardrobe & Closet Solutions",
        keywords: [
          "Walk-in wardrobe design",
          "Sliding door wardrobes",
          "Built-in closet systems",
          "Wardrobe shelving options",
          "Space-saving wardrobe units",
          "Custom closet organizers",
          "Mirrored wardrobe doors",
          "Linen closet cabinets",
          "Closet drawer installation",
          "Kids' room storage",
        ],
      },
      {
        id: 6,
        name: "Bathroom Cabinetry",
        keywords: [
          "Vanity cabinet design",
          "Floating bathroom cabinets",
          "Custom bathroom storage",
          "Space-efficient vanities",
          "Under-sink cabinetry",
          "Tall bathroom cabinets",
          "Bathroom mirror cabinets",
          "Compact bathroom shelves",
          "Waterproof cabinet finishes",
          "Modern bathroom vanities",
        ],
      },
      {
        id: 7,
        name: "Home Office Cabinets",
        keywords: [
          "Office desk cabinetry",
          "Built-in office shelves",
          "Custom filing cabinets",
          "Home office storage units",
          "Workspace cabinetry",
          "Wall-mounted office cabinets",
          "Printer storage cabinets",
          "Ergonomic office cabinets",
          "Minimalist office design",
          "Desk and shelf combinations",
        ],
      },
      {
        id: 8,
        name: "Garage & Utility Storage",
        keywords: [
          "Garage storage cabinets",
          "Tool storage units",
          "Heavy-duty garage shelves",
          "Custom utility cabinets",
          "Wall-mounted storage",
          "Sports equipment cabinets",
          "Workshop cabinetry",
          "Fold-down workbenches",
          "Durable garage cupboards",
          "Space-saving garage solutions",
        ],
      },
      {
        id: 9,
        name: "Entertainment & Media Units",
        keywords: [
          "TV cabinet design",
          "Custom entertainment centres",
          "Media storage cabinets",
          "Floating TV units",
          "Built-in bookcases",
          "Home theatre cabinetry",
          "Cable management cabinets",
          "Stylish TV stands",
          "Modular media units",
          "Compact entertainment units",
        ],
      },
    ],
  },
  {
    primaryCategory: "Carpentry",
    id: 7,
    subList: [
      {
        id: 1,
        name: "General Carpentry",
        keywords: [
          "Timber framing",
          "Structural carpentry",
          "House framing",
          "Wall stud installation",
          "Subfloor construction",
          "Roof truss installation",
          "Timber beam installation",
          "Partition wall framing",
          "Load-bearing wall support",
          "Framing repairs",
        ],
      },
      {
        id: 2,
        name: "Decking & Pergolas",
        keywords: [
          "Timber decking installation",
          "Pergola construction",
          "Outdoor deck repairs",
          "Deck staining and sealing",
          "Custom deck design",
          "Pergola roof installation",
          "Raised deck construction",
          "Poolside decking",
          "Alfresco area carpentry",
          "Deck board replacement",
        ],
      },
      {
        id: 3,
        name: "Door & Window Installation",
        keywords: [
          "Door hanging and fitting",
          "Window frame installation",
          "Sliding door setup",
          "Bi-fold door carpentry",
          "Custom door frames",
          "Window sill repairs",
          "French door installation",
          "Door trim and architraves",
          "Cavity sliding doors",
          "Exterior door carpentry",
        ],
      },
      {
        id: 4,
        name: "Cabinetry & Joinery",
        keywords: [
          "Custom built-in cabinets",
          "Timber joinery",
          "Wardrobe construction",
          "Shelving installation",
          "Kitchen joinery",
          "Bathroom vanity carpentry",
          "Floating shelves",
          "Joinery for home offices",
          "Cabinet door fitting",
          "Custom shelving units",
        ],
      },
      {
        id: 5,
        name: "Renovations & Extensions",
        keywords: [
          "Home renovation carpentry",
          "Timber wall extensions",
          "Roof extension carpentry",
          "Garage conversions",
          "House extension framing",
          "Renovation timber work",
          "Floor joist installation",
          "Staircase carpentry",
          "Wall removal support",
          "Attic renovation framing",
        ],
      },
      {
        id: 6,
        name: "Skirting & Architraves",
        keywords: [
          "Skirting board installation",
          "Decorative architraves",
          "Timber moulding fitting",
          "Baseboard replacement",
          "Door trim carpentry",
          "Window architrave carpentry",
          "Custom skirting designs",
          "Skirting board repairs",
          "Bullnose architraves",
          "Modern skirting styles",
        ],
      },
      {
        id: 7,
        name: "Outdoor Structures",
        keywords: [
          "Carport construction",
          "Gazebo carpentry",
          "Garden shed building",
          "Pool house framing",
          "Timber retaining walls",
          "Outdoor bench carpentry",
          "Picnic table construction",
          "Outdoor stair carpentry",
          "Playhouse building",
        ],
      },
      {
        id: 8,
        name: "Flooring & Subflooring",
        keywords: [
          "Timber floor installation",
          "Subfloor repairs",
          "Floorboard replacement",
          "Laminate flooring setup",
          "Parquetry flooring",
          "Floating floorboards",
          "Subfloor waterproofing",
          "Timber floor refinishing",
          "Hardwood floor carpentry",
          "Acoustic underlay fitting",
        ],
      },
      {
        id: 9,
        name: "Custom Carpentry Services",
        keywords: [
          "Bespoke timber projects",
          "Unique carpentry designs",
          "Artistic timber work",
          "Reclaimed wood carpentry",
          "Outdoor furniture building",
          "Feature wall carpentry",
          "Creative home carpentry",
          "Custom built-ins",
        ],
      },
      {
        id: 10,
        name: "Repair & Maintenance",
        keywords: [
          "Timber rot repairs",
          "Structural timber fixes",
          "Deck board replacement",
          "Door and window adjustments",
          "Framing repairs",
          "Subfloor maintenance",
          "Architrave replacement",
          "Moulding restoration",
          "Fence repair carpentry",
          "Pergola maintenance",
        ],
      },
    ],
  },
  {
    primaryCategory: "Carpet & Flooring",
    id: 8,
    subList: [
      {
        id: 1,
        name: "Carpet Installation & Replacement",
        keywords: [
          "New carpet installation",
          "Carpet replacement",
          "Wall-to-wall carpeting",
          "Carpet laying services",
          "Stain-resistant carpet",
          "Pet-friendly carpets",
          "Commercial carpet installation",
          "Carpet padding setup",
          "Eco-friendly carpet options",
        ],
      },
      {
        id: 2,
        name: "Carpet Repairs & Maintenance",
        keywords: [
          "Carpet patching",
          "Carpet re-stretching",
          "Carpet seam repair",
          "Water-damaged carpet repair",
          "Pet stain treatment",
          "Carpet deodorizing",
          "Carpet odor removal",
          "Carpet underlay replacement",
        ],
      },
      {
        id: 3,
        name: "Hardwood Flooring",
        keywords: [
          "Hardwood floor installation",
          "Timber floor laying",
          "Solid wood flooring",
          "Engineered hardwood setup",
          "Floorboard replacement",
          "Floor sanding and polishing",
          "Reclaimed timber flooring",
        ],
      },
      {
        id: 4,
        name: "Laminate & Vinyl Flooring",
        keywords: [
          "Laminate flooring installation",
          "Vinyl plank setup",
          "Floating laminate floors",
          "Luxury vinyl tiles",
          "Water-resistant laminate",
          "Vinyl floor repairs",
          "Hybrid flooring solutions",
          "Scratch-resistant vinyl",
        ],
      },
      {
        id: 5,
        name: "Concrete & Polished Flooring",
        keywords: [
          "Polished concrete floors",
          "Concrete grinding",
          "Stamped concrete designs",
          "Concrete resurfacing",
          "Industrial concrete floors",
          "Sealed concrete floors",
          "Concrete sealing",
          "High-gloss polished concrete",
        ],
      },
      {
        id: 6,
        name: "Epoxy Flooring",
        keywords: [
          "Epoxy floor coating",
          "Garage floor epoxy",
          "Industrial epoxy floors",
          "Epoxy resin flooring",
          "Anti-slip epoxy coating",
          "UV-resistant epoxy",
          "Custom epoxy designs",
        ],
      },
      {
        id: 7,
        name: "Outdoor Flooring",
        keywords: [
          "Decking floor solutions",
          "Weatherproof deck flooring",
          "Anti-slip outdoor finishes",
          "Poolside flooring",
          "Timber deck restoration",
          "Composite decking boards",
          "Outdoor carpet fitting",
          "Durable outdoor flooring",
        ],
      },
      {
        id: 8,
        name: "Specialty Flooring Solutions",
        keywords: [
          "Acoustic flooring",
          "Sports court flooring",
          "Dance studio floors",
          "Gym rubber mats",
          "Heated floor systems",
          "Eco-friendly flooring options",
          "Floor leveling services",
          "Anti-static flooring",
        ],
      },
    ],
  },
  {
    primaryCategory: "Caulking Services",
    id: 9,
    subList: [
      {
        id: 1,
        name: "Interior Caulking",
        keywords: [
          "Bathroom caulking",
          "Kitchen sink caulking",
          "Window frame sealing",
          "Tile edge caulking",
          "Shower and bath caulking",
          "Vanity caulking",
          "Countertop seam sealing",
          "Mould-resistant caulking",
        ],
      },
      {
        id: 2,
        name: "Exterior Caulking",
        keywords: [
          "Window and door sealing",
          "Brick joint caulking",
          "Exterior wall gap sealing",
          "Roof edge caulking",
          "Weatherproof caulking",
          "UV-resistant caulking",
          "Gutter joint sealing",
          "Driveway caulking",
        ],
      },
      {
        id: 3,
        name: "Wet Area Sealing",
        keywords: [
          "Wet room caulking",
          "Waterproof shower sealing",
          "Laundry room sealing",
          "Splashback caulking",
          "Toilet base sealing",
          "Grout line caulking",
          "Silicone sealing for showers",
        ],
      },
      {
        id: 4,
        name: "Expansion Joint Sealing",
        keywords: [
          "Concrete expansion joints",
          "Building joint caulking",
          "Thermal expansion sealing",
          "Floor joint caulking",
          "Industrial joint caulking",
          "Flexible sealant application",
          "Construction joint caulking",
        ],
      },
      {
        id: 5,
        name: "Fire-Rated Caulking",
        keywords: [
          "Firestop caulking",
          "Fire-rated sealant",
          "Smoke barrier sealing",
          "Fire-resistant caulking",
          "Intumescent caulking",
          "Fireproof wall sealing",
          "Pipe and duct fire sealing",
        ],
      },
      {
        id: 6,
        name: "Commercial Caulking",
        keywords: [
          "Office building caulking",
          "Warehouse caulking",
          "Commercial window sealing",
          "High-rise caulking",
          "Concrete floor sealing",
          "Weatherproofing for businesses",
        ],
      },
      {
        id: 7,
        name: "Specialty Caulking Projects",
        keywords: [
          "Acoustic caulking",
          "Pool area caulking",
          "Anti-microbial sealant",
          "Cleanroom caulking",
          "Aquarium sealing",
          "Heritage building sealing",
        ],
      },
    ],
  },
  {
    primaryCategory: "Security & Automation",
    id: 10,
    subList: [
      {
        id: 1,
        name: "Home Security Systems",
        keywords: [
          "Security camera installation",
          "Smart home security",
          "Motion sensor setup",
          "Alarm system installation",
          "CCTV monitoring",
          "Doorbell camera systems",
          "Intrusion detection systems",
          "Perimeter security setup",
          "Wireless security cameras",
          "Home security consultations",
        ],
      },
      {
        id: 2,
        name: "Access Control & Locks",
        keywords: [
          "Smart lock installation",
          "Keyless entry systems",
          "Biometric door locks",
          "Gate access control",
          "Card reader installation",
          "Intercom and access control",
          "Lock rekeying services",
          "Security gate automation",
          "Electronic keypad locks",
          "Remote access systems",
        ],
      },
      {
        id: 3,
        name: "Surveillance & Monitoring",
        keywords: [
          "24/7 surveillance systems",
          "Live video monitoring",
          "Cloud-based camera storage",
          "Security DVR setup",
          "Hidden camera installation",
          "Wireless monitoring solutions",
          "Commercial CCTV systems",
          "Video door monitoring",
          "Offsite monitoring services",
          "Remote surveillance setup",
        ],
      },
      {
        id: 4,
        name: "Alarm Systems",
        keywords: [
          "Burglar alarm setup",
          "Fire alarm systems",
          "Alarm monitoring services",
          "Wireless alarm systems",
          "Silent alarm installation",
          "Emergency alarm buttons",
          "Alarm panel upgrades",
          "Motion alarm sensors",
          "Door and window alarms",
          "Commercial alarm solutions",
        ],
      },
      {
        id: 5,
        name: "Smart Home Automation",
        keywords: [
          "Smart lighting control",
          "Automated thermostat setup",
          "Voice-activated home systems",
          "Smart blinds and curtains",
          "Home automation integration",
          "App-controlled home devices",
          "Remote appliance control",
          "Smart home energy management",
          "Automated garage doors",
          "Whole-home automation",
        ],
      },
      {
        id: 6,
        name: "Security System Upgrades",
        keywords: [
          "System modernization",
          "Security camera upgrades",
          "Smart alarm retrofitting",
          "Wireless to wired transitions",
          "Cloud storage integration",
          "Backup power solutions",
          "Enhanced monitoring systems",
          "System firmware updates",
          "Sensor range extensions",
          "Voice control upgrades",
        ],
      },
      {
        id: 7,
        name: "Commercial Security Solutions",
        keywords: [
          "Business security audits",
          "Warehouse surveillance",
          "Office access control",
          "Multi-site security integration",
          "Commercial alarm systems",
          "Retail loss prevention",
          "Security lighting installation",
          "High-security lock systems",
          "Perimeter fencing security",
          "Industrial security solutions",
        ],
      },
      {
        id: 8,
        name: "Networking & Connectivity",
        keywords: [
          "Wi-Fi security setup",
          "Networked security cameras",
          "Smart device networking",
          "Home and office network security",
          "Router security upgrades",
          "Secure remote access",
          "VPN setup for home automation",
          "Firewall installation",
          "Cloud connectivity solutions",
          "Network troubleshooting",
        ],
      },
      {
        id: 9,
        name: "Custom Security Projects",
        keywords: [
          "Custom surveillance plans",
          "Personalized automation systems",
          "High-tech security designs",
          "Bespoke alarm setups",
          "Custom smart home features",
          "Tailored access control solutions",
          "Exclusive security packages",
          "Advanced monitoring options",
          "Property-specific automation",
          "Unique security configurations",
        ],
      },
      {
        id: 10,
        name: "Emergency Response Solutions",
        keywords: [
          "Panic button setup",
          "Rapid response alarms",
          "Emergency intercom systems",
          "SOS alarm installation",
          "Critical alert systems",
          "Mobile emergency notifications",
          "Home evacuation alarms",
          "Emergency power backup",
          "Personal safety devices",
          "Crisis alert management",
        ],
      },
      {
        id: 11,
        name: "NBN & Data Services",
        keywords: [
          "NBN installation",
          "Data cabling services",
          "Home network setup",
          "Office network installation",
          "Fiber optic installations",
          "Network cabling for businesses",
          "Structured cabling solutions",
          "Network speed optimization",
          "Network upgrades and repairs",
          "Data point installation",
        ],
      },
    ],
  },
  {
    primaryCategory: "Cleaning Services",
    id: 11,
    subList: [
      {
        id: 1,
        name: "Residential Cleaning",
        keywords: [
          "House cleaning",
          "Deep cleaning",
          "End of lease cleaning",
          "Bathroom and kitchen cleaning",
          "Dusting and vacuuming",
          "Floor mopping",
          "Window cleaning",
          "Carpet cleaning",
          "Appliance cleaning",
        ],
      },
      {
        id: 2,
        name: "Commercial Cleaning",
        keywords: [
          "Office cleaning",
          "Retail cleaning",
          "Industrial cleaning",
          "Building maintenance",
          "Restaurant cleaning",
          "Warehouse cleaning",
          "Gym cleaning",
          "Healthcare facility cleaning",
          "School cleaning",
          "Shopping centre cleaning",
        ],
      },
      {
        id: 3,
        name: "Carpet & Upholstery Cleaning",
        keywords: [
          "Carpet steam cleaning",
          "Stain removal",
          "Upholstery cleaning",
          "Rug cleaning",
          "Leather couch cleaning",
          "Carpet deodorizing",
          "Pet stain treatment",
          "Dust mite removal",
          "Fabric protection",
          "Mattress cleaning",
        ],
      },
      {
        id: 4,
        name: "Window & Glass Cleaning",
        keywords: [
          "Interior window cleaning",
          "Exterior glass cleaning",
          "High-rise window cleaning",
          "Skylight cleaning",
          "Balcony glass cleaning",
          "Streak-free window cleaning",
          "Commercial glass cleaning",
        ],
      },
      {
        id: 5,
        name: "Solar Panel Cleaning",
        keywords: [
          "Solar panel cleaning",
          "PV system cleaning",
          "Dust and debris removal",
          "Residential solar cleaning",
          "Commercial solar cleaning",
          "Streak-free solar cleaning",
        ],
      },
      {
        id: 6,
        name: "Pressure Washing",
        keywords: [
          "Driveway pressure cleaning",
          "Patio cleaning",
          "Concrete cleaning",
          "Building exterior washing",
          "Roof pressure washing",
          "Pool area cleaning",
          "Fence cleaning",
          "Graffiti removal",
        ],
      },
      {
        id: 7,
        name: "Post-Construction Cleaning",
        keywords: [
          "Construction site cleanup",
          "Dust removal",
          "Builder’s cleaning",
          "Masonry cleaning",
          "Acid and pressure washing",
          "Floor polishing",
          "Surface sanitization",
          "Ceiling dusting",
          "Waste disposal",
          "Final inspection cleaning",
        ],
      },
      {
        id: 8,
        name: "Move-In/Move-Out Cleaning",
        keywords: [
          "Move-out cleaning",
          "Bond cleaning",
          "Wall cleaning",
          "Cabinet cleaning",
          "Light fixture dusting",
          "Carpet and floor cleaning",
          "Appliance deep cleaning",
        ],
      },
      {
        id: 9,
        name: "Specialty Cleaning Services",
        keywords: [
          "Mould remediation",
          "Hoarder cleanup",
          "Biohazard cleaning",
          "Crime scene cleaning",
          "Hazardous waste removal",
          "Air duct cleaning",
          "Tile and grout cleaning",
          "Allergen removal",
          "Trauma cleaning",
        ],
      },
      {
        id: 10,
        name: "Green & Eco-Friendly Cleaning",
        keywords: [
          "Eco-friendly cleaning",
          "Chemical-free cleaning",
          "Sustainable practices",
          "Organic carpet cleaning",
          "Non-toxic cleaning",
          "Water-saving pressure washing",
          "Waste-free solutions",
        ],
      },
      { id: 11, name: "Custom Cleaning Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Concrete & Paving",
    id: 12,
    subList: [
      {
        id: 1,
        name: "Concrete Driveways & Pathways",
        keywords: [
          "Concrete driveway installation",
          "Exposed aggregate driveways",
          "Concrete path laying",
          "Decorative concrete driveways",
          "Stamped concrete pathways",
          "Driveway resurfacing",
          "Driveway repair",
          "Coloured concrete driveways",
          "Driveway sealing",
        ],
      },
      {
        id: 2,
        name: "Concrete Slabs & Foundations",
        keywords: [
          "Concrete slab pouring",
          "Foundation slabs",
          "Shed slab installation",
          "Garage floor slabs",
          "Reinforced concrete slabs",
          "Slab leveling and repair",
          "Concrete formwork",
        ],
      },
      {
        id: 3,
        name: "Decorative Concrete",
        keywords: [
          "Stamped concrete patterns",
          "Exposed aggregate finishes",
          "Polished concrete floors",
          "Stained concrete",
          "Concrete overlays",
          "Coloured concrete",
          "Artistic concrete features",
        ],
      },
      {
        id: 4,
        name: "Paving Services",
        keywords: [
          "Driveway paving",
          "Patio paving",
          "Pathway paving",
          "Poolside paving",
          "Brick paving",
          "Stone pavers",
          "Permeable paving",
          "Retaining wall paving",
        ],
      },
      {
        id: 5,
        name: "Retaining Walls",
        keywords: [
          "Concrete retaining walls",
          "Stone retaining walls",
          "Structural retaining walls",
          "Garden wall construction",
          "Reinforced retaining walls",
          "Retaining wall repair",
        ],
      },
      {
        id: 6,
        name: "Concrete Repair & Maintenance",
        keywords: [
          "Concrete crack repair",
          "Concrete resurfacing",
          "Joint sealing",
          "Concrete patching",
          "Concrete grinding and leveling",
          "Driveway resealing",
          "Epoxy concrete coating",
        ],
      },
      {
        id: 7,
        name: "Commercial Concrete Projects",
        keywords: [
          "Car park paving",
          "Industrial slab installation",
          "Commercial driveway pouring",
          "Warehouse flooring",
          "Concrete kerbing",
          "Heavy-duty concrete pads",
        ],
      },
      {
        id: 8,
        name: "Outdoor Living Areas",
        keywords: [
          "Concrete patio installation",
          "Alfresco concrete areas",
          "Outdoor BBQ slabs",
          "Garden concrete features",
          "Fire pit concrete surrounds",
          "Outdoor entertainment spaces",
        ],
      },
      {
        id: 9,
        name: "Pool Surrounds & Decks",
        keywords: [
          "Poolside concrete paving",
          "Slip-resistant pool decks",
          "Stamped pool surrounds",
          "Concrete coping for pools",
          "Pool deck resurfacing",
        ],
      },
      { id: 10, name: "Custom Concrete Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Conveyancing",
    id: 13,
    subList: [
      {
        id: 1,
        name: "Residential Property Conveyancing",
        keywords: [
          "House purchase conveyancing",
          "Residential property transfer",
          "First home buyer conveyancing",
          "Title search services",
          "Contract review and advice",
          "Property settlement services",
          "Vendor statement preparation",
          "Transfer of land documents",
          "Residential leasehold conveyancing",
          "Mortgage and refinancing paperwork",
        ],
      },
      {
        id: 2,
        name: "Commercial Property Conveyancing",
        keywords: [
          "Commercial property transactions",
          "Office lease transfers",
          "Retail property conveyancing",
          "Business property settlements",
          "Commercial title searches",
          "Commercial lease agreements",
          "Contract negotiation for businesses",
          "Transfer of commercial land",
          "Industrial property conveyancing",
          "Business premises acquisition",
        ],
      },
      {
        id: 3,
        name: "Off-the-Plan Conveyancing",
        keywords: [
          "Off-the-plan purchase contracts",
          "Pre-construction property conveyancing",
          "Contract review for off-the-plan",
          "Developer agreement negotiations",
          "Off-the-plan deposit handling",
          "Progress payment coordination",
          "Title registration for new builds",
          "Land subdivision agreements",
          "Off-the-plan settlement preparation",
          "Final inspection and handover",
        ],
      },
      {
        id: 4,
        name: "Property Transfer Services",
        keywords: [
          "Transfer of ownership",
          "Family property transfers",
          "Title change services",
          "Divorce property settlements",
          "Deceased estate transfers",
          "Gifting property to family",
          "Equity transfer agreements",
          "Refinancing property transfers",
          "Land title adjustments",
          "Shared property title changes",
        ],
      },
      {
        id: 5,
        name: "Contract of Sale Services",
        keywords: [
          "Drafting contract of sale",
          "Reviewing sale contracts",
          "Special conditions advice",
          "Auction contract assistance",
          "Sale contract amendments",
          "Vendor disclosure requirements",
          "Negotiating contract terms",
          "Conditional contract guidance",
          "Cooling-off period consultation",
          "Contract of sale finalization",
        ],
      },
      {
        id: 6,
        name: "Legal Advice & Representation",
        keywords: [
          "Conveyancing legal advice",
          "Property law consultations",
          "Representation at settlement",
          "Dispute resolution services",
          "Advice on property regulations",
          "Property litigation support",
          "Easement and covenant advice",
          "Zoning and planning consultations",
          "Building contract legal review",
          "Legal representation for developers",
        ],
      },
      {
        id: 7,
        name: "Title & Property Searches",
        keywords: [
          "Comprehensive title searches",
          "Land registry checks",
          "Property encumbrance searches",
          "Easement investigation",
          "Council zoning certificates",
          "Building permit history",
          "Planning restriction searches",
          "Flood zone analysis",
          "Heritage listing investigations",
          "Subdivision plan searches",
        ],
      },
      {
        id: 8,
        name: "Settlement Coordination",
        keywords: [
          "Coordinating property settlements",
          "Settlement statement preparation",
          "Liaising with banks and lenders",
          "Final settlement adjustments",
          "Transfer of funds management",
          "Electronic settlement setup",
          "Communicating with stakeholders",
          "Organizing settlement dates",
          "Settlement issue resolution",
          "Ensuring smooth handover",
        ],
      },
      { id: 9, name: "Custom Conveyancing Services", keywords: [] },
    ],
  },
  {
    primaryCategory: "Decking, Gazebo & Carports",
    id: 14,
    subList: [
      {
        id: 1,
        name: "Timber Decking",
        keywords: [
          "Timber deck installation",
          "Hardwood decking",
          "Deck design and construction",
          "Deck sanding and sealing",
          "Outdoor entertaining decks",
          "Poolside decks",
          "Elevated deck structures",
          "Merbau decking",
          "Composite alternatives",
          "Deck maintenance",
        ],
      },
      {
        id: 2,
        name: "Composite & Eco Decking",
        keywords: [
          "Composite deck installation",
          "Eco-friendly decking",
          "Recycled material decks",
          "UV-resistant decking",
          "Sustainable deck options",
          "Non-slip decking",
          "Waterproof deck surfaces",
        ],
      },
      {
        id: 3,
        name: "Gazebos & Pergolas",
        keywords: [
          "Custom gazebo construction",
          "Pergola installation",
          "Outdoor gazebo designs",
          "Louvered pergolas",
          "Freestanding pergolas",
          "Gazebo roof replacement",
          "Decorative pergolas",
          "Waterproof pergola covers",
        ],
      },
      {
        id: 4,
        name: "Verandas & Patios",
        keywords: [
          "Veranda construction",
          "Covered patios",
          "Alfresco verandas",
          "Patio roofing",
          "Enclosed patios",
          "Weatherproof verandas",
          "Timber patio decking",
          "Veranda extensions",
        ],
      },
      {
        id: 5,
        name: "Carports & Outdoor Shelters",
        keywords: [
          "Carport construction",
          "Double carports",
          "Steel frame carports",
          "Timber carports",
          "Freestanding shelters",
          "Weatherproof carports",
          "Carport enclosures",
        ],
      },
      {
        id: 6,
        name: "Outdoor Kitchens & BBQ Areas",
        keywords: [
          "Outdoor kitchen construction",
          "Built-in BBQ installations",
          "Alfresco kitchens",
          "Custom BBQ areas",
          "Stone benchtops",
          "Timber cabinetry",
          "Fireproof materials",
        ],
      },
      {
        id: 7,
        name: "Shade Structures & Canopies",
        keywords: [
          "Retractable awnings",
          "Shade sail installation",
          "UV-protection canopies",
          "Weather-resistant shade structures",
          "Pergola canopy upgrades",
          "Gazebo canopy replacement",
        ],
      },
      { id: 8, name: "Custom Outdoor Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Demolition & Excavation",
    id: 15,
    subList: [
      {
        id: 1,
        name: "Residential Demolition",
        keywords: [
          "House demolition",
          "Garage demolition",
          "Shed removal",
          "Small structure demolition",
          "Residential knockdown",
          "Asbestos removal",
          "Pool demolition",
          "Site clearing",
          "Driveway removal",
          "Demolition waste removal",
        ],
      },
      {
        id: 2,
        name: "Commercial Demolition",
        keywords: [
          "Office building demolition",
          "Retail space demolition",
          "Warehouse demolition",
          "Industrial structure removal",
          "Concrete slab demolition",
          "Factory demolition",
          "Multi-storey demolition",
          "Interior strip-out services",
          "Car park demolition",
          "Commercial waste disposal",
        ],
      },
      {
        id: 3,
        name: "Selective Demolition",
        keywords: [
          "Interior demolition",
          "Kitchen/bathroom strip-outs",
          "Wall and partition removal",
          "Ceiling removal",
          "Selective concrete removal",
          "Renovation-specific demolition",
          "Floor removal",
          "Window and door frame removal",
          "Structural modifications",
        ],
      },
      {
        id: 4,
        name: "Excavation Services",
        keywords: [
          "Site excavation",
          "Earthmoving services",
          "Foundation excavation",
          "Basement excavation",
          "Trenching and digging",
          "Land leveling",
          "Pool excavation",
          "Driveway excavation",
          "Footing excavation",
          "Retaining wall excavation",
        ],
      },
      {
        id: 5,
        name: "Land Clearing & Preparation",
        keywords: [
          "Tree and stump removal",
          "Land clearing for construction",
          "Vegetation removal",
          "Site grading",
          "Soil removal and disposal",
          "Rock and debris removal",
          "Pre-construction site prep",
          "Erosion control",
          "Overgrown land clearing",
        ],
      },
      {
        id: 6,
        name: "Bulk Earthworks",
        keywords: [
          "Bulk soil removal",
          "Large-scale excavation",
          "Rock excavation",
          "Construction earthmoving",
          "Soil compaction",
          "Bulk transport",
          "Quarry excavation",
          "Cut and fill",
          "Bulk landfill preparation",
          "High-volume earthworks",
        ],
      },
      {
        id: 7,
        name: "Concrete Removal & Breaking",
        keywords: [
          "Concrete breaking services",
          "Jackhammer removal",
          "Concrete cutting",
          "Slab/driveway removal",
          "Reinforced concrete demolition",
          "Concrete disposal",
          "Concrete recycling",
          "Concrete sawing",
          "Pathway removal",
        ],
      },
      {
        id: 8,
        name: "Trenching & Drainage",
        keywords: [
          "Utility trenching",
          "Stormwater drainage",
          "Drainage trench digging",
          "Water main trenching",
          "Electrical conduit trenching",
          "Sewer line excavation",
          "Irrigation trenching",
          "Gas line trenching",
          "Subsurface drainage",
        ],
      },
      {
        id: 9,
        name: "Retaining Wall Excavation",
        keywords: [
          "Retaining wall prep",
          "Foundation excavation for walls",
          "Slope leveling",
          "Earth retention systems",
          "Site stabilization",
          "Reinforced wall excavation",
          "Retaining wall backfill",
        ],
      },
      { id: 10, name: "Custom Demolition & Excavation Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Electrical Services",
    id: 16,
    subList: [
      {
        id: 1,
        name: "General Electrical Repairs",
        keywords: [
          "Faulty wiring repair",
          "Power outlet replacement",
          "Electrical troubleshooting",
          "Light switch repairs",
          "Circuit breaker fixing",
          "Electrical fault finding",
          "Appliance wiring repair",
          "Short circuit repair",
          "Electrical socket installation",
          "Fuse box upgrades",
        ],
      },
      {
        id: 2,
        name: "New Electrical Installations",
        keywords: [
          "Ceiling fan installation",
          "New power point installation",
          "Lighting installation",
          "Smoke alarm installation",
          "Appliance wiring setup",
          "Outdoor lighting installation",
          "Downlight installation",
          "Electric vehicle charger installation",
          "Kitchen appliance installation",
          "Bathroom exhaust fan setup",
        ],
      },
      {
        id: 3,
        name: "Lighting Solutions",
        keywords: [
          "LED lighting upgrades",
          "Smart lighting systems",
          "Garden and outdoor lighting",
          "Pendant light installation",
          "Motion sensor lights",
          "Track lighting installation",
          "Dimmer switch installation",
          "Security lighting setup",
          "Feature lighting design",
          "Pool area lighting",
        ],
      },
      {
        id: 4,
        name: "Safety & Compliance",
        keywords: [
          "Electrical safety inspections",
          "RCD (safety switch) testing",
          "Compliance certificate issuance",
          "Smoke alarm compliance checks",
          "Electrical tagging and testing",
          "Earthing and grounding inspections",
          "Home safety audits",
          "Circuit safety checks",
          "Electrical hazard assessment",
          "Appliance safety testing",
        ],
      },
      {
        id: 5,
        name: "Switchboard Upgrades & Repairs",
        keywords: [
          "Switchboard upgrade",
          "Fuse box replacement",
          "Circuit breaker panel installation",
          "Safety switch installation",
          "Switchboard rewiring",
          "Load balancing services",
          "Overload protection upgrades",
          "Electrical meter box installation",
          "Switchboard relocation",
          "Emergency switchboard repairs",
        ],
      },
      {
        id: 6,
        name: "Energy Efficiency Solutions",
        keywords: [
          "Energy-efficient lighting upgrades",
          "LED retrofits",
          "Solar panel integration support",
          "Smart meter installation",
          "Low-energy appliance wiring",
          "Energy-saving electrical audits",
          "Insulation wiring solutions",
          "High-efficiency power solutions",
          "Timers and occupancy sensors",
          "Energy consumption monitoring",
        ],
      },
      {
        id: 7,
        name: "Data & Communication Wiring",
        keywords: [
          "Network cable installation",
          "Data point installation",
          "Structured cabling installation",
          "Phone line repair",
          "Ethernet cabling services",
          "Home office wiring",
          "TV antenna installation",
          "Wi-Fi access point setup",
          "Coaxial cable repair",
          "Signal booster installation",
        ],
      },
      {
        id: 8,
        name: "Commercial Electrical Services",
        keywords: [
          "Office electrical fit-out",
          "Commercial lighting solutions",
          "Emergency lighting installation",
          "Warehouse electrical wiring",
          "Shop electrical fit-out",
          "Commercial switchboard upgrades",
          "Energy-efficient lighting systems",
          "Backup generator installation",
          "Commercial appliance wiring",
          "Security system installation",
        ],
      },
      {
        id: 9,
        name: "Emergency Electrical Services",
        keywords: [
          "24/7 emergency electrician",
          "Power outage repair",
          "Urgent fault finding",
          "Emergency lighting repair",
          "After-hours electrical service",
          "Electrical fire risk assessment",
          "Emergency rewiring",
          "Storm damage repairs",
          "Rapid appliance disconnection",
          "Immediate safety switch repair",
        ],
      },
      { id: 10, name: "Custom Electrical Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Fencing & Gates",
    id: 17,
    subList: [
      {
        id: 1,
        name: "Residential Fencing",
        keywords: [
          "Timber fence installation",
          "Colorbond fencing",
          "Picket fence construction",
          "Fence replacement",
          "Privacy fence setup",
          "Pool safety fencing",
          "Decorative garden fencing",
          "Slat fencing installation",
          "Custom wooden fences",
          "Boundary fence installation",
        ],
      },
      {
        id: 2,
        name: "Commercial & Industrial Fencing",
        keywords: [
          "Security fencing",
          "Chain link fence installation",
          "Industrial perimeter fencing",
          "Barbed wire fences",
          "Steel fencing solutions",
          "School and park fencing",
          "Anti-climb fences",
          "Automatic security gates",
          "Warehouse fencing",
          "Temporary construction fencing",
        ],
      },
      {
        id: 3,
        name: "Automatic & Electric Gates",
        keywords: [
          "Automatic gate installation",
          "Electric driveway gates",
          "Sliding gate systems",
          "Swing gate automation",
          "Gate motor repairs",
          "Keypad entry gates",
          "Intercom gate systems",
          "Solar-powered gates",
          "Remote control gate setup",
          "Gate opener replacement",
        ],
      },
      {
        id: 4,
        name: "Garage Doors",
        keywords: [
          "Garage door installation",
          "Roller door setup",
          "Sectional garage doors",
          "Garage door repairs",
          "Automatic garage doors",
          "Insulated garage doors",
          "Garage door remote replacement",
          "Garage door spring repair",
          "Custom garage door designs",
          "Emergency garage door services",
        ],
      },
      {
        id: 5,
        name: "Side Gates & Pedestrian Access",
        keywords: [
          "Side gate installation",
          "Pedestrian gate setup",
          "Lockable side gates",
          "Metal side gates",
          "Wooden pedestrian gates",
          "Decorative side gates",
          "Security side gates",
          "Custom-built side access gates",
          "Automated pedestrian gates",
          "Privacy side gates",
        ],
      },
      {
        id: 6,
        name: "Security & Privacy Screens",
        keywords: [
          "Privacy screen installation",
          "Outdoor privacy walls",
          "Aluminium slat screens",
          "Decorative metal screens",
          "Laser-cut privacy panels",
          "Poolside privacy screens",
          "Retractable privacy screens",
          "Garden privacy structures",
          "Custom privacy screen designs",
          "Weather-resistant privacy screens",
        ],
      },
      {
        id: 7,
        name: "Farm & Rural Fencing",
        keywords: [
          "Rural fence installation",
          "Post and rail fencing",
          "Farm gate installation",
          "Electric farm fencing",
          "Stockyard fencing",
          "Cattle fencing solutions",
          "Horse-safe fencing",
          "Wire mesh farm fences",
          "Livestock gate installation",
          "Boundary farm fencing",
        ],
      },
      {
        id: 8,
        name: "Decorative & Custom Gates",
        keywords: [
          "Wrought iron gates",
          "Decorative driveway gates",
          "Custom metal gates",
          "Timber feature gates",
          "Ornate garden gates",
          "Laser-cut decorative gates",
          "Aluminium panel gates",
          "Rustic wooden gates",
          "Contemporary gate designs",
          "Bespoke gate craftsmanship",
        ],
      },
      {
        id: 9,
        name: "Fence & Gate Repairs",
        keywords: [
          "Broken fence repair",
          "Gate hinge replacement",
          "Post and rail repair",
          "Fence re-staining",
          "Rusted gate repairs",
          "Latch and lock repairs",
          "Realignment of sliding gates",
          "Damaged panel replacement",
          "Reinforcing weakened fences",
          "Gate motor servicing",
        ],
      },
      { id: 10, name: "Custom Fencing & Gate Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Glass & Glazing",
    id: 18,
    subList: [
      {
        id: 1,
        name: "Glass Installation & Replacement",
        keywords: [
          "Window glass installation",
          "Broken glass replacement",
          "Double-glazed windows",
          "Energy-efficient glass",
          "Glass pane replacement",
          "Custom cut glass",
          "Frameless glass installation",
          "Soundproof glass windows",
          "Frosted glass panels",
          "Glass splashback installation",
        ],
      },
      {
        id: 2,
        name: "Shower Screens & Bathroom Glass",
        keywords: [
          "Frameless shower screens",
          "Semi-frameless shower doors",
          "Custom shower screen design",
          "Glass shower panel installation",
          "Bathroom glass partitions",
          "Frosted shower screens",
          "Shower door replacement",
          "Frameless bath screens",
          "Shower screen repairs",
          "Waterproof glass screens",
        ],
      },
      {
        id: 3,
        name: "Mirrors & Decorative Glass",
        keywords: [
          "Custom mirror installation",
          "Bathroom mirror fitting",
          "Decorative wall mirrors",
          "Gym and studio mirrors",
          "Frameless mirrors",
          "Mirrored wardrobe doors",
          "Bevelled edge mirrors",
          "Antique-style mirrors",
          "Splashback mirror panels",
          "Full-length mirror installation",
        ],
      },
      {
        id: 4,
        name: "Glass Balustrades & Railings",
        keywords: [
          "Glass balustrade installation",
          "Frameless glass railings",
          "Pool glass fencing",
          "Staircase glass balustrades",
          "Outdoor glass railings",
          "Custom glass balustrades",
          "Balcony glass panels",
          "Toughened glass railings",
          "Internal glass partitions",
          "Glass deck balustrades",
        ],
      },
      {
        id: 5,
        name: "Office & Commercial Glass Solutions",
        keywords: [
          "Office glass partitions",
          "Meeting room glass walls",
          "Sliding glass doors",
          "Commercial glass fit-outs",
          "Privacy glass installations",
          "Glass shopfronts",
          "Acoustic glass walls",
          "Fire-rated glass panels",
          "Frosted office glass",
          "Custom commercial glazing",
        ],
      },
      {
        id: 6,
        name: "Glass Repairs & Maintenance",
        keywords: [
          "Emergency glass repair",
          "Window glass crack repair",
          "Shower screen hinge repair",
          "Glass door realignment",
          "Sealed unit replacements",
          "Glass scratch removal",
          "Glass door maintenance",
          "Leak-proofing glass showers",
          "Glass balustrade repairs",
          "Window resealing services",
        ],
      },
      { id: 7, name: "Custom Glass Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Handyman Services",
    id: 19,
    subList: [
      {
        id: 1,
        name: "General Repairs & Maintenance",
        keywords: [
          "Door handle repairs",
          "Wall patching and painting",
          "Furniture assembly",
          "Minor plumbing repairs",
          "Leaky faucet fix",
          "Tile and grout repair",
          "Window lock installation",
          "Ceiling fan balancing",
          "Light bulb replacement",
          "Picture hanging services",
        ],
      },
      {
        id: 2,
        name: "Carpentry & Woodwork",
        keywords: [
          "Cabinet door repairs",
          "Custom shelving installation",
          "Skirting board replacement",
          "Deck repairs and maintenance",
          "Timber framing adjustments",
          "Wooden gate repair",
          "Closet rod installation",
          "Wooden fence repairs",
          "Door frame adjustments",
          "Pergola touch-ups",
        ],
      },
      {
        id: 3,
        name: "Electrical & Lighting",
        keywords: [
          "Light fixture installation",
          "Ceiling fan replacement",
          "Outlet cover replacement",
          "Installing dimmer switches",
          "Basic wiring repairs",
          "Motion sensor light setup",
          "Smoke detector installation",
          "Doorbell replacement",
          "Outdoor light installation",
          "TV mounting and wiring",
        ],
      },
      {
        id: 4,
        name: "Plumbing Fixes & Installations",
        keywords: [
          "Faucet installation",
          "Toilet flush repairs",
          "Showerhead replacement",
          "Fixing leaking pipes",
          "Garbage disposal repair",
          "Water filter installation",
          "Unclogging sinks and drains",
          "Dishwasher hookup",
          "Re-sealing shower tiles",
          "Drip irrigation system setup",
        ],
      },
      {
        id: 5,
        name: "Home Interior Upgrades",
        keywords: [
          "Wall painting and touch-ups",
          "Wallpaper removal",
          "Crown molding installation",
          "Window treatment setup",
          "Hanging mirrors and artwork",
          "Closet organizer installation",
          "Trim and molding repairs",
          "Door installation and repairs",
          "Acoustic panel mounting",
          "Installing curtain rods",
        ],
      },
      {
        id: 6,
        name: "Outdoor Maintenance & Repairs",
        keywords: [
          "Fence repair and staining",
          "Gutter cleaning",
          "Patio furniture assembly",
          "Deck pressure washing",
          "Garden shed repairs",
          "Outdoor tap replacement",
          "Hanging outdoor decorations",
          "Small landscaping tasks",
          "Yard waste removal",
          "Outdoor caulking repairs",
        ],
      },
      {
        id: 7,
        name: "Assembly & Installation",
        keywords: [
          "Flat-pack furniture assembly",
          "Basketball hoop installation",
          "Trampoline assembly",
          "BBQ setup and assembly",
          "Gym equipment assembly",
          "Swing set installation",
          "Closet system assembly",
          "TV bracket mounting",
          "Smart home device setup",
          "Pet door installation",
        ],
      },
      {
        id: 8,
        name: "Odd Jobs & Miscellaneous",
        keywords: [
          "Window screen repair",
          "Door alignment fixes",
          "Weatherstripping installation",
          "Basic drywall repairs",
          "Mailbox installation",
          "Garage door lubrication",
          "Furniture moving assistance",
          "Childproofing homes",
          "Minor welding tasks",
          "Setting up holiday lights",
        ],
      },
      {
        id: 9,
        name: "Safety & Accessibility Modifications",
        keywords: [
          "Grab bar installation",
          "Anti-slip floor treatment",
          "Stair rail reinforcement",
          "Smoke and CO detector checks",
          "Child safety gate setup",
          "Window lock installation",
          "Motion sensor light setup",
          "Security camera mounting",
          "Handrail installation",
          "Accessibility ramp adjustments",
        ],
      },
      { id: 10, name: "Custom Handyman Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Inspections & Surveyors",
    id: 20,
    subList: [
      {
        id: 1,
        name: "Pre-Purchase Building Inspections",
        keywords: [
          "Home inspection services",
          "Building condition reports",
          "Structural integrity checks",
          "Property defect inspection",
          "Comprehensive building report",
          "Pre-settlement inspections",
          "Roof and gutter inspections",
          "Interior and exterior checks",
          "Asbestos risk assessment",
          "Moisture and mold detection",
        ],
      },
      {
        id: 2,
        name: "Property & Land Surveying",
        keywords: [
          "Boundary survey services",
          "Land subdivision surveys",
          "Topographic surveys",
          "Site analysis surveys",
          "Easement location survey",
          "Contour mapping",
          "Title re-establishment survey",
          "Land measurement services",
          "Property boundary marking",
          "Construction site surveys",
        ],
      },
      {
        id: 3,
        name: "Dilapidation Reports",
        keywords: [
          "Pre-construction condition report",
          "Neighboring property assessment",
          "Construction impact survey",
          "Dilapidation inspection",
          "Condition monitoring",
          "Roadwork impact assessment",
          "Building movement documentation",
          "Vibration damage assessment",
          "Council requirement reports",
          "Detailed property condition report",
        ],
      },
      {
        id: 4,
        name: "Pest & Termite Inspections",
        keywords: [
          "Termite detection services",
          "Pre-purchase pest inspection",
          "Comprehensive termite report",
          "Termite damage assessment",
          "Pest control inspection",
          "Timber pest detection",
          "Pest infestation assessment",
          "Ant and rodent inspection",
          "Building and pest combo report",
          "Invasive termite inspection",
        ],
      },
      {
        id: 5,
        name: "Structural & Engineering Inspections",
        keywords: [
          "Structural integrity assessment",
          "Load-bearing wall inspection",
          "Engineering compliance checks",
          "Foundation and footing checks",
          "Retaining wall inspection",
          "Seismic safety evaluation",
          "Roof truss inspection",
          "Building stability report",
          "Structural defect analysis",
          "Steel frame inspection",
        ],
      },
      {
        id: 6,
        name: "Electrical & Plumbing Inspections",
        keywords: [
          "Electrical system inspection",
          "Safety switch testing",
          "Plumbing leak detection",
          "Hot water system check",
          "Electrical fault inspection",
          "Water pressure testing",
          "Sewer and drainage inspection",
          "Compliance certificate checks",
          "Gas line safety inspection",
          "Fixture and fitting assessment",
        ],
      },
      {
        id: 7,
        name: "Building Code & Compliance Inspections",
        keywords: [
          "Code compliance check",
          "Building regulation inspection",
          "Occupancy permit inspection",
          "Council compliance inspection",
          "Fire safety compliance report",
          "Zoning regulation check",
          "Pool safety compliance",
          "Accessibility compliance assessment",
          "Certificate of final inspection",
          "Building permit inspection",
        ],
      },
      {
        id: 8,
        name: "Environmental & Site Assessments",
        keywords: [
          "Soil contamination testing",
          "Environmental impact report",
          "Site risk assessment",
          "Groundwater testing",
          "Erosion control inspection",
          "Tree and vegetation survey",
          "Site drainage assessment",
          "Heritage site assessment",
          "Flood risk evaluation",
          "Wildlife impact assessment",
        ],
      },
      {
        id: 9,
        name: "Construction Progress Inspections",
        keywords: [
          "Construction milestone inspection",
          "Progress quality checks",
          "Frame stage inspection",
          "Lock-up stage inspection",
          "Practical completion inspection",
          "Waterproofing compliance",
          "Final handover inspection",
          "Construction site safety check",
          "Defects liability inspection",
          "Builder’s workmanship assessment",
        ],
      },
      { id: 10, name: "Custom Inspection & Surveyor Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Insulation Services",
    id: 21,
    subList: [
      {
        id: 1,
        name: "Roof & Ceiling Insulation",
        keywords: [
          "Roof insulation installation",
          "Ceiling batts setup",
          "Attic insulation",
          "Reflective foil insulation",
          "Blow-in roof insulation",
          "Insulation removal and replacement",
          "Heat barrier installation",
          "Insulation for pitched roofs",
          "Ceiling cavity insulation",
          "Roof ventilation solutions",
        ],
      },
      {
        id: 2,
        name: "Wall Insulation",
        keywords: [
          "Wall cavity insulation",
          "External wall insulation",
          "Acoustic wall insulation",
          "Retrofit wall insulation",
          "Insulation board installation",
          "Blown-in wall insulation",
          "Soundproofing walls",
          "Interior wall batts",
          "Energy-saving wall insulation",
          "Insulating plasterboard",
        ],
      },
      {
        id: 3,
        name: "Underfloor Insulation",
        keywords: [
          "Underfloor insulation setup",
          "Crawl space insulation",
          "Thermal floor batts",
          "Insulation for timber floors",
          "Moisture barrier installation",
          "Underfloor acoustic insulation",
          "Retrofit floor insulation",
          "Insulation for concrete slabs",
          "Floorboard insulation",
          "Subfloor insulation sealing",
        ],
      },
      {
        id: 4,
        name: "Acoustic & Soundproofing Insulation",
        keywords: [
          "Acoustic batts installation",
          "Soundproofing insulation",
          "Home theatre soundproofing",
          "Noise reduction insulation",
          "Acoustic ceiling panels",
          "Wall soundproofing solutions",
          "Office soundproofing",
          "Sound barrier installation",
          "Floor soundproofing batts",
          "Music studio insulation",
        ],
      },
      {
        id: 5,
        name: "Thermal & Energy-Efficient Insulation",
        keywords: [
          "Energy-efficient insulation",
          "High R-value insulation",
          "Heat loss prevention",
          "Cool room insulation",
          "Temperature control insulation",
          "Eco-friendly insulation",
          "Thermal lagging installation",
          "Sustainable insulation materials",
          "Heat retention batts",
          "Climate control insulation",
        ],
      },
      {
        id: 6,
        name: "Insulation Removal & Replacement",
        keywords: [
          "Old insulation removal",
          "Asbestos insulation removal",
          "Insulation replacement services",
          "Roof cavity cleanup",
          "Damaged insulation removal",
          "Contaminated insulation disposal",
          "Pest-damaged insulation replacement",
          "Moldy insulation removal",
          "Debris cleanup in ceilings",
          "Insulation vacuum services",
        ],
      },
      { id: 7, name: "Custom Insulation Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Interior Design",
    id: 22,
    subList: [
      {
        id: 1,
        name: "Residential Interior Design",
        keywords: [
          "Home interior design",
          "Living room makeover",
          "Kitchen design planning",
          "Bedroom decor ideas",
          "Custom furniture selection",
          "Interior space optimization",
          "Open-plan living design",
          "Colour palette consultation",
          "Whole home design",
          "Small space solutions",
        ],
      },
      {
        id: 2,
        name: "Commercial Interior Design",
        keywords: [
          "Office space planning",
          "Retail store design",
          "Restaurant interior styling",
          "Workspace optimization",
          "Commercial furniture sourcing",
          "Brand-aligned decor",
          "Hospitality interior design",
          "Meeting room design",
          "Reception area styling",
          "Co-working space design",
        ],
      },
      {
        id: 3,
        name: "Interior Styling & Decor",
        keywords: [
          "Home decor consultation",
          "Accessory and decor selection",
          "Seasonal styling updates",
          "Wall art arrangement",
          "Styling for photoshoots",
          "Furniture placement services",
          "Plant and greenery styling",
          "Custom lighting solutions",
          "Rug and textile selection",
          "Window treatments design",
        ],
      },
      {
        id: 4,
        name: "Kitchen & Bathroom Design",
        keywords: [
          "Custom kitchen layouts",
          "Kitchen cabinetry design",
          "Bathroom renovation planning",
          "Benchtop material selection",
          "Splashback design ideas",
          "Functional kitchen design",
          "Bathroom fixtures selection",
          "Modern kitchen concepts",
          "Ensuite bathroom design",
          "Compact kitchen solutions",
        ],
      },
      {
        id: 5,
        name: "Furniture & Material Selection",
        keywords: [
          "Custom furniture sourcing",
          "Material and finish selection",
          "Sustainable material options",
          "Upholstery fabric selection",
          "Built-in cabinetry design",
          "Lighting fixture selection",
          "Floor material consultation",
          "Bespoke joinery design",
          "Stone and tile selection",
          "High-end furniture procurement",
        ],
      },
      {
        id: 6,
        name: "Colour & Mood Consultation",
        keywords: [
          "Colour scheme development",
          "Mood board creation",
          "Paint colour consultation",
          "Interior ambience planning",
          "Texture and pattern mix",
          "Feature wall ideas",
          "Calming colour palette",
          "Bold accent colour tips",
          "Harmonious design schemes",
          "Room-specific colour advice",
        ],
      },
      {
        id: 7,
        name: "Space Planning & Layout Design",
        keywords: [
          "Floor plan optimization",
          "Furniture layout design",
          "Open-plan space planning",
          "Functional room arrangement",
          "Traffic flow assessment",
          "Storage solution design",
          "Ergonomic workspace design",
          "Zoning and partitioning",
          "Room division ideas",
          "Space-saving layout options",
        ],
      },
      {
        id: 8,
        name: "Lighting Design & Solutions",
        keywords: [
          "Ambient lighting planning",
          "Task lighting setup",
          "Accent lighting design",
          "Pendant light selection",
          "Custom light fixtures",
          "LED strip lighting ideas",
          "Layered lighting design",
          "Smart lighting integration",
          "Outdoor lighting concepts",
          "Lighting for art and decor",
        ],
      },
      { id: 9, name: "Custom Interior Design Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Garden & Landscaping",
    id: 23,
    subList: [
      {
        id: 1,
        name: "Garden Design & Planning",
        keywords: [
          "Custom garden design",
          "Outdoor space planning",
          "Plant selection services",
          "Landscape architecture",
          "Garden makeover ideas",
          "Native plant gardens",
          "Xeriscape design",
          "Garden layout optimization",
          "Water-efficient landscaping",
          "Themed garden designs",
        ],
      },
      {
        id: 2,
        name: "Lawn Installation & Care",
        keywords: [
          "New lawn installation",
          "Turf laying services",
          "Lawn aeration and care",
          "Lawn edging setup",
          "Artificial turf installation",
          "Lawn maintenance services",
          "Grass seeding and fertilizing",
          "Weed control solutions",
          "Lawn pest management",
          "Drought-resistant lawns",
        ],
      },
      {
        id: 3,
        name: "Planting & Garden Beds",
        keywords: [
          "Flower bed planting",
          "Vegetable garden setup",
          "Shrub and tree planting",
          "Raised garden bed installation",
          "Mulching and soil improvement",
          "Native plant installation",
          "Garden bed weeding",
          "Perennial garden designs",
          "Garden soil conditioning",
          "Seasonal planting services",
        ],
      },
      {
        id: 4,
        name: "Hardscaping & Outdoor Features",
        keywords: [
          "Paving and pathways",
          "Retaining wall construction",
          "Garden stonework",
          "Outdoor fire pit installation",
          "Decorative rock landscaping",
          "Stone edging for gardens",
          "Concrete paver patios",
          "Outdoor seating areas",
          "Timber garden beds",
          "Garden steps and stairs",
        ],
      },
      {
        id: 5,
        name: "Irrigation & Water Management",
        keywords: [
          "Sprinkler system installation",
          "Drip irrigation setup",
          "Garden water feature installation",
          "Rainwater harvesting systems",
          "Automatic irrigation controls",
          "Water-saving irrigation",
          "Greywater reuse systems",
          "Lawn sprinkler maintenance",
          "Garden drainage solutions",
          "Pond and water feature repair",
        ],
      },
      {
        id: 6,
        name: "Tree Services & Stump Grinding",
        keywords: [
          "Tree lopping and pruning",
          "Stump grinding services",
          "Emergency tree removal",
          "Tree health assessment",
          "Arborist consultation",
          "Palm tree maintenance",
          "Land clearing for construction",
          "Tree planting and relocation",
          "Tree mulching services",
          "Deadwood removal",
        ],
      },
      {
        id: 7,
        name: "Garden Maintenance & Cleanups",
        keywords: [
          "Regular garden maintenance",
          "Seasonal garden cleanup",
          "Weed removal and control",
          "Hedge trimming services",
          "Pruning and deadheading",
          "Leaf blowing and raking",
          "Soil and mulch replenishment",
          "Outdoor pressure washing",
          "Gutter cleaning for gardens",
          "Composting and green waste removal",
        ],
      },
      {
        id: 8,
        name: "Outdoor Lighting & Decor",
        keywords: [
          "Garden lighting installation",
          "Solar landscape lights",
          "Pathway lighting design",
          "Decorative garden lights",
          "Feature tree lighting",
          "Outdoor string lights setup",
          "Motion sensor garden lights",
          "Water feature lighting",
          "Festive garden decor",
          "Custom outdoor lighting",
        ],
      },
      { id: 9, name: "Custom Landscaping Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Locksmiths",
    id: 24,
    subList: [
      {
        id: 1,
        name: "Residential Locksmith",
        keywords: [
          "House lockout assistance",
          "Lock rekeying services",
          "Front door lock replacement",
          "Smart lock installation",
          "Window lock repairs",
          "Patio door lock installation",
          "Deadbolt installation",
          "Mailbox lock replacement",
          "Security door lock repair",
          "High-security lock upgrades",
        ],
      },
      {
        id: 2,
        name: "Commercial Locksmith",
        keywords: [
          "Office lockout solutions",
          "Master key system setup",
          "Commercial door lock installation",
          "Keyless entry system installation",
          "File cabinet lock repair",
          "Panic bar installation",
          "Safe lock combination change",
          "Commercial lock rekeying",
          "Access control system setup",
          "Biometric lock installation",
        ],
      },
      {
        id: 3,
        name: "Emergency Locksmith",
        keywords: [
          "24/7 emergency lockout",
          "Broken key extraction",
          "Urgent lock change",
          "Lock repair after a break-in",
          "Emergency door unlocking",
          "Immediate lock rekeying",
          "Fast locksmith response",
          "Overnight lock replacement",
          "Emergency safe unlocking",
          "Quick security lock upgrades",
        ],
      },
      {
        id: 4,
        name: "Lock Installation & Replacement",
        keywords: [
          "Deadbolt lock installation",
          "Door knob replacement",
          "High-security lock installation",
          "Smart door lock installation",
          "Lock replacement for rentals",
          "Sliding door lock installation",
          "Keypad lock setup",
          "Cabinet lock replacement",
          "Shed lock installation",
          "Gate lock installation",
        ],
      },
      {
        id: 5,
        name: "Lock Rekeying & Key Duplication",
        keywords: [
          "Lock rekeying service",
          "Key duplication near me",
          "Master key rekeying",
          "Key cutting for new locks",
          "Spare house key cutting",
          "High-security key duplication",
          "Rekeying for all door locks",
          "Quick key duplication",
          "Mailbox lock rekeying",
          "Rekeying for rental properties",
        ],
      },
      {
        id: 6,
        name: "Safe & Vault",
        keywords: [
          "Safe opening services",
          "Vault combination change",
          "Safe installation at home",
          "Safe lock repair",
          "Digital safe programming",
          "Biometric safe setup",
          "Fireproof safe installation",
          "Safe bolt-down service",
          "Antique safe restoration",
          "Commercial safe maintenance",
        ],
      },
      {
        id: 7,
        name: "Security Consultation & Upgrades",
        keywords: [
          "Home security assessment",
          "Lock and key system audit",
          "Security lock upgrades",
          "Residential security solutions",
          "Commercial security improvements",
          "Smart home security setup",
          "Lock hardware recommendations",
          "Security door reinforcements",
          "Access control upgrades",
          "Surveillance system integration",
        ],
      },
      { id: 8, name: "Custom Locksmith", keywords: [] },
    ],
  },
  {
    primaryCategory: "Painting & Decorating",
    id: 25,
    subList: [
      {
        id: 1,
        name: "Interior Painting",
        keywords: [
          "Wall painting services",
          "Ceiling painting",
          "Trim and moulding painting",
          "Accent wall painting",
          "Room repainting",
          "Furniture painting and refinishing",
          "Interior paint touch-ups",
          "Custom color matching",
          "Feature wall designs",
          "Low-VOC paint options",
        ],
      },
      {
        id: 2,
        name: "Exterior Painting",
        keywords: [
          "House exterior painting",
          "Weather-resistant coatings",
          "Fence and deck painting",
          "Garage door painting",
          "Exterior wall repainting",
          "Concrete and brick painting",
          "Outdoor timber staining",
          "Facade painting",
          "UV-protective paint",
          "Exterior paint restoration",
        ],
      },
      {
        id: 3,
        name: "Commercial Painting",
        keywords: [
          "Office painting services",
          "Retail store painting",
          "Restaurant interior painting",
          "Commercial exterior painting",
          "Industrial space painting",
          "Epoxy floor coating",
          "Commercial graffiti removal",
          "Safety line marking",
          "Hotel room painting",
          "School and daycare painting",
        ],
      },
      {
        id: 4,
        name: "Decorative Painting & Finishes",
        keywords: [
          "Faux finish painting",
          "Textured wall finishes",
          "Venetian plaster application",
          "Limewash painting",
          "Metallic and pearl finishes",
          "Murals and custom artwork",
          "Chalkboard wall painting",
          "Distressed wood finishes",
          "Color wash technique",
          "Stencil design painting",
        ],
      },
      {
        id: 5,
        name: "Wallpapering",
        keywords: [
          "Wallpaper installation",
          "Wallpaper removal",
          "Custom wall murals",
          "Vinyl wallpaper setup",
          "Textured wallpaper installation",
          "Peel and stick wallpaper",
          "Feature wall wallpapering",
          "Fabric wallpaper designs",
          "Wallpaper border application",
          "Wallpaper repair services",
        ],
      },
      {
        id: 6,
        name: "Special Surface Coatings",
        keywords: [
          "Anti-graffiti coatings",
          "Fire-retardant paint",
          "Waterproof coatings",
          "Anti-mold and mildew paint",
          "Slip-resistant floor coatings",
          "High-gloss enamel finishes",
          "Epoxy floor coating",
          "Rustproof metal paint",
          "Concrete floor sealers",
          "Kitchen splashback protection",
        ],
      },
      {
        id: 7,
        name: "Furniture Painting & Refinishing",
        keywords: [
          "Furniture painting services",
          "Furniture refinishing",
          "Spray painting furniture",
          "Built-in wardrobe painting",
          "Staining wooden furniture",
          "Lacquer finishes for furniture",
          "Custom furniture colors",
          "High-durability furniture paint",
          "Outdoor furniture painting",
          "Furniture touch-ups",
        ],
      },
      {
        id: 8,
        name: "Color Consultation & Design",
        keywords: [
          "Interior color consultation",
          "Exterior color schemes",
          "Paint sample boards",
          "Mood board creation",
          "Color psychology in design",
          "Choosing accent colors",
          "Matching paint with decor",
          "Seasonal color updates",
          "Trending color palettes",
          "Custom paint design advice",
        ],
      },
      { id: 9, name: "Custom Painting Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Pest Control",
    id: 26,
    subList: [
      {
        id: 1,
        name: "General Pest Control",
        keywords: [
          "Home pest treatment",
          "Residential pest control",
          "Commercial pest management",
          "Seasonal pest prevention",
          "Routine pest inspection",
          "Pest control for rentals",
          "Safe pest control solutions",
          "Child and pet-friendly pest control",
          "Pest monitoring services",
          "Environmentally friendly pesticides",
        ],
      },
      {
        id: 2,
        name: "Termite Inspections & Treatment",
        keywords: [
          "Termite inspection services",
          "Termite barrier installation",
          "Termite baiting systems",
          "Pre-construction termite treatment",
          "Termite damage repair",
          "Chemical soil treatment",
          "Termite colony eradication",
          "Wood preservation treatment",
          "Post-construction termite control",
          "Termite monitoring stations",
        ],
      },
      {
        id: 3,
        name: "Rodent Control",
        keywords: [
          "Rat and mouse extermination",
          "Rodent-proofing homes",
          "Bait station setup",
          "Mice removal services",
          "Rodent infestation cleanup",
          "Electronic rodent repellents",
          "Garage rodent control",
          "Attic rodent inspections",
          "Dead rodent removal",
          "Rodent prevention strategies",
        ],
      },
      {
        id: 4,
        name: "Ant Control",
        keywords: [
          "Ant infestation treatment",
          "Indoor ant control",
          "Outdoor ant management",
          "Ant nest eradication",
          "White ant treatment",
          "Ant baiting solutions",
          "Kitchen ant control",
          "Garden ant removal",
          "Carpenter ant extermination",
          "Pet-safe ant treatments",
        ],
      },
      {
        id: 5,
        name: "Cockroach Control",
        keywords: [
          "Cockroach extermination",
          "Kitchen roach treatment",
          "German cockroach control",
          "Roach bait stations",
          "Commercial roach management",
          "Apartment cockroach treatment",
          "Organic cockroach control",
          "Restaurant cockroach prevention",
          "Emergency cockroach removal",
          "Roach infestation prevention",
        ],
      },
      {
        id: 6,
        name: "Spider Control",
        keywords: [
          "Spider extermination",
          "Redback spider treatment",
          "Indoor spider control",
          "Outdoor spider management",
          "Web removal services",
          "Spider-proofing homes",
          "Garage spider removal",
          "Funnel-web spider control",
          "Spider spray application",
          "Brown recluse treatment",
        ],
      },
      {
        id: 7,
        name: "Bed Bug Treatment",
        keywords: [
          "Bed bug heat treatment",
          "Bed bug infestation control",
          "Mattress encasement solutions",
          "Bed bug inspection services",
          "Whole-home bed bug treatment",
          "Bed bug steam cleaning",
          "Bed bug monitoring",
          "Residential bed bug removal",
          "Bed bug prevention tips",
          "Bed frame inspection",
        ],
      },
      {
        id: 8,
        name: "Flea & Tick Control",
        keywords: [
          "Flea extermination services",
          "Tick treatment for yards",
          "Pet-safe flea control",
          "Flea and tick prevention",
          "Carpet flea treatment",
          "Outdoor tick management",
          "Flea infestation cleanup",
          "Dog-friendly tick treatment",
          "Cat flea control solutions",
          "Yard flea and tick spray",
        ],
      },
      {
        id: 9,
        name: "Wasp & Bee Removal",
        keywords: [
          "Wasp nest removal",
          "Bee hive relocation",
          "Safe bee removal",
          "Hornet nest extermination",
          "Wasp control services",
          "Carpenter bee treatment",
          "Emergency wasp removal",
          "Backyard wasp prevention",
          "Honey bee protection",
          "Nest removal from roofs",
        ],
      },
      { id: 10, name: "Custom Pest Control Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Solid Plastering",
    id: 27,
    subList: [
      {
        id: 1,
        name: "Wall & Ceiling Plastering",
        keywords: [
          "Wall plastering services",
          "Ceiling plaster repairs",
          "Plasterboard installation",
          "Skim coating walls",
          "Smooth finish plastering",
          "Drywall taping and jointing",
          "Gyprock installation",
          "Bulkhead plastering",
          "Plaster crack repairs",
          "Plaster hole patching",
        ],
      },
      {
        id: 2,
        name: "Decorative Plasterwork",
        keywords: [
          "Cornice installation",
          "Decorative ceiling roses",
          "Ornamental plaster moulding",
          "Custom plaster cornices",
          "Decorative wall panels",
          "Archway plaster designs",
          "Heritage plaster restoration",
          "Plaster ceiling medallions",
          "Victorian plaster features",
          "Art Deco plaster detailing",
        ],
      },
      {
        id: 3,
        name: "Partition Wall Construction",
        keywords: [
          "Stud wall plastering",
          "Partition wall setup",
          "Soundproof partition walls",
          "Office partition plastering",
          "Lightweight partition installation",
          "Temporary plasterboard walls",
          "Plastering for room dividers",
          "Insulated plaster walls",
          "Commercial office partitions",
          "Fire-rated partition systems",
        ],
      },
      {
        id: 4,
        name: "Suspended Ceilings & Bulkheads",
        keywords: [
          "Suspended ceiling installation",
          "Drop ceiling plastering",
          "Acoustic ceiling panels",
          "Bulkhead construction",
          "Decorative bulkhead plastering",
          "Plasterboard ceiling tiles",
          "Ceiling grid systems",
          "Hidden bulkhead lighting",
          "Fire-rated ceiling installation",
          "Suspended ceiling repairs",
        ],
      },
      {
        id: 5,
        name: "Plaster Repair & Maintenance",
        keywords: [
          "Hole patching in plaster",
          "Water-damaged plaster repair",
          "Sagging ceiling repair",
          "Plaster crack filling",
          "Mouldy plaster removal",
          "Re-skimming old plaster",
          "Plaster reattachment",
          "Corner bead replacement",
          "Impact damage repair",
          "Ceiling plaster restoration",
        ],
      },
      {
        id: 6,
        name: "Heritage & Restoration Plastering",
        keywords: [
          "Heritage plaster restoration",
          "Period home plastering",
          "Traditional plastering techniques",
          "Lime plaster repairs",
          "Plaster moulding replication",
          "Restoration of ceiling roses",
          "Decorative cornice matching",
          "Federation-style plastering",
          "Heritage ceiling repair",
          "Historic building plasterwork",
        ],
      },
      { id: 7, name: "Custom Plastering Projects" },
    ],
  },
  {
    primaryCategory: "Plumbing",
    id: 28,
    subList: [
      {
        "id": 1,
        "name": "General Plumbing Repairs",
        "keywords": [
          "Leaky faucet repair",
          "Burst pipe repair",
          "Toilet leak fixes",
          "Water pressure issues",
          "Tap and mixer replacement",
          "Sink plumbing repair",
          "Toilet cistern replacement",
          "Pipe joint sealing",
          "Plumbing maintenance services",
          "Home plumbing repairs"
        ]
      },
      {
        "id": 2,
        "name": "Blocked Drains & Drainage Solutions",
        "keywords": [
          "Blocked drain clearing",
          "Stormwater drain unblocking",
          "Sewer line jetting",
          "Drain camera inspections",
          "Root removal from drains",
          "Kitchen sink blockage repair",
          "Shower drain unclogging",
          "Outdoor drainage solutions",
          "Drain relining services",
          "Emergency drain unblocking"
        ]
      },
      {
        "id": 3,
        "name": "Hot Water Systems",
        "keywords": [
          "Hot water system installation",
          "Water heater repairs",
          "Tankless water heater setup",
          "Solar hot water systems",
          "Heat pump water heater",
          "Gas hot water service",
          "Electric hot water replacement",
          "Thermostat repairs",
          "Hot water maintenance",
          "Emergency hot water services"
        ]
      },
      {
        "id": 4,
        "name": "Gas Fitting Services",
        "keywords": [
          "Gas line installation",
          "Gas leak detection",
          "Gas appliance installation",
          "Gas pipe repairs",
          "LPG gas fitting",
          "Natural gas conversions",
          "Gas safety inspections",
          "Gas hot water systems",
          "Outdoor BBQ gas fitting",
          "Emergency gas repairs"
        ]
      },
      {
        "id": 5,
        "name": "Pipework & Replacements",
        "keywords": [
          "Pipe relining solutions",
          "Copper pipe installation",
          "Poly pipe replacements",
          "Pipe corrosion prevention",
          "Water pipe rerouting",
          "Underground pipe repairs",
          "Burst pipe replacement",
          "Flexible hose installations",
          "Pipe insulation services",
          "Main water line repairs"
        ]
      },
      {
        "id": 6,
        "name": "Bathroom & Kitchen Plumbing",
        "keywords": [
          "Showerhead installation",
          "Vanity plumbing setup",
          "Kitchen sink plumbing",
          "Bathtub drain repairs",
          "Dishwasher plumbing services",
          "Garbage disposal installation",
          "Bathroom tapware upgrades",
          "Sink and basin replacements",
          "Shower leak detection",
          "Bath and spa plumbing"
        ]
      },
      {
        "id": 7,
        "name": "Roof & Gutter Plumbing",
        "keywords": [
          "Roof leak repairs",
          "Gutter and downpipe installation",
          "Gutter blockage clearing",
          "Roof plumbing maintenance",
          "Stormwater drain connection",
          "Flashing installation",
          "Gutter guard installation",
          "Rainwater tank plumbing",
          "Downpipe replacement",
          "Overflow prevention systems"
        ]
      },
      {
        "id": 8,
        "name": "Water Filtration & Purification",
        "keywords": [
          "Water filter installation",
          "Whole-house water filtration",
          "Under-sink water filters",
          "UV water purification",
          "Reverse osmosis systems",
          "Water softener setup",
          "Tap water filter replacement",
          "Water quality testing",
          "Filtered water taps",
          "Sediment filter maintenance"
        ]
      },
      {
        "id": 9,
        "name": "Emergency Plumbing Services",
        "keywords": [
          "24/7 emergency plumber",
          "After-hours plumbing repairs",
          "Burst pipe emergencies",
          "Urgent toilet repairs",
          "Same-day plumber service",
          "Rapid leak detection",
          "Emergency hot water repair",
          "Sewer backup emergencies",
          "Fast drain unblocking",
          "On-call plumbing support"
        ]
      },
      // { id: 10, name: "Custom Plumbing Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Pools & Spas",
    id: 29,
    subList: [
      {
        "id": 1,
        "name": "Pool Installation & Construction",
        "keywords": [
          "Inground pool construction",
          "Above-ground pool setup",
          "Concrete pool installation",
          "Fiberglass pool construction",
          "Pool excavation services",
          "Pool design and planning",
          "Custom pool builds",
          "Infinity pool installation",
          "Plunge pool construction",
          "Lap pool installation"
        ]
      },
      {
        "id": 2,
        "name": "Spa & Hot Tub Installation",
        "keywords": [
          "Hot tub installation",
          "Outdoor spa setup",
          "Indoor spa installation",
          "Portable hot tub assembly",
          "Spa plumbing services",
          "Custom spa design",
          "Swim spa installation",
          "Spa deck construction",
          "Energy-efficient spa setup",
          "Spa gazebo installation"
        ]
      },
      {
        "id": 3,
        "name": "Pool & Spa Maintenance",
        "keywords": [
          "Regular pool cleaning",
          "Spa maintenance services",
          "Pool water testing",
          "Chemical balancing",
          "Pool filter cleaning",
          "Weekly pool servicing",
          "Algae prevention treatment",
          "Pool equipment maintenance",
          "Spa water treatment",
          "Pool pump servicing"
        ]
      },
      {
        "id": 4,
        "name": "Pool Heating & Equipment Installation",
        "keywords": [
          "Pool heater installation",
          "Solar pool heating systems",
          "Electric pool heaters",
          "Heat pump installation",
          "Pool cover installation",
          "Pool chlorinator setup",
          "Automatic pool cleaner installation",
          "Pool light installation",
          "Pool automation systems",
          "Variable-speed pool pumps"
        ]
      },
      {
        "id": 5,
        "name": "Pool & Spa Repairs",
        "keywords": [
          "Pool leak detection",
          "Cracked pool repair",
          "Spa pump repairs",
          "Pool resurfacing",
          "Vinyl liner replacement",
          "Broken tile repair",
          "Spa jet replacement",
          "Pool equipment troubleshooting",
          "Pool plaster repair",
          "Spa heater repairs"
        ]
      },
      {
        "id": 6,
        "name": "Pool Resurfacing & Renovation",
        "keywords": [
          "Pool resurfacing services",
          "Pebblecrete pool finishes",
          "Pool tile renovation",
          "Pool coping replacement",
          "Interior pool surface update",
          "Pool deck resurfacing",
          "Pool plastering services",
          "Pool colour change",
          "Aggregate pool finishes",
          "Pool design makeover"
        ]
      },
      {
        "id": 7,
        "name": "Safety & Compliance",
        "keywords": [
          "Pool fence compliance",
          "Pool safety inspections",
          "Spa safety barrier installation",
          "Childproof pool gates",
          "Pool cover compliance",
          "Pool depth marking",
          "Anti-slip pool decking",
          "CPR sign installation",
          "Pool area lighting compliance",
          "Pool safety audits"
        ]
      },
      { id: 8, name: "Custom Pool & Spa Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Rendering",
    id: 30,
    subList: [
      {
        "id": 1,
        "name": "Cement Rendering",
        "keywords": [
          "Cement render application",
          "Sand and cement rendering",
          "Smooth cement finishes",
          "Textured cement render",
          "External cement rendering",
          "Cement render for brick walls",
          "Traditional cement rendering",
          "Indoor cement render",
          "House facade cement render",
          "Cement render repairs"
        ]
      },
      {
        "id": 2,
        "name": "Acrylic Rendering",
        "keywords": [
          "Acrylic render services",
          "Weather-resistant acrylic render",
          "Flexible acrylic finishes",
          "Acrylic render for concrete",
          "Quick-dry acrylic rendering",
          "Acrylic render over old brick",
          "Durable acrylic coating",
          "Colored acrylic rendering",
          "Crack-resistant acrylic render",
          "Acrylic texture coats"
        ]
      },
      {
        "id": 3,
        "name": "Bagging & Bag Render",
        "keywords": [
          "Wall bagging services",
          "Bag render application",
          "Brick wall bagging",
          "Lightweight bag render",
          "Rough bagged finish",
          "Decorative bagging",
          "Bagged render for exterior walls",
          "Painted bag render",
          "Textured bagging techniques",
          "Cost-effective bagging solutions"
        ]
      },
      {
        "id": 4,
        "name": "Decorative & Textured Finishes",
        "keywords": [
          "Textured wall rendering",
          "Stucco finish application",
          "Polished plaster finishes",
          "Rustic wall rendering",
          "Stone-look render effects",
          "Decorative exterior rendering",
          "Smooth texture finishes",
          "Limestone rendering",
          "Patterned render designs",
          "Heritage texture restoration"
        ]
      },
      {
        "id": 5,
        "name": "Polymer Rendering",
        "keywords": [
          "Polymer render services",
          "High-bond polymer render",
          "Waterproof polymer coating",
          "Flexible polymer rendering",
          "Polymer render for walls",
          "Long-lasting polymer finishes",
          "Polymer-based crack repair",
          "Quick-set polymer rendering",
          "UV-resistant polymer render",
          "Polymer render for high-rises"
        ]
      },
      {
        "id": 6,
        "name": "Heritage Rendering & Restoration",
        "keywords": [
          "Heritage render restoration",
          "Traditional lime rendering",
          "Period home rendering",
          "Historic building render repair",
          "Heritage facade preservation",
          "Decorative heritage finishes",
          "Restoration of old render",
          "Authentic heritage techniques",
          "Lime render applications",
          "Architectural heritage render"
        ]
      },
      {
        "id": 7,
        "name": "Stucco Services",
        "keywords": [
          "Stucco finish application",
          "Traditional stucco installation",
          "Decorative stucco textures",
          "Exterior stucco wall coatings",
          "Stucco crack repair",
          "Smooth stucco finishes",
          "Rustic stucco applications",
          "Durable stucco coatings",
          "Water-resistant stucco",
          "Stucco restoration and refinishing"
        ]
      },
      { id: 8, name: "Custom Rendering Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Renovation & Restoration",
    id: 31,
    subList: [
      {
        "id": 1,
        "name": "Home Renovations",
        "keywords": [
          "Home remodeling services",
          "Complete home renovations",
          "Interior home renovation",
          "Home extension construction",
          "Residential home improvements",
          "Home renovation planning",
          "Flooring and tiling upgrades",
          "Home redesign services",
          "Whole-house renovations",
          "Property renovation experts"
        ]
      },
      {
        "id": 2,
        "name": "Bathroom Renovations",
        "keywords": [
          "Bathroom remodeling services",
          "Custom bathroom design",
          "Shower and bathtub installation",
          "Vanity and sink upgrades",
          "Luxury bathroom renovations",
          "Bathroom tiling services",
          "Waterproofing bathroom walls",
          "Modern bathroom designs",
          "Eco-friendly bathroom solutions",
          "Bathroom lighting and fixtures"
        ]
      },
      {
        "id": 3,
        "name": "Kitchen Renovations",
        "keywords": [
          "Kitchen remodeling",
          "Custom kitchen design",
          "Cabinet installation",
          "Kitchen countertop upgrades",
          "Modern kitchen layouts",
          "Kitchen plumbing and electrical",
          "Open-plan kitchen design",
          "Kitchen flooring installation",
          "Eco-friendly kitchen solutions",
          "Kitchen renovation specialists"
        ]
      },
      {
        "id": 4,
        "name": "Structural Repairs & Alterations",
        "keywords": [
          "Structural repairs for homes",
          "Foundation repair services",
          "Load-bearing wall removal",
          "Home structural alterations",
          "Concrete foundation repair",
          "Structural support installation",
          "Foundation crack repairs",
          "Building modifications",
          "Reinforcement of structural integrity",
          "Property foundation inspections"
        ]
      },
      {
        "id": 5,
        "name": "Heritage Restorations",
        "keywords": [
          "Heritage building restoration",
          "Period home restoration",
          "Historical home repairs",
          "Architectural heritage preservation",
          "Traditional craftsmanship restoration",
          "Restoration of heritage facades",
          "Historical building conservation",
          "Heritage stonework repairs",
          "Antique wood restoration",
          "Preserving original features"
        ]
      },
      {
        "id": 6,
        "name": "Outdoor Renovations",
        "keywords": [
          "Outdoor living space design",
          "Patio construction and design",
          "Landscape garden renovation",
          "Decking and pergola installation",
          "Garden paths and driveways",
          "Outdoor kitchen installation",
          "Swimming pool landscaping",
          "Hardscaping services",
          "Outdoor lighting solutions",
          "Backyard renovation projects"
        ]
      },
      {
        "id": 7,
        "name": "Fire & Water Damage Restorations",
        "keywords": [
          "Fire damage repair",
          "Water damage restoration",
          "Mold removal and prevention",
          "Smoke damage cleaning",
          "Flood damage restoration",
          "Fire and water damage repairs",
          "Emergency damage restoration",
          "Waterproofing and sealing",
          "Fire and water mitigation",
          "Insurance claims for damage repair"
        ]
      },
      { id: 8, name: "Custom Renovation Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Roofing",
    id: 32,
    subList: [
      {
        "id": 1,
        "name": "Roof Installation & Replacement",
        "keywords": [
          "New roof installation",
          "Roof replacement services",
          "Metal roofing installation",
          "Colorbond roof replacement",
          "Tile roof installation",
          "Flat roof construction",
          "Slate roofing services",
          "Custom roof designs",
          "Energy-efficient roofing",
          "Roof installation contractors"
        ]
      },
      {
        "id": 2,
        "name": "Roof Repairs & Maintenance",
        "keywords": [
          "Roof leak repair",
          "Broken tile replacement",
          "Gutter and downpipe repair",
          "Flashing repair services",
          "Roof inspection and maintenance",
          "Ridge capping repairs",
          "Roof re-bedding and pointing",
          "Roof storm damage repair",
          "Moss and lichen removal",
          "Emergency roof repairs"
        ]
      },
      {
        "id": 3,
        "name": "Roof Restoration",
        "keywords": [
          "Complete roof restoration",
          "Roof cleaning and repainting",
          "Roof sealing services",
          "Roof pressure washing",
          "Tile roof restoration",
          "Metal roof restoration",
          "Roof repointing and re-coating",
          "Roof membrane application",
          "Roof resealing for longevity",
          "Heritage roof restoration"
        ]
      },
      {
        "id": 4,
        "name": "Gutter & Downpipe Services",
        "keywords": [
          "Gutter installation",
          "Downpipe replacement",
          "Gutter guard installation",
          "Gutter cleaning services",
          "Seamless gutter systems",
          "Leaf and debris protection",
          "Custom downpipe designs",
          "Gutter realignment",
          "Rainwater drainage solutions",
          "Box gutter installation"
        ]
      },
      {
        "id": 5,
        "name": "Roof Insulation & Ventilation",
        "keywords": [
          "Roof insulation installation",
          "Attic insulation services",
          "Roof ventilation systems",
          "Whirlybird installation",
          "Solar roof ventilation",
          "Energy-efficient roof insulation",
          "Skylight ventilation installation",
          "Ceiling cavity insulation",
          "Thermal roof protection",
          "Under-roof insulation"
        ]
      },
      {
        "id": 6,
        "name": "Metal & Colorbond Roofing",
        "keywords": [
          "Colorbond roof installation",
          "Metal roofing sheets",
          "Corrugated metal roofing",
          "Zincalume roof installation",
          "Metal roof replacement",
          "Custom Colorbond finishes",
          "Insulated metal panels",
          "Fire-resistant metal roofing",
          "Long-span metal roofing",
          "Colorbond roof repairs"
        ]
      },
      {
        "id": 7,
        "name": "Flat Roofing Services",
        "keywords": [
          "Flat roof waterproofing",
          "Rubber membrane installation",
          "TPO roofing systems",
          "Flat roof repairs",
          "Polycarbonate flat roofing",
          "Insulated flat roof panels",
          "Flat roof replacement",
          "Green roof installations",
          "Fibreglass flat roofing",
          "Flat roof drainage solutions"
        ]
      },
      {
        "id": 8,
        "name": "Roof Skylights & Installations",
        "keywords": [
          "Skylight installation",
          "Skylight leak repairs",
          "Custom skylight designs",
          "Fixed skylight installation",
          "Solar-powered skylights",
          "Roof window installation",
          "Skylight blinds and shutters",
          "Skylight dome replacements",
          "Roof light tubes",
          "Skylight sealing and maintenance"
        ]
      },
      { id: 9, name: "Custom Roofing Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Scaffolding",
    id: 33,
    subList: [
      {
        "id": 1,
        "name": "Scaffold Erection & Dismantling",
        "keywords": [
          "Scaffold erection services",
          "Scaffold dismantling solutions",
          "Safe scaffold assembly",
          "Professional scaffold setup",
          "Certified scaffold erectors",
          "Scaffold disassembly",
          "Quick scaffold installation",
          "On-site scaffold management",
          "Height adjustment scaffolding",
          "Scaffolds dismantle crews"
        ]
      },
      {
        "id": 2,
        "name": "Mobile & Lightweight Scaffolding",
        "keywords": [
          "Mobile scaffold solutions",
          "Lightweight scaffold assembly",
          "Wheeled scaffolding services",
          "Easy-move scaffold setup",
          "Foldable mobile scaffolds",
          "Adjustable mobile platforms",
          "Compact scaffold structures",
          "Rolling scaffold platforms",
          "Portable scaffold setups",
          "Lightweight frame scaffolding"
        ]
      },
      {
        "id": 3,
        "name": "Heavy-Duty Scaffolding Services",
        "keywords": [
          "Heavy-duty scaffold assembly",
          "Industrial scaffold setup",
          "High-load scaffold platforms",
          "Strong scaffold structures",
          "Load-bearing scaffolding",
          "Multi-level scaffold installation",
          "Reinforced scaffold solutions",
          "Structural scaffolding services",
          "Durable scaffold components",
          "Heavy-duty frame scaffolding"
        ]
      },
      {
        "id": 4,
        "name": "Safety Systems & Compliance",
        "keywords": [
          "Scaffold safety inspections",
          "Safety harness setup",
          "Scaffold compliance checks",
          "Guardrail installation",
          "Fall prevention systems",
          "Scaffold safety barriers",
          "Secure scaffold anchoring",
          "Safety net installation",
          "Scaffold hazard assessments",
          "Worksite safety management"
        ]
      },
      {
        "id": 5,
        "name": "Custom Scaffolding Solutions",
        "keywords": [
          "Custom scaffold design",
          "Tailored scaffold solutions",
          "Scaffolding for unique structures",
          "Specialized scaffold builds",
          "Bespoke scaffold installation",
          "Scaffold for complex projects",
          "Project-specific scaffolding",
          "Innovative scaffold systems",
          "Scaffold design consulting",
          "Complex scaffold engineering"
        ]
      }
    ],
  },
  {
    primaryCategory: "Shopfitting",
    id: 34,
    subList: [
      {
        "id": 1,
        "name": "Retail Fit-Outs",
        "keywords": [
          "Retail store fit-outs",
          "Custom shop design",
          "Store layout planning",
          "Retail shelving installation",
          "Display cabinet setup",
          "Cash wrap counter installation",
          "Lighting design for retail",
          "Boutique fit-out services",
          "Fashion store renovation",
          "Retail space optimization"
        ]
      },
      {
        "id": 2,
        "name": "Commercial Interiors",
        "keywords": [
          "Office interior fit-outs",
          "Restaurant fit-out services",
          "Café and bar fit-outs",
          "Medical clinic fit-outs",
          "Commercial kitchen setup",
          "Hospitality interior design",
          "Custom office partitions",
          "Reception area fit-outs",
          "Open-plan workspace design",
          "Corporate meeting room setup"
        ]
      },
      {
        "id": 3,
        "name": "Custom Joinery & Fixtures",
        "keywords": [
          "Custom joinery solutions",
          "Built-in display units",
          "Bespoke cabinetry",
          "Countertop fabrication",
          "Wooden shelving units",
          "Custom bar counters",
          "Retail display tables",
          "Unique shop fixtures",
          "High-quality joinery work",
          "Custom-made storage solutions"
        ]
      },
      {
        "id": 4,
        "name": "Signage & Branding",
        "keywords": [
          "Custom shopfront signage",
          "Illuminated signage",
          "3D logo installations",
          "Window graphics application",
          "Internal brand displays",
          "Digital signage setup",
          "Wayfinding signage design",
          "Hanging sign installations",
          "Shop branding solutions",
          "Exterior signage mounting"
        ]
      },
      {
        "id": 5,
        "name": "Shop Renovations & Refurbishments",
        "keywords": [
          "Complete shop refurbishment",
          "Storefront renovation",
          "Flooring replacement services",
          "Ceiling and lighting upgrades",
          "Modernizing retail spaces",
          "Shop facade restoration",
          "Shop expansion projects",
          "Retail renovation planning",
          "Space reconfiguration",
          "High-traffic area durability"
        ]
      },
      {
        "id": 6,
        "name": "Security & Safety Installations",
        "keywords": [
          "Security gate installation",
          "Roller shutter setup",
          "Shop alarm systems",
          "Emergency exit lighting",
          "Safety barrier installations",
          "Fire safety compliance",
          "CCTV and surveillance setup",
          "Anti-theft measures",
          "Secure lock installations",
          "Glass partition safety film"
        ]
      },
      {
        "id": 7,
        "name": "Project Management & Consulting",
        "keywords": [
          "Shopfitting project management",
          "Fit-out consulting services",
          "Budget planning for fit-outs",
          "Timeline management",
          "Quality control oversight",
          "Supplier coordination",
          "On-site project supervision",
          "Cost-effective shopfitting",
          "Client consultation meetings",
          "Custom fit-out planning"
        ]
      },
      { id: 8, name: "Custom Shopfitting Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Skip Bins",
    id: 35,
    subList: [
      {
        "id": 1,
        "name": "Skip Bin Hire & Delivery",
        "keywords": [
          "Skip bin delivery",
          "Local skip bin hire",
          "Residential skip bins",
          "Commercial skip bin hire",
          "Same-day skip bin service",
          "Affordable bin hire",
          "Mini skip bin hire",
          "Large skip bin delivery",
          "Fast skip bin drop-off",
          "Scheduled bin pickups"
        ]
      },
      {
        "id": 2,
        "name": "Waste Types & Disposal",
        "keywords": [
          "General waste skip bins",
          "Green waste disposal",
          "Construction waste bins",
          "Mixed waste skip hire",
          "Heavy waste removal",
          "Household cleanup bins",
          "Soil and dirt bin hire",
          "Renovation waste bins",
          "Garden waste skip bins",
          "Office waste skip bins"
        ]
      },
      {
        "id": 3,
        "name": "Recycling & Sustainable Disposal",
        "keywords": [
          "Recycling skip bins",
          "Eco-friendly waste solutions",
          "Metal recycling bins",
          "Paper and cardboard recycling",
          "Green waste composting",
          "Concrete recycling services",
          "Plastic waste recycling",
          "Responsible waste disposal",
          "Sustainable waste management",
          "Recyclable material bins"
        ]
      },
      {
        "id": 4,
        "name": "Specialty Waste Solutions",
        "keywords": [
          "Asbestos skip bins",
          "Hazardous waste skip hire",
          "E-waste bin disposal",
          "Contaminated soil removal",
          "Medical waste bins",
          "Chemical waste management",
          "Sharps disposal bins",
          "Industrial waste solutions",
          "Waste oil collection bins",
          "Regulated waste skip hire"
        ]
      },
      {
        "id": 5,
        "name": "Site-Specific Services",
        "keywords": [
          "Construction site skip bins",
          "Demolition waste removal",
          "Event waste management",
          "Renovation site cleanup",
          "Landscaping waste bins",
          "Factory waste bin hire",
          "Shop fit-out waste bins",
          "Warehouse cleanup bins",
          "School waste bin services",
          "Festival waste solutions"
        ]
      },
      { id: 6, name: "Custom Skip Bin Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Solar Power",
    id: 36,
    subList: [
      {
        "id": 1,
        "name": "Solar Panel Installation",
        "keywords": [
          "Residential solar panel installation",
          "Commercial solar panel setup",
          "Rooftop solar systems",
          "Custom solar energy solutions",
          "Ground-mounted solar panels",
          "Solar panel design and installation",
          "Off-grid solar installations",
          "Solar panel for new builds",
          "Solar mounting systems",
          "Certified solar panel installers"
        ]
      },
      {
        "id": 2,
        "name": "Solar System Maintenance & Repairs",
        "keywords": [
          "Solar panel maintenance",
          "Solar system inspection",
          "Solar inverter repairs",
          "Solar panel cleaning services",
          "Faulty panel replacement",
          "Performance monitoring for solar",
          "Solar wiring repairs",
          "System efficiency checks",
          "Preventative solar maintenance",
          "Warranty servicing for solar systems"
        ]
      },
      {
        "id": 3,
        "name": "Battery Storage Solutions",
        "keywords": [
          "Solar battery installation",
          "Home energy storage systems",
          "Off-grid battery solutions",
          "Solar battery backup systems",
          "Hybrid solar battery setup",
          "Smart energy management",
          "High-capacity battery storage",
          "Lithium-ion battery solutions",
          "Battery bank installation",
          "Solar power storage upgrades"
        ]
      },
      {
        "id": 4,
        "name": "Solar System Upgrades & Expansions",
        "keywords": [
          "Solar panel system upgrades",
          "Expanding existing solar arrays",
          "Inverter technology upgrades",
          "Adding battery storage to solar",
          "Upgrading solar capacity",
          "Retrofitting solar systems",
          "Performance enhancement upgrades",
          "Solar panel reconfiguration",
          "Smart meter installations",
          "Enhanced solar efficiency solutions"
        ]
      },
      {
        "id": 5,
        "name": "Solar Energy Consulting & Design",
        "keywords": [
          "Solar energy system design",
          "Custom solar planning",
          "Solar feasibility studies",
          "Energy consumption analysis",
          "Solar financial consulting",
          "Renewable energy consulting",
          "Tailored solar energy plans",
          "Site analysis for solar",
          "Solar ROI calculations",
          "Sustainable energy strategies"
        ]
      },
      {
        "id": 6,
        "name": "Off-Grid & Hybrid Solar Systems",
        "keywords": [
          "Off-grid solar solutions",
          "Hybrid solar energy systems",
          "Remote area solar setups",
          "Solar and wind hybrid systems",
          "Autonomous energy systems",
          "Energy independence solutions",
          "Standalone solar installations",
          "Self-sufficient energy systems",
          "Solar generator setups",
          "Emergency backup solar systems"
        ]
      },
      {
        "id": 7,
        "name": "Commercial Solar Projects",
        "keywords": [
          "Commercial solar energy solutions",
          "Business solar installations",
          "Large-scale solar panel setups",
          "Solar for warehouses",
          "Solar for office buildings",
          "Energy savings for businesses",
          "Industrial solar energy systems",
          "Commercial solar maintenance",
          "Solar for retail spaces",
          "Tax incentives for solar"
        ]
      },
      { id: 8, name: "Custom Solar Energy Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Staircase Services",
    id: 37,
    subList: [
      {
        "id": 1,
        "name": "Custom Staircase Design & Installation",
        "keywords": [
          "Custom staircase design",
          "Modern stair installation",
          "Timber staircase setup",
          "Floating staircase construction",
          "Spiral staircase installation",
          "Steel and glass staircases",
          "Open riser stairs",
          "Bespoke staircase projects",
          "Architectural staircases",
          "Space-saving stair designs"
        ]
      },
      {
        "id": 2,
        "name": "Staircase Renovation & Upgrades",
        "keywords": [
          "Staircase renovation",
          "Stair tread replacement",
          "Balustrade upgrades",
          "Handrail installation",
          "Modernizing old stairs",
          "Wooden stair refinishing",
          "Carpet to timber stair conversion",
          "Steel staircase refinishing",
          "Staircase restoration services",
          "Updating staircase aesthetics"
        ]
      },
      {
        "id": 3,
        "name": "Handrails & Balustrades",
        "keywords": [
          "Custom handrail installation",
          "Glass balustrade setup",
          "Wrought iron handrails",
          "Stainless steel balustrades",
          "Timber handrail design",
          "Safety balustrade installation",
          "Decorative railing systems",
          "Outdoor balustrades",
          "Handrail replacement services",
          "Balustrade compliance checks"
        ]
      },
      {
        "id": 4,
        "name": "Outdoor & Deck Stairs",
        "keywords": [
          "Outdoor stair construction",
          "Deck stair installation",
          "Weatherproof stairs",
          "Timber deck stairs",
          "Composite deck staircases",
          "Garden staircase setup",
          "Non-slip outdoor steps",
          "Exterior stairway design",
          "Poolside stairs",
          "Durable outdoor stair solutions"
        ]
      },
      {
        "id": 5,
        "name": "Accessibility & Safety Stairs",
        "keywords": [
          "Accessibility stair solutions",
          "Wheelchair ramp installation",
          "Non-slip stair treads",
          "Safety stair rails",
          "Stair lifts for homes",
          "Child safety gates for stairs",
          "Elderly-friendly staircases",
          "Slip-resistant coatings",
          "Emergency exit stairs",
          "Compliance with safety standards"
        ]
      },
      {
        "id": 6,
        "name": "Spiral & Feature Staircases",
        "keywords": [
          "Spiral staircases",
          "Curved staircase design",
          "Helical stair construction",
          "Statement staircase projects",
          "Artistic staircase builds",
          "Loft access spiral stairs",
          "Contemporary staircases",
          "Compact spiral stairs",
          "Feature stair lighting",
          "Unique staircase solutions"
        ]
      },
      {
        "id": 7,
        "name": "Structural Stair Repairs",
        "keywords": [
          "Structural stair repairs",
          "Fixing squeaky stairs",
          "Replacing stair stringers",
          "Staircase stability improvements",
          "Concrete stair crack repair",
          "Reinforcing stair supports",
          "Tread and riser stabilization",
          "Strengthening wooden stairs",
          "Fixing broken handrails",
          "Repointing stone staircases"
        ]
      },
      { id: 8, name: "Custom Staircase Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Stonework",
    id: 38,
    subList: [
      {
        "id": 1,
        "name": "Stone Wall Construction",
        "keywords": [
          "Stone wall building",
          "Garden stone walls",
          "Dry stone wall construction",
          "Retaining stone walls",
          "Feature stone walls",
          "Stone boundary walls",
          "Sandstone wall construction",
          "Stone cladding for walls",
          "Heritage stone wall repairs",
          "Limestone wall installation"
        ]
      },
      {
        "id": 2,
        "name": "Stone Restoration & Repairs",
        "keywords": [
          "Stone restoration services",
          "Repointing stone walls",
          "Crack repair for stonework",
          "Stone surface cleaning",
          "Restoring heritage stonework",
          "Stone weatherproofing",
          "Stone facade restoration",
          "Repairing stone cladding",
          "Stone sealing and protection",
          "Marble and granite restoration"
        ]
      },
      {
        "id": 3,
        "name": "Stone Paving & Pathways",
        "keywords": [
          "Stone paving installation",
          "Bluestone pathway design",
          "Granite paving services",
          "Flagstone pathway setup",
          "Cobblestone driveway construction",
          "Outdoor stone steps",
          "Sandstone paving solutions",
          "Permeable stone pathways",
          "Custom stone walkways",
          "Slip-resistant stone paving"
        ]
      },
      {
        "id": 4,
        "name": "Decorative Stone Features",
        "keywords": [
          "Stone feature walls",
          "Stone fire pit construction",
          "Water feature stonework",
          "Outdoor stone seating",
          "Stone BBQ area construction",
          "Stone garden edging",
          "Rockery and boulder placement",
          "Natural stone landscaping",
          "Custom stone planter boxes",
          "Stone pool surrounds"
        ]
      },
      {
        "id": 5,
        "name": "Stone Cladding & Veneers",
        "keywords": [
          "Stone veneer installation",
          "Exterior stone cladding",
          "Faux stone wall panels",
          "Lightweight stone veneers",
          "Interior stone cladding",
          "Decorative stone panels",
          "Natural stone veneer",
          "Stone facade upgrades",
          "Custom stone cladding",
          "Sandstone cladding options"
        ]
      },
      {
        "id": 6,
        "name": "Fireplace & Chimney Stonework",
        "keywords": [
          "Stone fireplace construction",
          "Outdoor stone fireplaces",
          "Stone chimney repairs",
          "Custom hearth design",
          "Masonry fireplace builds",
          "Stone mantel installation",
          "Chimney stonework restoration",
          "Rebuilding stone chimneys",
          "Fireproof stone finishes",
          "Rustic stone fireplace designs"
        ]
      },
      {
        "id": 7,
        "name": "Kitchen & Bathroom Stonework",
        "keywords": [
          "Stone benchtop installation",
          "Marble kitchen countertops",
          "Granite vanity tops",
          "Stone splashback installation",
          "Custom kitchen islands",
          "Stone bathroom sinks",
          "Engineered stone surfaces",
          "Bathroom stone tiling",
          "Stone shower wall cladding",
          "Seamless stone joinery"
        ]
      },
      {
        "id": 8,
        "name": "Outdoor Living Stone Projects",
        "keywords": [
          "Stone patio construction",
          "Outdoor kitchen stonework",
          "Stone retaining wall seating",
          "Stone pizza oven builds",
          "Stone bar and countertop",
          "Stone pergola bases",
          "Courtyard stone designs",
          "Stone decking surfaces",
          "Outdoor entertainment area stonework",
          "Stone foundation work"
        ]
      },
      { id: 9, name: "Custom Stonemasonry Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Tiling",
    id: 39,
    subList: [
      {
        "id": 1,
        "name": "Floor Tiling",
        "keywords": [
          "Floor tile installation",
          "Porcelain floor tiling",
          "Ceramic tile flooring",
          "Timber-look floor tiles",
          "Slip-resistant floor tiles",
          "Tile floor leveling",
          "Marble floor tiles",
          "Large format tile installation",
          "Floor tile removal",
          "Floor grout and sealing"
        ]
      },
      {
        "id": 2,
        "name": "Wall Tiling",
        "keywords": [
          "Wall tile installation",
          "Subway tile installation",
          "Bathroom wall tiles",
          "Kitchen splashback tiles",
          "Mosaic wall tiling",
          "Feature wall tiling",
          "Vertical tiling patterns",
          "Glass wall tiles",
          "Decorative wall tile design",
          "Wall tile grout repair"
        ]
      },
      {
        "id": 3,
        "name": "Bathroom Tiling",
        "keywords": [
          "Bathroom tile installation",
          "Waterproof bathroom tiling",
          "Shower tile installation",
          "Floor-to-ceiling bathroom tiles",
          "Non-slip bathroom tiles",
          "Mosaic bathroom floor",
          "Wet area tiling",
          "Bathroom tile regrouting",
          "Heated floor tile installation",
          "Herringbone bathroom tiles"
        ]
      },
      {
        "id": 4,
        "name": "Kitchen Tiling",
        "keywords": [
          "Kitchen splashback installation",
          "Backsplash tile designs",
          "Heat-resistant kitchen tiles",
          "Island bench tile wrap",
          "Kitchen floor tiling",
          "Grout-resistant kitchen tiles",
          "Subway tile splashbacks",
          "Stone kitchen tiling",
          "Glossy kitchen wall tiles",
          "Stain-proof kitchen tiles"
        ]
      },
      {
        "id": 5,
        "name": "Grouting Services",
        "keywords": [
          "Tile grout installation",
          "Epoxy grout application",
          "Regrouting tiles",
          "Tile grout sealing",
          "Mold-resistant grout",
          "Grout color restoration",
          "Anti-stain grout sealing",
          "Grout repair and replacement",
          "Custom grout finishes"
        ]
      },
      {
        "id": 6,
        "name": "Outdoor Tiling",
        "keywords": [
          "Outdoor tile installation",
          "Poolside tiling",
          "Patio tile setup",
          "Non-slip outdoor tiles",
          "Balcony tile waterproofing",
          "Stone-look outdoor tiles",
          "Deck tiling solutions",
          "Exterior tile grout sealing",
          "Alfresco area tiling",
          "Weatherproof tile finishes"
        ]
      },
      {
        "id": 7,
        "name": "Commercial Tiling",
        "keywords": [
          "Commercial tile installation",
          "Retail space tiling",
          "Office floor tiles",
          "High-traffic area tiles",
          "Hotel bathroom tiling",
          "Restaurant wall tiling",
          "Commercial kitchen tiles",
          "Non-slip commercial flooring",
          "Durable tile finishes",
          "Custom commercial tile designs"
        ]
      },
      {
        "id": 8,
        "name": "Tile Repairs & Maintenance",
        "keywords": [
          "Tile crack repair",
          "Tile replacement services",
          "Regrouting tiles",
          "Tile surface cleaning",
          "Fixing loose tiles",
          "Tile grout sealing",
          "Tile scratch removal",
          "Tile polishing and buffing",
          "Waterproofing under tiles",
          "Mold and mildew treatment"
        ]
      },
      { id: 9, name: "Custom Tiling Services", keywords: [] },
    ],
  },
  {
    primaryCategory: "Waterproofing",
    id: 40,
    subList: [
      {
        "id": 1,
        "name": "Bathroom & Wet Area Waterproofing",
        "keywords": [
          "Bathroom waterproofing",
          "Wet area sealing",
          "Shower waterproofing",
          "Leak-proof bathroom solutions",
          "Waterproofing under tiles",
          "Bathroom membrane installation",
          "Ensuites waterproofing",
          "Wet room waterproofing",
          "Floor and wall junction sealing",
          "Mould prevention in wet areas"
        ]
      },
      {
        "id": 2,
        "name": "Balcony & Terrace Waterproofing",
        "keywords": [
          "Balcony waterproofing",
          "Waterproofing outdoor terraces",
          "Leak-proof balcony solutions",
          "Waterproof membrane for balconies",
          "Rooftop terrace waterproofing",
          "Concrete balcony sealing",
          "Outdoor area water protection",
          "UV-resistant waterproofing",
          "Waterproofing tiled balconies",
          "Deck and patio waterproofing"
        ]
      },
      {
        "id": 3,
        "name": "Roof Waterproofing",
        "keywords": [
          "Roof waterproofing solutions",
          "Flat roof waterproofing",
          "Waterproof membrane for roofs",
          "Leak detection and sealing",
          "Roof waterproof coating",
          "Roof flashing installation",
          "Waterproofing for metal roofs",
          "Roof drainage systems",
          "Waterproofing for concrete roofs",
          "Long-lasting roof protection"
        ]
      },
      {
        "id": 4,
        "name": "Basement & Below Ground Waterproofing",
        "keywords": [
          "Basement waterproofing",
          "Below ground waterproofing",
          "Foundation sealing",
          "Retaining wall waterproofing",
          "Concrete slab waterproofing",
          "Negative side waterproofing",
          "Basement leak prevention",
          "Underground water barriers",
          "Waterproofing basement walls",
          "Damp-proofing solutions"
        ]
      },
      {
        "id": 5,
        "name": "Tanking & Water Retention Systems",
        "keywords": [
          "Tanking waterproofing",
          "Water retention system installation",
          "Concrete tank waterproofing",
          "Waterproofing retaining tanks",
          "Tanking membranes",
          "Water ingress prevention",
          "Water tank leak repair",
          "Underground water tank sealing",
          "Internal tanking solutions",
          "Heavy-duty water retention systems"
        ]
      },
      {
        "id": 6,
        "name": "Crack Injection & Leak Sealing",
        "keywords": [
          "Crack injection waterproofing",
          "Concrete crack sealing",
          "Epoxy injection for leaks",
          "Polyurethane crack injection",
          "Leak detection and sealing",
          "Structural crack repair",
          "Waterproofing concrete cracks",
          "Flexible sealant application",
          "High-pressure leak injection",
          "Basement crack waterproofing"
        ]
      },
      {
        "id": 7,
        "name": "Commercial Waterproofing",
        "keywords": [
          "Commercial building waterproofing",
          "Industrial waterproof membranes",
          "Car park waterproofing",
          "Warehouse waterproofing",
          "High-rise waterproofing",
          "Waterproofing for office buildings",
          "Commercial roof sealing",
          "Waterproofing for retail spaces",
          "Large-scale waterproof projects",
          "Durable waterproof coatings"
        ]
      },
      { id: 8, name: "Custom Waterproofing Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Moving & Delivery",
    id: 41,
    subList: [
      {
        "id": 1,
        "name": "Residential Moving Services",
        "keywords": [
          "Local house moving",
          "Apartment relocation",
          "Full-service home movers",
          "Packing and unpacking services",
          "Furniture assembly and disassembly",
          "Fragile item handling",
          "Door-to-door moving service",
          "Pet-friendly moving solutions",
          "Residential moving specialists",
          "Eco-friendly moving options"
        ]
      },
      {
        "id": 2,
        "name": "Commercial Moving Services",
        "keywords": [
          "Office relocation",
          "Commercial moving solutions",
          "Business furniture moving",
          "IT equipment relocation",
          "Corporate office movers",
          "Warehouse moving services",
          "Retail space relocation",
          "Business asset packing",
          "After-hours office moves",
          "Small business moving services"
        ]
      },
      {
        "id": 3,
        "name": "Packing & Unpacking Services",
        "keywords": [
          "Professional packing services",
          "Unpacking and organizing",
          "Custom packing solutions",
          "Fragile item packing",
          "Kitchenware packing",
          "Box and material supply",
          "Room-by-room packing",
          "Wardrobe box rentals",
          "Packing for long-distance moves",
          "Post-move setup services"
        ]
      },
      {
        "id": 4,
        "name": "Furniture & Specialty Item Moving",
        "keywords": [
          "Furniture moving services",
          "Piano moving experts",
          "Antique furniture transport",
          "Art and sculpture moving",
          "Pool table relocation",
          "Heavy appliance moving",
          "Custom crating for valuables",
          "Outdoor furniture moving",
          "Safe and vault moving",
          "Specialty item movers"
        ]
      },
      {
        "id": 5,
        "name": "Interstate & Long-Distance Moving",
        "keywords": [
          "Interstate moving services",
          "Cross-country relocation",
          "Long-distance packing and moving",
          "Vehicle transport for moves",
          "Interstate furniture moving",
          "Coordinated interstate logistics",
          "Stress-free long-distance moves",
          "Pre-move consultation",
          "Secure long-distance storage",
          "Nationwide moving network"
        ]
      },
      {
        "id": 6,
        "name": "Removalist Services",
        "keywords": [
          "Professional removalists",
          "Affordable moving options",
          "Quick removalist services",
          "Heavy lifting and moving",
          "Rubbish removal during moves",
          "Last-minute moving help",
          "Weekend removalist services",
          "Experienced moving crews",
          "Insurance for removals",
          "Moving day coordination"
        ]
      },
      {
        "id": 7,
        "name": "Delivery Services",
        "keywords": [
          "Furniture delivery",
          "Appliance delivery",
          "Door-to-door delivery service",
          "Pet-friendly delivery options",
          "After-hours delivery services",
          "Large item delivery",
          "Office supply delivery",
          "Retail item delivery",
          "Quick delivery options",
          "Nationwide delivery network"
        ]
      },
      { id: 8, name: "Custom Moving Projects", keywords: [] },
    ],
  },
  {
    primaryCategory: "Mould & Asbestos",
    id: 42,
    subList: [
      {
        "id": 1,
        "name": "Mould Inspection & Testing",
        "keywords": [
          "Mould inspection services",
          "Indoor air quality testing",
          "Mould sample analysis",
          "Comprehensive mould testing",
          "Moisture level assessment",
          "Thermal imaging for mould",
          "Pre-purchase mould inspection",
          "Building mould assessment",
          "Lab-certified mould testing",
          "Residential mould surveys"
        ]
      },
      {
        "id": 2,
        "name": "Mould Removal & Remediation",
        "keywords": [
          "Mould removal services",
          "Mould remediation specialists",
          "Black mould treatment",
          "Safe mould removal",
          "Eco-friendly mould remediation",
          "Anti-fungal treatments",
          "Mould prevention solutions",
          "Surface mould cleaning",
          "Mould-damaged material disposal",
          "Complete mould remediation"
        ]
      },
      {
        "id": 3,
        "name": "Asbestos Inspection & Testing",
        "keywords": [
          "Asbestos inspection services",
          "Asbestos sample testing",
          "Pre-renovation asbestos checks",
          "Asbestos air monitoring",
          "Lab-certified asbestos analysis",
          "Asbestos risk assessment",
          "Building material testing",
          "Comprehensive asbestos survey",
          "Asbestos detection specialists",
          "Workplace asbestos testing"
        ]
      },
      {
        "id": 4,
        "name": "Asbestos Removal & Disposal",
        "keywords": [
          "Licensed asbestos removal",
          "Asbestos abatement services",
          "Safe asbestos disposal",
          "Friable asbestos removal",
          "Non-friable asbestos management",
          "Asbestos encapsulation",
          "Residential asbestos removal",
          "Commercial asbestos services",
          "Asbestos-contaminated soil removal",
          "Asbestos waste transport"
        ]
      },
      {
        "id": 5,
        "name": "Preventative Solutions & Decontamination",
        "keywords": [
          "Mould prevention strategies",
          "Asbestos encapsulation methods",
          "Dehumidification services",
          "Air purification solutions",
          "Post-removal decontamination",
          "Moisture control installation",
          "Ventilation system upgrades",
          "HEPA air filtration systems",
          "Water damage prevention",
          "Building decontamination services"
        ]
      },
      {
        "id": 6,
        "name": "Emergency Response Services",
        "keywords": [
          "Emergency mould cleanup",
          "Urgent asbestos removal",
          "24/7 decontamination response",
          "Flood damage mould prevention",
          "Crisis asbestos containment",
          "Rapid mould mitigation",
          "Immediate moisture extraction",
          "Quick asbestos abatement",
          "Disaster recovery mould services",
          "Emergency building safety checks"
        ]
      },
      { id: 7, name: "Custom Mould & Asbestos Projects", keywords: [] },
    ],
  },
];
