// Send OTP
export const SENDOTP_MUTATION = `
    mutation SendOtp($email: String!) {
        sendOtp(email: $email)
    }
`;

export const SendOtpMutation = async (graphqlService, variables) => {
    try {
        const response = await graphqlService.mutateData(SENDOTP_MUTATION, variables);
        return response;
    } catch (error) {
        console.error("Mutation Error:", error);
        throw error;
    }
};

// Verify OTP
export const VERIFYOTP_MUTATION = `
    mutation VerifyOtp($email: String!, $otp: String!) {
        verifyOtp(email: $email, otp: $otp)
        {
        message
        access_token
        id_token
        }
    }
`;

export const VerifyOtpMutation = async (graphqlService, variables) => {
    try {
        const response = await graphqlService.mutateData(VERIFYOTP_MUTATION, variables);
        return response;
    } catch (error) {
        console.error("Mutation Error:", error);
        throw error;
    }
};

// Register User
export const REGISTERUSER_MUTATION = `
    mutation RegisterUser($userInput: UserInput!) {
        registerUser(userInput: $userInput) {
            id
        }
    }
`;

export const RegisterUserMutation = async (graphqlService, variables) => {
    try {
        const response = await graphqlService.mutateData(REGISTERUSER_MUTATION, variables);
        return response;
    } catch (error) {
        console.error("Mutation Error:", error);
        throw error;
    }
};

// Update User
export const UpdateUserMutation = async (graphqlService, variables) => {
    try {
        const UPDATEUSERPROFILE_MUTATION = `
            mutation updateUserProfile($userInput: UserInput!) {
                updateUserProfile(userInput: $userInput) {
                    id
                    firstName
                    lastName
                    personalEmail
                    roleType
                    location
                    profilePicture
                    abn
                    businessName
                    companyName
                    serviceCategoryId
                }
            }
        `;
        const response = await graphqlService.mutateData(UPDATEUSERPROFILE_MUTATION, variables);
        return response;
    } catch (error) {
        console.error("Mutation Error:", error);
        throw error;
    }
};