import { combineReducers } from "@reduxjs/toolkit";
import BusinessCategoryReducer from "./Reducers/BusinessCategories";
import PricingPlanReducer from "./Reducers/PricingPlans";
import ServiceTagsReducer from "./Reducers/ServiceTags";
import TestmonialReducer from "./Reducers/Testmonial";
import SignUpReducer from "./Slice/SignUp";
import fileSlice from "./Slice/FileUpload";
import userReducer from "./Slice/userSlice";
import serviceCategoriesReducer from "./Slice/serviceCategoriesSlice";
import createPostReducer from "./Slice/createPostSlice";

export default combineReducers({
  PricingPlanReducer,
  TestmonialReducer,
  BusinessCategoryReducer,
  ServiceTagsReducer,
  signup: SignUpReducer,
  files: fileSlice,
  user: userReducer,
  serviceCategories: serviceCategoriesReducer,
  createPost: createPostReducer,
});
