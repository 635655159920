import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { useDispatch, useSelector } from "react-redux";
import { setCroppedFile, setCropping } from "../../../Redux/Slice/FileUpload";
import MainButton from "../../common/MainButton/MainButton";

const ImageCropper = ({ onComplete }) => {
    const dispatch = useDispatch();
    const { originalFile, cropping } = useSelector((state) => state.files);
    const cropperRef = useRef(null);
    const [zoom, setZoom] = useState(0);
  
    const handleCrop = () => {
      if (cropperRef.current && cropperRef.current.cropper) {
        dispatch(setCropping(true));
        const cropper = cropperRef.current.cropper;
  
        cropper.getCroppedCanvas().toBlob((blob) => {
          const objectUrl = URL.createObjectURL(blob);
          dispatch(setCroppedFile(objectUrl));
          dispatch(setCropping(false));
          setZoom(0);
          onComplete(objectUrl);
        }, "image/jpeg");
      }
    };
  
    const handleZoomChange = (event) => {
      const newZoom = event.detail.ratio;
      const boundedZoom = Math.max(0, Math.min(3, newZoom));
      setZoom(boundedZoom);

      // Center the image after zoom
      if (cropperRef.current?.cropper) {
        const cropper = cropperRef.current.cropper;
        const containerData = cropper.getContainerData();
        const canvasData = cropper.getCanvasData();
        const centerX = (containerData.width - canvasData.width) / 2;
        const centerY = (containerData.height - canvasData.height) / 2;
        cropper.setCanvasData({
          left: centerX,
          top: centerY
        });
      }
    };
  
    const handleSliderChange = (event) => {
      const zoomLevel = parseFloat(event.target.value);
      setZoom(zoomLevel);
      if (cropperRef.current?.cropper) {
        const cropper = cropperRef.current.cropper;
        cropper.zoomTo(zoomLevel);
        
        // Center the image after zoom
        const containerData = cropper.getContainerData();
        const canvasData = cropper.getCanvasData();
        const centerX = (containerData.width - canvasData.width) / 2;
        const centerY = (containerData.height - canvasData.height) / 2;
        cropper.setCanvasData({
          left: centerX,
          top: centerY
        });
      }
    };
  
    return (
      <div>
        <Cropper
          ref={cropperRef}
          src={originalFile}
          style={{ height: "100%", width: "100%" }}
          dragMode={"move"}
          aspectRatio={1}
          guides={false}
          viewMode={1}
          cropBoxMovable={false}
          cropBoxResizable={false}
          background={false}
          autoCropArea={1}
          initialAspectRatio={1}
          responsive={true}
          zoomTo={zoom}
          wheelZoomRatio={0.1}
          zoom={handleZoomChange}
          minZoom={0}
          center={true}
        />
        <div className="flex items-center gap-4 mt-6">
          <label className="min-w-16 text-sm font-medium text-gray-700">Zoom:</label>
          <div className="relative flex-1">
            <input
              type="range"
              min="0"
              max="3"
              step="0.1"
              value={zoom}
              onChange={handleSliderChange}
              className="w-full h-1.5 bg-gray-200 rounded-lg appearance-none cursor-pointer
                accent-primaryblue
                focus:outline-none focus:ring-2 focus:ring-primaryblue/20
                [&::-webkit-slider-thumb]:appearance-none
                [&::-webkit-slider-thumb]:h-4
                [&::-webkit-slider-thumb]:w-4
                [&::-webkit-slider-thumb]:rounded-full
                [&::-webkit-slider-thumb]:bg-primaryblue
                [&::-webkit-slider-thumb]:shadow-sm
                [&::-webkit-slider-thumb]:transition-all
                [&::-webkit-slider-thumb]:hover:scale-110
                [&::-moz-range-thumb]:h-4
                [&::-moz-range-thumb]:w-4
                [&::-moz-range-thumb]:rounded-full
                [&::-moz-range-thumb]:border-0
                [&::-moz-range-thumb]:bg-primaryblue
                [&::-moz-range-thumb]:shadow-sm
                [&::-moz-range-thumb]:transition-all
                [&::-moz-range-thumb]:hover:scale-110"
            />
            <span className="absolute right-0 -top-6 text-xs text-gray-500">
              {Math.round(zoom * 100)}%
            </span>
          </div>
        </div>
        <div className="flex justify-end mt-4">
          <MainButton
            variant="auto"
            onClick={handleCrop}
            disabled={cropping}
            className="mt-2"
          >
            {cropping ? "Cropping..." : "Crop & Save"}
          </MainButton>
        </div>
      </div>
    );
};

export default ImageCropper;
