import React, { useState } from "react";
import { useFormik } from "formik";
import TextFiled from "../../../components/common/InputField/TextFiled";
import MainButton from "../../../components/common/MainButton/MainButton";
import Heading from "../../../components/common/Typography/Heading";
import Paragraph from "../../../components/common/Typography/Paragraph";
import AuxiliaryText from "../../../components/common/Typography/AuxiliaryText";
import Spinner from "../../../components/common/Spinner";
import InteractiveText from "../../../components/common/Typography/InteractiveText";
import SpecialText from "../../../components/common/Typography/SpecialText";
import { validationSchema } from "../../../FormValidations/ForgotPasswordSchema";
import { ForgotPasswordMutation } from "../../../services/ForgotPassowrd";
import { useGraphQL } from "../../../context/GraphQLProvider";
import Alerts from "../../../components/common/Alerts/Alerts";

const ForgotPassword = ({ onClickHandler }) => {
    const graphqlService = useGraphQL();
    const [RequestStart, setRequetStart] = useState(false);
    const [step, setStep] = useState("A");

  const ForgotPasswordservice = (gql, email) => {
      ForgotPasswordMutation(gql, { 
          email,
      })
      .then((response) => {
          console.log(response);
          if (response.forgotPassword?.success){
              setStep('B');
              // onClickHandler();  
          }
      })
      .catch((error) => {
          Alerts.error(error);
      })
      .finally(() => {
        setRequetStart(false);
      });
  };

  const formik = useFormik({
      initialValues: {email: ""},
      validationSchema: validationSchema,
      onSubmit: (values, { resetForm }) => {
          const email = values.email;
          setRequetStart(true);
          ForgotPasswordservice(graphqlService, email);
      },
  });
  
  return (
    <>
    {step === "A" && (
        <>
        <div className="my-auto mx-auto w-[320px] sm:w-[380px] md:w-[400px]">
        <Heading
            variant={"h5"}
            className={
            "flex items-center justify-center mt-[60px] md:mt-[120px] text-offblack"
            }
        >
            Password Recovery
        </Heading>
        <div className="w-fit mx-auto pt-10 xl:pt-[60px]">
            {RequestStart && <Spinner />}
            <form>
            <div>
                <TextFiled
                label={"Email"}
                variant={"medium"}
                type="email"
                name="email"
                placeholder={"Enter your registered email"}
                onChange={formik?.handleChange}
                // value={formik?.values?.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <SpecialText
                  variant={"FootNoteDisclaimer"}
                  className={"mt-1"}
                >
                  {formik.errors.email}
                </SpecialText>
                ) : null}
            </div>

            {/* <div className="my-5">
                <TextFiled
                label={"Create New Password"}
                variant={"general"}
                type="email"
                name="email"
                placeholder={"New Password"}
                // onChange={formik?.handleChange}
                // value={formik?.values?.email}
                /> */}
                {/* {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500">
                    {formik.errors.email}
                </div>
                ) : null} */}
            {/* </div> */}

            {/* <div>
                <TextFiled
                label={"Confirm Password"}
                variant={"large"}
                type="email"
                name="email"
                placeholder={"Confirm New Password"}
                // onChange={formik?.handleChange}
                // value={formik?.values?.email}
                /> */}
                {/* {formik.touched.email && formik.errors.email ? (
                <div className="text-red-500">
                    {formik.errors.email}
                </div>
                ) : null} */}
            {/* </div> */}

            <div className="flex justify-end mt-10">
                <MainButton
                type="button"
                variant={"extrasmall"}
                onClick={formik.handleSubmit}
                >
                {/* Update */}
                Submit
                </MainButton>
            </div>
            </form>
        </div>
        </div>
        </>
      )}

        {step === "B" && (
        <>
          <div className="h-[550px] overflow-auto hidden-scrollbar lg:h-[800px] lg:overflow-hidden pb-[550px] lg:pb-0 relative">
            <div className="flex items-center justify-center w-[300px] sm:w-[350px] md:w-[380px] lg:w-[400px] mt-[60px] xl:mt-[120px] mx-auto ">
              {/* <i className="fi fi-sr-check text-[30px] text-primaryblue mr-5"></i> */}
              <Heading variant={"h6"} className={"text-center text-offblack"}>
                Password Reset Email Sent
              </Heading>
            </div>
            <div className="w-[300px] sm:w-[350px] md:w-[380px] lg:w-[400px] mx-auto mt-10 xl:mt-[60px]">
              <Paragraph
                variant={"MainParagraph"}
                className={"text-offblack text-start"}
              >
                We've sent you an email with instructions to reset your password.
                Follow the link in the email to create a new password.
              </Paragraph>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ForgotPassword;
