import { createSlice } from "@reduxjs/toolkit";

const fileSlice = createSlice({
  name: "files",
  initialState: {
    originalFile: null,
    croppedFile: null,
    cropping: false,
    error: null,
  },
  reducers: {
    setFile: (state, action) => {
      state.originalFile = action.payload;
      state.error = null;
    },
    setCroppedFile: (state, action) => {
      state.croppedFile = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    clearFile: (state) => {
      state.originalFile = null;
      state.croppedFile = null;
      state.cropping = false;
      state.error = null;
    },
    setCropping: (state, action) => {
      state.cropping = action.payload;
    },
  },
});

export const { setFile, setCroppedFile, setError, clearFile, setCropping } = fileSlice.actions;
export default fileSlice.reducer;