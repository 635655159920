/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
// import { motion } from "framer-motion";
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import useAuth from "../../Hooks/useAuth";
import Login from "../../sections/Login/Login";
import { SignUp } from "../../sections/SignUp/SignUp";
import MainButton from "../common/MainButton/MainButton";
import CustomerSidebarDrawer from "./CustomerSidebarDrawer";
import Avtar from "../../assets/img/default-image.jpg";
import useClickOutside from "../../Hooks/useClickOutside";
import CustomerNavbar from "./CustomerNavbar";
import AuxiliaryText from "../common/Typography/AuxiliaryText";
import SearchField from "../common/SearchInput/SearchField";
import InteractiveText from "../common/Typography/InteractiveText";
import SpecialText from "../common/Typography/SpecialText";
import { useSelector } from 'react-redux';
import AuthButtons from '../common/AuthButtons/AuthButtons';

const CustomerHeader = ({ isCustomerDashboard }) => {
  const [settings, setSettings] = useState(false);
  const [notificationsAlerts, setNotificationsAlerts] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const [inboxOpen, setInboxOpen] = useState(false);
  const [scrolledVersion, setScrolledVersion] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isSidebarOpen, setIsSideBarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { profilePicture } = useSelector((state) => state.user);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [isOpen]);

  useEffect(() => {
    const responsive = () => {
      setIsMobile(window.matchMedia("(max-width: 1023px)").matches);
    };
    const event = () => {
      setScrolledVersion(() => {
        if (window.scrollY <= 0) {
          return false;
        }
        return true;
      });
    };

    responsive();
    document.addEventListener("scroll", event);
    window.addEventListener("resize", responsive);
    return () => {
      document.removeEventListener("scroll", event);
      window.removeEventListener("resize", responsive);
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    };
  }, []);

  const location = useLocation();

  const { auth, setAuth } = useAuth();
  const [SignUpState, setSignUpState] = useState(false);
  const [SignIn, setSignIn] = useState(false);

  const [IsDropDownOpen, setDropDown] = useState(false);
  const { id: userId } = auth?.user ?? "";
  const ref = useRef(null);
  useClickOutside(ref, () => {
    setSettings(false);
    setDropDown(false);
    setHelpOpen(false);
    setInboxOpen(false);
    setNotificationsAlerts(false);
  });
  return (
    <>
      <Login
        SignIn={SignIn}
        setSignIn={setSignIn}
        SignUpState={SignUpState}
        setSignUpState={setSignUpState}
      />
      <SignUp
        SignUpState={SignUpState}
        setSignUpState={setSignUpState}
        SignIn={SignIn}
        setSignIn={setSignIn}
      />
      {/* scrolledVersion={scrolledVersion || isOpen} */}
      <HeaderContainer>
        <div
          className={`
          ${
            isOpen
              ? "bg-pastalblue "
              : location.pathname === "/" ||
                location.pathname === "/about-us" ||
                location.pathname === "/business-support-centre" ||
                location.pathname === "/customer-support-centre" ||
                location.pathname === "/cost-estimation" ||
                location.pathname.includes("/services-guide/") ||
                location.pathname === "/pricing" ||
                location.pathname.includes("/councils-regulations") 
              ? "bg-transparent text-white"
              : "bg-pastalblue text-offblack"
          } 
          `}
        >
          <div
            className={
              isCustomerDashboard
                ? "flex items-center justify-between mx-[5%] lg:mx-auto lg:w-[950px] xl:w-[1150px] 2xl:w-[1400px] h-[60px] mt-5"
                : location.pathname.includes("/HelpCentre")
                ? "flex items-center justify-between mx-[5%] xl:mx-auto xl:w-[800px] 2xl:w-[800px] h-[60px] sm:h-[80px]"
                : "flex items-center justify-between mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] h-[60px] sm:h-[80px]"
            }
          >
            <div onClick={() => setIsOpen(false)}>
              <Link to="/">
                <img
                  className="w-[60px] h-[25px] sm:w-[65px] sm:h-[28px] lg:w-[70px] lg:h-[30px] cursor-pointer"
                  src={require("../../assets/img/trades-poster.png")}
                  alt="trades-poster"
                  onClick={() => window.scrollTo(0, 0)}
                />
              </Link>
            </div>
            <div>
              {!location.pathname.includes("/HelpCentre") && (
                <>
                  <div
                    className={"hidden lg:flex items-center"
                    }
                  >
                    {!location.pathname.includes("customer-dashboard") && (
                      <CustomerNavbar
                        scrolledVersion={scrolledVersion}
                        SignInState={SignIn}
                        setSignIn={setSignIn}
                      />
                    )}
                    <div className="flex items-center">
                      {!auth?.user ? (
                        <div className="flex items-center">
                          <AuthButtons
                            variant="desktop"
                            onSignIn={() => setSignIn(!SignIn)}
                            onSignUp={() => setSignUpState(!SignUpState)}
                            onRequestQuote={() => {}}
                          />
                        </div>
                      ) : (
                        <>
                          {location.pathname.includes("customer-dashboard") ? (
                            <>
                              <div className="flex items-center gap-5">
                                <div>
                                  <SearchField placeholder={"Search"} />
                                </div>
                                <ul className="flex items-center gap-5 ml-20">
                                  <li
                                    className="cursor-pointer hover:bg-transparent hidden lg:block relative"
                                    ref={ref}
                                  >
                                    <i
                                      className="fi fi-rs-bell text-base"
                                      onClick={() =>
                                        setNotificationsAlerts(
                                          !notificationsAlerts
                                        )
                                      }
                                    ></i>
                                    {notificationsAlerts && (
                                      <div className="absolute z-10 w-[350px] origin-top-right rounded-xl bg-white text-offblack pt-5 pb-10 mt-3 -right-14 shadow-2xl">
                                        <div
                                          class="w-0 h-0 
  border-l-[10px] border-l-transparent
  border-b-[15px] border-b-white
  border-r-[10px] border-r-transparent absolute left-[275px] -mt-8"
                                        ></div>
                                        {/* Heading */}
                                        <div className="flex items-center justify-between px-5 mb-5">
                                          <AuxiliaryText variant={"FieldLabel"}>
                                            Notifications
                                          </AuxiliaryText>
                                          <Link
                                            to={
                                              "/customer-dashboard/inbox/notifications"
                                            }
                                          >
                                            <InteractiveText
                                              variant={"ActionLink"}
                                              className={"text-primaryblue"}
                                            >
                                              View all
                                            </InteractiveText>
                                          </Link>
                                        </div>

                                        {/* Notifications list */}
                                        <div className="border-t border-t-lightgray mx-5">
                                          <div className="border-b border-b-lightgray py-3 relative">
                                            <div className="flex items-center justify-between">
                                              <div className="flex items-center gap-x-2">
                                                <img
                                                  src={profilePicture || Avtar}
                                                  className="w-6 h-6 rounded-full object-cover"
                                                  alt="Profile"
                                                />
                                                <AuxiliaryText
                                                  variant={"MenuDropdown"}
                                                >
                                                  Trades poster
                                                </AuxiliaryText>
                                              </div>
                                              <div className="flex items-center gap-2">
                                                <i className="fi fi-ss-bullet text-base text-primaryblue pt-1"></i>
                                                <SpecialText
                                                  variant={"Date"}
                                                  className={"text-mediumgray"}
                                                >
                                                  1 minute ago
                                                </SpecialText>
                                              </div>
                                            </div>
                                            <AuxiliaryText
                                              variant={"SubMenuDropdown"}
                                              className={
                                                "h-[20px] overflow-hidden text-ellipsis mt-2"
                                              }
                                            >
                                              Ensure seamless transactions and
                                              keep your business running
                                              smoothly with our straightforward
                                              billing section. Add/ remove
                                              payment methods, track and
                                              maintain record of all your
                                              pricing plans transactions with
                                              Trades Poster
                                            </AuxiliaryText>
                                          </div>
                                          <div className="border-b border-b-lightgray py-3 relative">
                                            <div className="flex items-center justify-between">
                                              <div className="flex items-center gap-x-2">
                                                <img
                                                  src={profilePicture || Avtar}
                                                  className="w-6 h-6 rounded-full object-cover"
                                                  alt="Profile"
                                                />
                                                <AuxiliaryText
                                                  variant={"MenuDropdown"}
                                                >
                                                  Trades poster
                                                </AuxiliaryText>
                                              </div>
                                              <div className="flex items-center gap-2">
                                                <i className="fi fi-ss-bullet text-base text-primaryblue pt-1"></i>
                                                <SpecialText
                                                  variant={"Date"}
                                                  className={"text-mediumgray"}
                                                >
                                                  1 minute ago
                                                </SpecialText>
                                              </div>
                                            </div>
                                            <AuxiliaryText
                                              variant={"SubMenuDropdown"}
                                              className={
                                                "h-[20px] overflow-hidden text-ellipsis mt-2"
                                              }
                                            >
                                              Ensure seamless transactions and
                                              keep your business running
                                              smoothly with our straightforward
                                              billing section. Add/ remove
                                              payment methods, track and
                                              maintain record of all your
                                              pricing plans transactions with
                                              Trades Poster
                                            </AuxiliaryText>
                                          </div>
                                          <div className="border-b border-b-lightgray py-3 relative">
                                            <div className="flex items-center justify-between">
                                              <div className="flex items-center gap-x-2">
                                                <img
                                                  src={profilePicture || Avtar}
                                                  className="w-6 h-6 rounded-full object-cover"
                                                  alt="Profile"
                                                />
                                                <AuxiliaryText
                                                  variant={"MenuDropdown"}
                                                >
                                                  Trades poster
                                                </AuxiliaryText>
                                              </div>
                                              <div className="flex items-center gap-2">
                                                <i className="fi fi-ss-bullet text-base text-primaryblue pt-1"></i>
                                                <SpecialText
                                                  variant={"Date"}
                                                  className={"text-mediumgray"}
                                                >
                                                  1 minute ago
                                                </SpecialText>
                                              </div>
                                            </div>
                                            <AuxiliaryText
                                              variant={"SubMenuDropdown"}
                                              className={
                                                "h-[20px] overflow-hidden text-ellipsis mt-2"
                                              }
                                            >
                                              Ensure seamless transactions and
                                              keep your business running
                                              smoothly with our straightforward
                                              billing section. Add/ remove
                                              payment methods, track and
                                              maintain record of all your
                                              pricing plans transactions with
                                              Trades Poster
                                            </AuxiliaryText>
                                          </div>
                                          <div className="border-b border-b-lightgray py-3 relative">
                                            <div className="flex items-center justify-between">
                                              <div className="flex items-center gap-x-2">
                                                <img
                                                  src={profilePicture || Avtar}
                                                  className="w-6 h-6 rounded-full object-cover"
                                                  alt="Profile"
                                                />
                                                <AuxiliaryText
                                                  variant={"MenuDropdown"}
                                                >
                                                  Trades poster
                                                </AuxiliaryText>
                                              </div>
                                              <div className="flex items-center gap-2">
                                                <i className="fi fi-ss-bullet text-base text-primaryblue pt-1"></i>
                                                <SpecialText
                                                  variant={"Date"}
                                                  className={"text-mediumgray"}
                                                >
                                                  1 minute ago
                                                </SpecialText>
                                              </div>
                                            </div>
                                            <AuxiliaryText
                                              variant={"SubMenuDropdown"}
                                              className={
                                                "h-[20px] overflow-hidden text-ellipsis mt-2"
                                              }
                                            >
                                              Ensure seamless transactions and
                                              keep your business running
                                              smoothly with our straightforward
                                              billing section. Add/ remove
                                              payment methods, track and
                                              maintain record of all your
                                              pricing plans transactions with
                                              Trades Poster
                                            </AuxiliaryText>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </li>
                                  <li className="cursor-pointer hover:bg-transparent hidden lg:block">
                                    <Link
                                      to={"/customer-dashboard/inbox/chats"}
                                    >
                                      <i className="fi fi-rs-comment-alt-dots size-4 "></i>
                                    </Link>
                                  </li>
                                </ul>
                                <img
                                  className="size-10 sm:size-12 rounded-full object-cover"
                                  src={profilePicture || Avtar}
                                  alt="Profile"
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div ref={ref} className="relative ml-5">
                                <div>
                                  <button
                                    onClick={() => setDropDown(!IsDropDownOpen)}
                                    type="button"
                                    className="flex max-w-xs items-center rounded-full"
                                    id="user-menu-button"
                                    aria-expanded="false"
                                    aria-haspopup="true"
                                  >
                                    <span className="sr-only">
                                      Open user menu
                                    </span>
                                    <img
                                      className="size-10 sm:size-12 rounded-full object-cover"
                                      src={profilePicture || Avtar}
                                      alt="Profile"
                                    />
                                  </button>
                                </div>
                                {IsDropDownOpen && (
                                  <div
                                    className="absolute right-0 z-10 mt-2 w-60 origin-top-right rounded-xl bg-white text-offblack px-6 py-5 "
                                    role="menu"
                                    aria-orientation="vertical"
                                    aria-labelledby="user-menu-button"
                                    tabIndex={-1}
                                  >
                                    <Link
                                      to={`/customer-dashboard/dashboard?userId=${userId}`}
                                      rel=""
                                      className="block py-2 hover:text-primaryblue ease-in-out w-full"
                                      role="menuitem"
                                      tabIndex={-1}
                                      id="user-menu-item-0"
                                      onClick={() =>
                                        setDropDown(!IsDropDownOpen)
                                      }
                                    >
                                      <AuxiliaryText variant={"MenuDropdown"}>
                                        Customer dashboard
                                      </AuxiliaryText>
                                    </Link>
                                    <Link
                                      to={`/customer-dashboard/account?userId=${userId}`}
                                      rel=""
                                      className="block py-2 hover:text-primaryblue ease-in-out w-full"
                                      role="menuitem"
                                      tabIndex={-1}
                                      id="user-menu-item-0"
                                      onClick={() =>
                                        setDropDown(!IsDropDownOpen)
                                      }
                                    >
                                      <AuxiliaryText variant={"MenuDropdown"}>
                                        Account
                                      </AuxiliaryText>
                                    </Link>
                                    <Link
                                      to={`/customer-dashboard/posts?userId=${userId}`}
                                      rel=""
                                      className="block py-2 hover:text-primaryblue ease-in-out w-full"
                                      role="menuitem"
                                      tabIndex={-1}
                                      id="user-menu-item-0"
                                      onClick={() =>
                                        setDropDown(!IsDropDownOpen)
                                      }
                                    >
                                      <AuxiliaryText variant={"MenuDropdown"}>
                                        Posts
                                      </AuxiliaryText>
                                    </Link>

                                    <button
                                      onClick={() => {
                                        setSettings(!settings);
                                        setInboxOpen(false);
                                        setHelpOpen(false);
                                      }}
                                      className="flex justify-between py-2 hover:text-primaryblue ease-in-out w-full my-1"
                                    >
                                      <AuxiliaryText variant={"MenuDropdown"}>
                                        Settings
                                      </AuxiliaryText>
                                      <i className="fi fi-rs-angle-down text-sm text-offblack"></i>
                                    </button>
                                    {settings && (
                                      <>
                                        <ul className="text-start px-4">
                                          <li>
                                            <Link
                                              to={`/customer-dashboard/settings/mobile?userId=${userId}`}
                                              onClick={() => {
                                                setSettings(!settings);
                                                setDropDown(!IsDropDownOpen);
                                              }}
                                              className="block py-2 hover:text-primaryblue ease-in-out w-full my-1"
                                            >
                                              <AuxiliaryText
                                                variant={"SubMenuDropdown"}
                                              >
                                                Mobile
                                              </AuxiliaryText>
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to={`/customer-dashboard/settings/email?userId=${userId}`}
                                              onClick={() => {
                                                setSettings(!settings);
                                                setDropDown(!IsDropDownOpen);
                                              }}
                                              className="block py-2 hover:text-primaryblue ease-in-out w-full my-1"
                                            >
                                              <AuxiliaryText
                                                variant={"SubMenuDropdown"}
                                              >
                                                Email
                                              </AuxiliaryText>
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to={`/customer-dashboard/settings/notification-preferences?userId=${userId}`}
                                              onClick={() => {
                                                setSettings(!settings);
                                                setDropDown(!IsDropDownOpen);
                                              }}
                                              className="block py-2 hover:text-primaryblue ease-in-out w-full my-1"
                                            >
                                              <AuxiliaryText
                                                variant={"SubMenuDropdown"}
                                              >
                                                Notifications
                                              </AuxiliaryText>
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to={`/customer-dashboard/settings/reset-password?userId=${userId}`}
                                              onClick={() => {
                                                setSettings(!settings);
                                                setDropDown(!IsDropDownOpen);
                                              }}
                                              className="block py-2 hover:text-primaryblue ease-in-out w-full my-1"
                                            >
                                              <AuxiliaryText
                                                variant={"SubMenuDropdown"}
                                              >
                                                Reset Password
                                              </AuxiliaryText>
                                            </Link>
                                          </li>
                                        </ul>
                                      </>
                                    )}
                                    <button
                                      onClick={() => {
                                        setInboxOpen(!inboxOpen);
                                        setSettings(false);
                                        setHelpOpen(false);
                                      }}
                                      className="flex justify-between py-2 hover:text-primaryblue ease-in-out w-full my-1"
                                    >
                                      <AuxiliaryText variant={"MenuDropdown"}>
                                        Inbox
                                      </AuxiliaryText>
                                      <i className="fi fi-rs-angle-down text-sm text-offblack"></i>
                                    </button>
                                    {inboxOpen && (
                                      <>
                                        <ul className="text-start px-4">
                                          <li>
                                            <Link
                                              to={`/customer-dashboard/inbox/chats?userId=${userId}`}
                                              onClick={() => {
                                                setInboxOpen(!inboxOpen);
                                                setDropDown(!IsDropDownOpen);
                                              }}
                                              className="block py-2 hover:text-primaryblue ease-in-out w-full my-1"
                                            >
                                              <AuxiliaryText
                                                variant={"SubMenuDropdown"}
                                              >
                                                Messages
                                              </AuxiliaryText>
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to={`/customer-dashboard/inbox/notifications?userId=${userId}`}
                                              onClick={() => {
                                                setInboxOpen(!inboxOpen);
                                                setDropDown(!IsDropDownOpen);
                                              }}
                                              className="block py-2 hover:text-primaryblue ease-in-out w-full my-1"
                                            >
                                              <AuxiliaryText
                                                variant={"SubMenuDropdown"}
                                              >
                                                Notifications
                                              </AuxiliaryText>
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to={`/customer-dashboard/inbox/reviews?userId=${userId}`}
                                              onClick={() => {
                                                setInboxOpen(!inboxOpen);
                                                setDropDown(!IsDropDownOpen);
                                              }}
                                              className="block py-2 hover:text-primaryblue ease-in-out w-full my-1"
                                            >
                                              <AuxiliaryText
                                                variant={"SubMenuDropdown"}
                                              >
                                                Reviews
                                              </AuxiliaryText>
                                            </Link>
                                          </li>
                                        </ul>
                                      </>
                                    )}
                                    <button
                                      onClick={() => {
                                        setHelpOpen(!helpOpen);
                                        setSettings(false);
                                        setInboxOpen(false);
                                      }}
                                      className="flex justify-between py-2 hover:text-primaryblue ease-in-out w-full my-1"
                                    >
                                      <AuxiliaryText variant={"MenuDropdown"}>
                                        Support
                                      </AuxiliaryText>
                                      <i className="fi fi-rs-angle-down text-sm text-offblack"></i>
                                    </button>
                                    {helpOpen && (
                                      <>
                                        <ul className="text-start px-4">
                                          <li>
                                            <Link
                                              to={`/AboutUs?userId=${userId}`}
                                              onClick={() => {
                                                setHelpOpen(!helpOpen);
                                                setDropDown(!IsDropDownOpen);
                                              }}
                                              className="block py-2 hover:text-primaryblue ease-in-out w-full my-1"
                                            >
                                              <AuxiliaryText
                                                variant={"SubMenuDropdown"}
                                              >
                                                About
                                              </AuxiliaryText>
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to={`/contact?userId=${userId}`}
                                              onClick={() => {
                                                setHelpOpen(!helpOpen);
                                                setDropDown(!IsDropDownOpen);
                                              }}
                                              className="block py-2 hover:text-primaryblue ease-in-out w-full my-1"
                                            >
                                              <AuxiliaryText
                                                variant={"SubMenuDropdown"}
                                              >
                                                Contact
                                              </AuxiliaryText>
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to={`/Blogs?userId=${userId}`}
                                              onClick={() => {
                                                setHelpOpen(!helpOpen);
                                                setDropDown(!IsDropDownOpen);
                                              }}
                                              className="block py-2 hover:text-primaryblue ease-in-out w-full my-1"
                                            >
                                              <AuxiliaryText
                                                variant={"SubMenuDropdown"}
                                              >
                                                Blog
                                              </AuxiliaryText>
                                            </Link>
                                          </li>

                                          <li>
                                            <Link
                                              to={`/HelpCentre`}
                                              onClick={() => {
                                                setHelpOpen(!helpOpen);
                                                setDropDown(!IsDropDownOpen);
                                              }}
                                              className="block py-2 hover:text-primaryblue ease-in-out w-full my-1"
                                            >
                                              <AuxiliaryText
                                                variant={"SubMenuDropdown"}
                                              >
                                                Help centre
                                              </AuxiliaryText>
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to={`/TermsConditions?userId=${userId}`}
                                              onClick={() => {
                                                setHelpOpen(!helpOpen);
                                                setDropDown(!IsDropDownOpen);
                                              }}
                                              className="block py-2 hover:text-primaryblue ease-in-out w-full my-1"
                                            >
                                              <AuxiliaryText
                                                variant={"SubMenuDropdown"}
                                              >
                                                Terms & Conditions
                                              </AuxiliaryText>
                                            </Link>
                                          </li>
                                          <li>
                                            <Link
                                              to={`/PrivacyPolicy?userId=${userId}`}
                                              onClick={() => {
                                                setHelpOpen(!helpOpen);
                                                setDropDown(!IsDropDownOpen);
                                              }}
                                              className="block py-2 hover:text-primaryblue ease-in-out w-full my-1"
                                            >
                                              <AuxiliaryText
                                                variant={"SubMenuDropdown"}
                                              >
                                                Privacy Policy
                                              </AuxiliaryText>
                                            </Link>
                                          </li>
                                        </ul>
                                      </>
                                    )}
                                    <Link
                                      to="/"
                                      className="block py-2 hover:text-primaryblue ease-in-out w-full"
                                      role="menuitem"
                                      tabIndex={-1}
                                      id="user-menu-item-2"
                                      onClick={() => {
                                        setAuth({
                                          user: null,
                                          roles: null,
                                          accessToken: "",
                                        });
                                        localStorage.removeItem("LoginSession");
                                        setDropDown(!IsDropDownOpen);
                                      }}
                                    >
                                      <AuxiliaryText variant={"MenuDropdown"}>
                                        Sign out
                                      </AuxiliaryText>
                                    </Link>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {/* {!location?.pathname?.includes("CustomerDashboard") ? ( */}
                  <button
                    onClick={toggleDrawer}
                    className=" text-black lg:hidden"
                  >
                    {isOpen ? (
                      <>
                        <i className="fi fi-rs-cross text-base text-offblack"></i>
                        {/* <CircularButton variant={"cross"} /> */}
                      </>
                    ) : (
                      <>
                        <i
                          className={`${
                            location.pathname === "/" ||
                            location.pathname === "/about-us" ||
                            location.pathname === "/business-support-centre" ||
                            location.pathname === "/customer-support-centre" ||
                            location.pathname === "/cost-estimation" ||
                            location.pathname.includes("/services-guide/") ||
                            location.pathname === "/pricing" ||
                            location.pathname.includes(
                              "/councils-regulations"
                            )
                              ? "fi fi-rr-menu-burger text-[26px] text-white"
                              : "fi fi-rr-menu-burger text-[26px] text-offblack"
                          } `}
                        ></i>
                      </>
                    )}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        {isMobile && isOpen ? (
          <div
            //<motion.div
            // initial={{ x: "100%" }}
            // animate={{ x: "0%" }}
            // transition={{ type: "tween", duration: 0.7 }}
            className={
              !auth?.user
                ? "bg-pastalblue h-[calc(100vh_-_80px)] pb-6 pt-28 px-3 fixed overflow-y-auto w-[100vw] z-10 top-[80px] left-0 block lg:hidden overflow-hidden"
                : "bg-pastalblue h-[calc(100vh_-_80px)] px-3 pt-10 fixed z-10 top-[80px] left-0 overflow-y-auto w-[100vw] pb-32 block lg:hidden overflow-hidden"
            }
          >
            {!auth?.user ? (
              <>
                <CustomerNavbar
                  isMobile={isMobile}
                  toggleDrawer={toggleDrawer}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  SignUpState={SignUpState}
                  setSignUpState={setSignUpState}
                  auth={auth}
                  setAuth={setAuth}
                  setSignIn={setSignIn}
                />
              </>
            ) : (
              <>
                <CustomerSidebarDrawer
                  isMobile={isMobile}
                  toggleDrawer={() => setIsSideBarOpen(!isSidebarOpen)}
                  isOpen={isSidebarOpen}
                  setIsOpen={setIsSideBarOpen}
                  SignUpState={SignUpState}
                  setSignUpState={setSignUpState}
                  auth={auth}
                  setAuth={setAuth}
                  close={() => setIsOpen(!isOpen)}
                />
              </>
            )}
          </div>
        ) : (
          ""
        )}
        {/* {isMobile &&
        isSidebarOpen &&
        location?.pathname?.includes("CustomerDashboard") ? (
          <motion.div
            initial={{ x: "100%" }}
            animate={{ x: "0%" }}
            transition={{ type: "tween", duration: 0.7 }}
            className={
              !auth?.user
                ? "hidden"
                : "bg-white h-[calc(100vh_-_103px)] px-3 py-6 fixed z-10  top-[85px] left-0 overflow-y-auto w-[100vw] pb-32 md:hidden"
            }
          >
            <CustomerSidebarDrawer
              isMobile={isMobile}
              toggleDrawer={() => setIsSideBarOpen(!isSidebarOpen)}
              isOpen={isSidebarOpen}
              setIsOpen={setIsSideBarOpen}
              SignUpState={SignUpState}
              setSignUpState={setSignUpState}
              auth={auth}
              setAuth={setAuth}
              close={()=> setIsSideBarOpen(!isSidebarOpen)}
            />
          </motion.div>
        ) : (
          ""
        )} */}
      </HeaderContainer>
    </>
  );
};

const HeaderContainer = ({ scrolledVersion, isMobile, children }) => {
  const location = useLocation();
  const isCustomerDashboard = location.pathname.includes("customer-dashboard");
  if (!isCustomerDashboard) {
    return (
      <div
        // initial={{ y: "-100%" }}
        // animate={{ y: "0%" }}
        // transition={{ type: "tween" }}
        className={
          location.pathname === "/"
            ? "absolute bg-transparent top-0 w-full z-40"
            : "absolute bg-transparent top-0 w-full z-40"
        }
      >
        {children}
      </div>
    );
  }
  return (
    <div
      className={`${isCustomerDashboard ? "" : "absolute top-0"} w-full z-40 ${
        isMobile ? "bg-white" : "bg-transparent"
      }`}
    >
      {children}
    </div>
  );
};

export default CustomerHeader;
