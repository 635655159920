import React from 'react';
import SpecialText from "../Typography/SpecialText";

const InlineError = ({ message }) => {
  if (!message) return null;
  
  return (
    <div className="text-alertred mt-1">
      <SpecialText variant={"FootNoteDisclaimer"}>
        {message}
      </SpecialText>
    </div>
  );
};

export default InlineError; 