import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import TextFiled from "../../../components/common/InputField/TextFiled";
import MainButton from "../../../components/common/MainButton/MainButton";
import Heading from "../../../components/common/Typography/Heading";
import Alerts from "../../../components/common/Alerts/Alerts";
import Spinner from "../../../components/common/Spinner";
import { setField, setStep } from "../../../Redux/Slice/SignUp";
import { LocationSchema } from "../../../FormValidations/SignUpSchema";
import { UpdateUserMutation } from "../../../services/SignUp";
import { useGraphQL } from "../../../context/GraphQLProvider";
import StepBackButton from "../../../components/common/StepBackButton/StepBackButton";
import { transformToUserInput } from "../../../utils/userInputUtils";


const Location = ({ onClickHandler }) => {
    const graphqlService = useGraphQL();
    const [RequestStart, setRequetStart] = useState(false);
    const dispatch = useDispatch();
    const InitialValues = useSelector((state) => state.signup);
    const { id, email, step } = useSelector((state) => state.signup);

    const UpdatePersonalDetails = (gql, location) => {
        const userInput = transformToUserInput(InitialValues);
        userInput.userInput.location = location;

        UpdateUserMutation(gql, userInput)
            .then((response) => {
                if (response.updateUserProfile?.location === location) {
                    onClickHandler();
                }
            })
            .catch((error) => {
                Alerts.error(error);
            })
            .finally(() => {
                setRequetStart(false);
            });
    };

    const formik = useFormik({
        initialValues: InitialValues,
        validationSchema: LocationSchema,
        onSubmit: (values, { resetForm }) => {
            dispatch(setField(values));
            setRequetStart(true);
            const location = values.location;
            UpdatePersonalDetails(graphqlService, location);
        },
    });
  
  return (
    <>
        {RequestStart && <Spinner />}
        <Heading
            variant={"h6"}
            className={
            "flex items-center justify-center text-center mt-[60px] xl:mt-[120px] text-offblack"
            }
        >
            Set your location
        </Heading>
        <div className="mt-10 xl:mt-[60px]">
            <TextFiled
            variant={"general"}
            label={"Location"}
            name="location"
            value={formik.values.location}
            placeholder={"Start typing your suburb or postcode."}
            star={true}
            onChange={formik?.handleChange}
            error={formik.touched.location && formik.errors.location ? formik.errors.location : null}
            />
        </div>
        <div className="flex items-center justify-between mt-10 w-full">
            
            <StepBackButton onClick={() => dispatch(setStep(step - 1))} />

            <MainButton
            variant={"extrasmall"}
            onClick={formik.handleSubmit
                // () => {
                // setStep(6);
            // }
            }
            >
            Next
            </MainButton>
        </div>
    </>
  );
};

export default Location;
