import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import TextFiled from "../../../components/common/InputField/TextFiled";
import MainButton from "../../../components/common/MainButton/MainButton";
import Snippet from "../../../components/common/SnippetIcon/Snippet";
import Heading from "../../../components/common/Typography/Heading";
import ButtonText from "../../../components/common/Typography/ButtonText";
import Paragraph from "../../../components/common/Typography/Paragraph";
import SpecialText from "../../../components/common/Typography/SpecialText";
import WrittenStepper from "../../../components/common/StepIndicater/WrittenStepper";
import Title from "../../../components/common/Title/Title";
import Spinner from "../../../components/common/Spinner";
import Alerts from "../../../components/common/Alerts/Alerts";
import { Dropdown } from "primereact/dropdown";
import { PersonalDetailsSchema } from "../../../FormValidations/SignUpSchema";
import { UpdateUserMutation } from "../../../services/SignUp";
import { useGraphQL } from "../../../context/GraphQLProvider";
import { setField, setStep } from "../../../Redux/Slice/SignUp";
import Location from "../../SignUp/Location/Location";
import AddProfilePicture from "../../SignUp/AddProfilePicture/AddProfilePicture";
import ProfilePreview from "../../SignUp/ProfilePreview/ProfilePreview";
import StepBackButton from "../../../components/common/StepBackButton/StepBackButton";
import { transformToUserInput } from "../../../utils/userInputUtils";

const CustomerInfo = ({ onClickHandler }) => {
  const graphqlService = useGraphQL();
  const [RequestStart, setRequetStart] = useState(false);
  const dispatch = useDispatch();
  const InitialValues = useSelector((state) => state.signup);
  const { id, email, firstName, lastName, location, profilePicture, step } = useSelector((state) => state.signup);

  const [mainCategory, setMainCategory] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleStepChange = (newStep) => {
    dispatch(setStep(newStep));
  };

  const UpdatePersonalDetails = (gql, values) => {
    setRequetStart(true);
    dispatch(setField(values));
    const userInput = transformToUserInput(InitialValues);
    userInput.userInput.firstName = values.firstName;
    userInput.userInput.lastName = values.lastName;

    UpdateUserMutation(gql, userInput)
      .then((response) => {
        if (
          response.updateUserProfile?.firstName === values.firstName &&
          response.updateUserProfile?.lastName === values.lastName
        ) {
          handleStepChange(5);
          setRequetStart(false);
        }
      })
      .catch((error) => {
        Alerts.error(error);
      })
      .finally(() => {
        setRequetStart(false);
      });
  };

  const formik = useFormik({
    initialValues: InitialValues,
    validationSchema: PersonalDetailsSchema,
    onSubmit: (values) => {
      UpdatePersonalDetails(graphqlService, values);
    },
  });

  return (
    <>
      {RequestStart && <Spinner />}
      <div className="w-[320px] sm:w-[380px] md:w-[400px] mx-auto h-[550px] overflow-auto hidden-scrollbar lg:h-[800px] lg:overflow-hidden pb-[550px] lg:pb-0 relative">
        {step === 4 && (
          <>
            <Heading
              variant={"h6"}
              className={
                "flex items-center justify-center text-center mt-[60px] xl:mt-[120px] text-offblack"
              }
            >
              Add Personal details
            </Heading>
            <div className="mt-10 xl:mt-[60px]">
              <form>
                <div className="flex justify-center">
                  <div className="w-full mx-auto">
                    <div>
                      <TextFiled
                        className={"lg:bg-white"}
                        variant={"general"}
                        label={"First name"}
                        name="firstName"
                        value={formik.values.firstName}
                        placeholder={"Enter your first name"}
                        star={true}
                        onChange={formik?.handleChange}
                        error={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null}
                      />
                    </div>
                    <div className="mt-5">
                      <TextFiled
                        className={"lg:bg-white"}
                        variant={"general"}
                        label={"Last name"}
                        name="lastName"
                        value={formik.values.lastName}
                        placeholder={"Enter your surname or an initial here."}
                        star={true}
                        snippet={true}
                        SnippetText={
                          "For our records and to personalize your experience, please provide either your full last name or simply its initials."
                        }
                        onChange={formik?.handleChange}
                        error={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null}
                      />
                    </div>

                    <div className="flex items-center justify-between mt-10 w-full ">

                      <StepBackButton onClick={() => handleStepChange(step - 1)} />

                      <MainButton
                        variant={"extrasmall"}
                        onClick={formik.handleSubmit
                          // () => {
                          // setStep(5);
                        // }
                      }
                      >
                        Next
                      </MainButton>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <WrittenStepper
              currentStep={step}
              lastStep={7}
              currentLabel={"Add personal details"}
              marginTop={"absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full"}
            />
          </>
        )}
        {step === 5 && (
          <>
            <Location
              onClickHandler={() => {
                handleStepChange(6);
                setRequetStart(false);
              }}
            />
            <WrittenStepper
              currentStep={step}
              lastStep={7}
              currentLabel={"Set your location"}
              marginTop={"absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full"}
          />
          </>
        )}
        {step === 6 && (
          <>
            <AddProfilePicture
              onClickHandler={() => {
                handleStepChange(7);
              }}
            />
            <WrittenStepper
              currentStep={step}
              lastStep={7}
              currentLabel={"Add profile picture"}
              marginTop={"absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full"}
            />
          </>
        )}
       
        {step === 7 && (
          <>
            <ProfilePreview
              step={step}
              lastStep={7}
              onClickHandler={onClickHandler}
            />
            <WrittenStepper
              currentStep={step}
              lastStep={7}
              currentLabel={"Confirm & Preview profile"}
              marginTop={"absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full"}
            />
          </>
        )}
      </div>
    </>
  );
};

export default CustomerInfo;
