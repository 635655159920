import { useFormik } from "formik";
import React, { useState } from "react";
// import ReCAPTCHA from "react-google-recaptcha";
import { useLocation, useNavigate } from "react-router-dom";
import { Flip, toast, ToastContainer } from "react-toastify";
import { SignIn as SignInAction } from "../../Api/SignUp";
import TextFiled from "../../components/common/InputField/TextFiled";
import MainButton from "../../components/common/MainButton/MainButton";
import PasswordField from "../../components/common/PasswordField/PasswordField";
import Heading from "../../components/common/Typography/Heading";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";
import Spinner from "../../components/common/Spinner";
import Popup from "../../components/popup/Popup";
import ForgotPassword from "../../sections/Login/ForgotPassword/ForgotPassword";
import VerificationPopUp from "../../sections/VerificationPop/VerificationPopUp";

import Alerts from "../../components/common/Alerts/Alerts";
import { useGraphQL } from "../../context/GraphQLProvider";
import { LoginQuery } from "../../services/LoginService";
import {jwtDecode } from "jwt-decode";


import {
  InitialValues,
  validationSchema,
} from "../../FormValidations/SignInSchema";
import useAuth from "../../Hooks/useAuth";
import { useEffect } from "react";
import InteractiveText from "../../components/common/Typography/InteractiveText";
import SpecialText from "../../components/common/Typography/SpecialText";
import { handleLogin } from "../../utils/authUtils";
import { useDispatch } from "react-redux";

const Login = ({
  SignIn,
  setSignIn,
  SignUpState,
  setSignUpState,
  location: redirect,
  // userDetails,
}) => {
  const graphqlService = useGraphQL();
  const [PasswordType, setPasswordType] = useState("true");
  // const [validCaptcha, setValidCaptcha] = useState(false);
  const [VerificationError, setVerificationError] = useState({
    isCredentialsMatched: false,
  });
  const USER_TYPE = {
    CUSTOMER: "customer",
    SELLLER: "seller",
  };
  const [RequestStart, setRequetStart] = useState(false);
  // const [UserType, setUserType] = useState(USER_TYPE.CUSTOMER);
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const location = useLocation();
  // let from = location.state?.from?.pathname;
  // if (location?.state?.from?.pathname) from = location.state.from.pathname;
  // else if (location?.pathname) from = location.pathname;
  // else if (redirect) from = redirect;
  // else from = "CustomerDashboard";

  const LoginUser = async (gql, email, password) => {
    await handleLogin(gql, email, password, {
      setRequetStart,
      setAuth,
      setSignIn,
      navigate,
      dispatch
    });
  }

  const formik = useFormik(
    {
      initialValues: InitialValues,
      validationSchema: validationSchema,
      onSubmit: (values, { resetForm }) => {
        // setRequetStart(true);
        // if (!validCaptcha) {
        //   setRequetStart(false);
        //   toast.error("reCAPTCHA required!", {
        //     position: toast.POSITION.TOP_RIGHT,
        //     transition: Flip,
        //   });
        // } else {


        // const SignInUser = {
        //   Email: values.email,
        //   Password: values.password,
        //   ConfirmPassword: values.password,
        // };

        LoginUser(graphqlService, values.email, values.password);

        // SignInAction(SignInUser)
        //   .then((response) => {
        //     if (response.status === 200) {
        //       const accessToken = response?.data?.token;
        //       const expireAt = response?.data?.tokenExpire;
        //       const roles = response?.data?.userRoles?.$values;
        //       const user = response?.data?.key;
        //       const subscription = response?.data?.matrix?.subscription;
        //       const userDetails = {
        //         accessToken,
        //         roles,
        //         user,
        //         expireAt,
        //         subscription,
        //       };
        //       setRequetStart(false);
        //       localStorage.setItem("LoginSession", JSON.stringify(userDetails));
        //       setAuth(userDetails);
        //       setSignIn(!SignIn);
        //       // console.log("UserDetails", userDetails);
        //       // setValidCaptcha(false);
        //       navigate("/");
        //       if (
        //         roles?.filter((e) => e.roleName === USER_TYPE.SELLLER).length >
        //         0
        //       ) {
        //         navigate({ pathname: "/Services" }, { replace: true });
        //       } else navigate({ pathname: from }, { replace: true });
        //     }
        //   })
        //   .catch((error) => {
        //     setRequetStart(false);
        //     toast.error("Username or Password is Incorrect", {
        //       position: toast.POSITION.TOP_RIGHT,
        //       transition: Flip,
        //     });
        //   });
      },
    }
    // }
  );

  const [ShowCrossButton, setShowCrossButton] = useState(true);
  const [step, setStep] = useState(1);
  useEffect(() => {
    if (SignIn || SignUpState) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [SignIn, SignUpState]);
  
  return (
    <>
      <ToastContainer autoClose={2000} />
      {SignIn && (
        <Popup
          onClose={() => {
            setStep(1);
            setSignIn(false)
          }}
          setTrigger={setSignIn}
          modelType="SignIn"
          ShowCrossButton={ShowCrossButton}
          cross={true}
        >
        <div className="h-[600px] overflow-auto hidden-scrollbar md:h-[780px] lg:h-[800px] lg:overflow-hidden pb-[550px] lg:pb-0 relative">
          {step === 1 && (
            <>
              <div>
                <Heading
                  variant={"h6"}
                  className={
                    "flex items-center justify-center text-offblack mt-[60px] xl:mt-[120px]"
                  }
                >
                  Login
                </Heading>
                <div className="mt-10 xl:mt-[60px]">
                  {RequestStart && <Spinner />}
                  <form>
                    <div className="flex justify-center mx-[5%] md:mx-auto">
                      <div className="w-fit mx-auto">
                        <TextFiled
                          className={"bg-white"}
                          label={"Email"}
                          variant={"medium"}
                          type="email"
                          name="email"
                          placeholder={"Enter your registered email"}
                          onChange={formik?.handleChange}
                          value={formik?.values?.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-alertred">
                            <SpecialText
                              variant={"FootNoteDisclaimer"}
                              className={"mt-1"}
                            >
                              {formik.errors.email}
                            </SpecialText>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="my-5 flex justify-center mx-[5%] md:mx-0">
                      <div className="w-fit mx-auto">
                        <label className="mb-2 block">
                          <AuxiliaryText
                            variant={"FieldLabel"}
                            className={"text-offblack"}
                          >
                            Password
                          </AuxiliaryText>
                        </label>
                        <PasswordField
                          passwordtype={PasswordType}
                          setpasswordtype={setPasswordType}
                          name="password"
                          placeholder={"Enter your associated passward"}
                          onChange={formik?.handleChange}
                          value={formik?.values?.password}
                        />
                        <div
                          className={
                            formik.touched.password && formik.errors.password
                              ? "flex justify-between mt-1"
                              : "flex justify-end mt-1"
                          }
                        >
                          {formik.touched.password && formik.errors.password ? (
                            <div className="text-alertred">
                              <SpecialText variant={"FootNoteDisclaimer"}>
                                {formik.errors.password}
                              </SpecialText>
                            </div>
                          ) : null}
                          <div>
                            <button
                              className="text-primaryblue cursor-pointer"
                              type="button"
                              onClick={() => setStep(2)}
                            >
                              <InteractiveText variant={"ActionLink"}>
                                Forgot password
                              </InteractiveText>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                        <div className="flex justify-center mt-10 xl:mt-[60px]">
                          <MainButton
                            variant={"large"}
                            onClick={formik.handleSubmit}
                          >
                            Login
                          </MainButton>
                        </div>
                        <div className="flex items-center justify-center my-3 md:my-5">
                          <AuxiliaryText
                            variant={"Infographic"}
                            className={"text-center"}
                          >
                            or
                          </AuxiliaryText>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                          {/* <MainButton variant={"facebook"}>
                        <i className="fi fi-brands-facebook text-white size-4 pt-1"></i>
                        Continue with Facebook
                      </MainButton> */}
                          <MainButton variant={"google"}>
                            <i className="fi fi-brands-google text-white size-4 pt-1"></i>
                            {/* Continue with Google */}
                          </MainButton>
                        </div>
                    <div className="flex justify-center">
                      <SpecialText
                        variant={"FootNoteDisclaimer"}
                        className={"mt-10 xl:w-[380px] text-center"}
                      >
                        By continuing, you're agree to tradesposter <br />
                        <span
                          className="text-primaryblue cursor-pointer text-[10px]"
                          onClick={() => {
                            setSignIn(false);
                            navigate("/terms-&-conditions");
                          }}
                        >
                          Terms & Conditions.
                        </span>
                      </SpecialText>
                    </div>
                    <div class="border-t border-t-offblack w-[100px] my-2 mx-auto"></div>
                    {/* <hr className="bg-offblack h-px w-[100px] my-2 mx-auto" /> */}
                    <div className="flex justify-center">
                      <SpecialText
                        variant={"FootNoteDisclaimer"}
                        className={"text-center"}
                      >
                        Don't have an account? <br />
                        <span
                          className="text-primaryblue cursor-pointer"
                          onClick={() => {
                            setSignIn(!SignIn);
                            setSignUpState(!SignUpState);
                          }}
                        >
                          &nbsp;Sign up
                        </span>
                      </SpecialText>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <ForgotPassword
                    onClickHandler={() => {
                      setStep(3);
                  }}
                  />
            </>
          )}
          {step === 3 && (
            <div className="mt-[60px] xl:mt-[120px]">
              <VerificationPopUp
                Headingh6={true}
                Headingh5={true}
                secondHeadingh6={true}
                secondHeadingh5={true}
                closePopup={() => setStep(1)}
                firstMainHeading={"Email Verification"}
                label={"Varification code"}
                placeholder={"Enter the code here"}
                secondMainHeading={"Password updated !!!"}
                secondP={
                  "Your password has been successfully updated! You can now securely log in to your account using your new password."
                }
                buttonText={"Submit"}
              />
            </div>
          )}

        </div>
        </Popup>
      )}
    </>
  );
};

export default Login;
