// Send Forgot Password Email

export const ForgotPasswordMutation = async (graphqlService, variables) => {
    try {
        const FORGOTPASSWORD_MUTATION = `
            mutation ForgotPassword($email: String!) {
                forgotPassword(email: $email) {
                    success
                    resetLink
                }
            }
        `;
        const response = await graphqlService.mutateData(FORGOTPASSWORD_MUTATION, variables);
        return response;
    } catch (error) {
        console.error("Mutation Error:", error);
        throw error;
    }
};