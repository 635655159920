import * as Yup from "yup";
import { validateText } from '../../utils/textValidation';
import { useSelector } from 'react-redux';

export const defaultValues = {
  // selectedType: "",
  mainCategory: "",
  subCategory: "",
  customSubcategory: "",
  postTitle: "",
  postTags: [], // Ensure postTags is initialized as an empty array
  description: "",
  range: 5,
  location: "",
  portfolioImages: [],
};

export const validationSchema = Yup.object({
  mainCategory: Yup.object().required("Primary category is required"),
  subCategory: Yup.object().required("Sub category is required"),
  customSubcategory: Yup.string().when('subCategory', {
    is: (value) => value?.id === 'custom',
    then: () => Yup.string()
      .required('Custom subcategory is required')
      .min(25, 'Minimum 25 characters required')
      .max(40, 'Maximum 40 characters allowed')
      .matches(
        /^[A-Za-z\s]+$/,
        'Only letters and spaces are allowed'
      )
      .test('spellcheck', 'Please check spelling of all words', function(value) {
        if (!value) return true;
        const result = validateText(value);
        if (!result.isValid) {
          throw this.createError({
            message: result.suggestedMessage || result.message
          });
        }
        return true;
      }),
    otherwise: () => Yup.string(),
  }),
  postTitle: Yup.string()
    .min(40, "Title must be at least 40 characters")
    .max(60, "Title must not exceed 60 characters")
    .required("Post title is required"),
  postTags: Yup.array()
    .min(1, "Minimum 1 tag is required")
    .test('tagLimit', 'Tag limit exceeded for your plan', function(value) {
      const userPlan = this.parent.userPlan?.plan?.name?.toLowerCase() || 'basic';
      const maxTags = userPlan === 'pro' ? 12 : 5;
      if (value.length > maxTags) {
        return this.createError({
          message: `Maximum ${maxTags} tags allowed for ${userPlan} plan`
        });
      }
      return true;
    })
    .required("Minimum 1 tag is required"),
  portfolioImages: Yup.array().required("Please upload post images."),
  description: Yup.string()
    .min(450, "Description must be at least 450 characters")
    .max(600, "Description must not exceed 600 characters")
    .required("Description can't be empty."),
  range: Yup.number()
    .oneOf([5, 10], "Range must be either 5km or 10km")
    .required("Please define radius."),
  location: Yup.string().required("Please share your location."),
});
