import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useGraphQL } from "../../context/GraphQLProvider";
import MainButton from "../../components/common/MainButton/MainButton";
import PasswordField from "../../components/common/PasswordField/PasswordField";
import Heading from "../../components/common/Typography/Heading";
import Alerts from "../../components/common/Alerts/Alerts";
import Spinner from "../../components/common/Spinner";
import { useSearchParams, useNavigate } from 'react-router-dom';
import SpecialText from "../../components/common/Typography/SpecialText";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";
import { ResetPasswordSchema } from "../../FormValidations/resetPasswordSchema";
import { ResetPasswordMutation } from "../../services/ResetPasswordService";

const ResetPassword = () => {
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const navigate = useNavigate();
    const graphqlService = useGraphQL();
    const [RequestStart, setRequetStart] = useState(false);
    const [PasswordType, setPasswordType] = useState("true");
    const [ConfirmPasswordType, setConfirmPasswordType] = useState("true");

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, [token, navigate]);

    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: ResetPasswordSchema,
        onSubmit: async (values) => {
            try {
                setRequetStart(true);
                const response = await ResetPasswordMutation(graphqlService, {
                    token,
                    newPassword: values.password
                });

                if (response.resetPassword.success) {
                    Alerts.success("Password reset successfully!");
                    navigate('/login');
                } else {
                    Alerts.error(response.resetPassword.message);
                }
            } catch (error) {
                Alerts.error(error.message);
            } finally {
                setRequetStart(false);
            }
        },
    });

    return (
        <>
            {RequestStart && <Spinner />}
            <div className="flex min-h-screen">
                <div className="flex flex-col justify-center items-center w-full">
                    <div className="w-[320px] sm:w-[380px] md:w-[448px] mx-auto">
                        <Heading
                            variant={"h6"}
                            className={"text-center mb-10 text-offblack"}
                        >
                            Reset Password
                        </Heading>

                        <form onSubmit={formik.handleSubmit}>
                            <div className="flex flex-col items-center justify-center space-y-6">
                                    <div className="flex justify-center">
                                        <div className="w-full">
                                            <label className="mb-2 block">
                                                <AuxiliaryText
                                                    variant={"FieldLabel"}
                                                    className={"text-offblack"}
                                                >
                                                    New Password
                                                </AuxiliaryText>
                                            </label>
                                            <PasswordField
                                                passwordtype={PasswordType}
                                                setpasswordtype={setPasswordType}
                                                name="password"
                                                placeholder={"Enter your new password"}
                                                onChange={formik.handleChange}
                                                value={formik.values.password}
                                            />
                                            {formik.touched.password && formik.errors.password ? (
                                                <SpecialText
                                                    variant={"FootNoteDisclaimer"}
                                                    className={"mt-1 text-alertred"}
                                                >
                                                    {formik.errors.password}
                                                </SpecialText>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="flex justify-center">
                                        <div className="w-full">
                                            <label className="mb-2 block">
                                                <AuxiliaryText
                                                    variant={"FieldLabel"}
                                                    className={"text-offblack"}
                                                >
                                                    Confirm Password
                                                </AuxiliaryText>
                                            </label>
                                            <PasswordField
                                                passwordtype={ConfirmPasswordType}
                                                setpasswordtype={setConfirmPasswordType}
                                                name="confirmPassword"
                                                placeholder={"Confirm your new password"}
                                                onChange={formik.handleChange}
                                                value={formik.values.confirmPassword}
                                            />
                                            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                                <SpecialText
                                                    variant={"FootNoteDisclaimer"}
                                                    className={"mt-1 text-alertred"}
                                                >
                                                    {formik.errors.confirmPassword}
                                                </SpecialText>
                                            ) : null}
                                        </div>
                                    </div>

                                    <MainButton
                                        variant={"large"}
                                        type="submit"
                                        className={"w-full"}
                                        disabled={RequestStart}
                                    >
                                        Reset Password
                                    </MainButton>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ResetPassword; 