import React from "react";
import { useSelector } from "react-redux";
import SpecialText from "../Typography/SpecialText";
import Profile from "../../../assets/img/User.png";
import { useState } from "react";
import Title from "../Title/Title";
import AuxiliaryText from "../Typography/AuxiliaryText";
import { Link } from "react-router-dom";

const USER_TYPE = {
  CUSTOMER: "customer",
  SELLER: "seller",
};

const CustomerProfile = ({ profileCompleteion }) => {
  const {
    roleType,
    firstName,
    lastName,
    businessName,
    location,
    profilePicture,
    startDate
  } = useSelector((state) => state.user);
  console.log(useSelector((state) => state.user));

  const isCustomer = roleType === USER_TYPE.CUSTOMER;
  
  const displayName = isCustomer 
    ? `${firstName} ${lastName}`.trim() || "User Name"
    : businessName || "Business Name";

    console.log(firstName);

  const [image, setImage] = useState(profilePicture || Profile);
  
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };
  
  const completion = 50;

  return (
    <>
      <div>
        <div className="flex items-center justify-between">
          <div className="flex justify-center lg:justify-start group relative size-[72px]">
            <div className="hidden group-hover:block absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
              <div className="flex justify-center items-center bg-white rounded-full w-[30px] h-[30px] relative">
                <div>
                  <input
                    type="file"
                    id="custPicture"
                    onChange={onImageChange}
                    accept="image/*"
                    className="cursor-pointer hidden"
                  />
                  <label
                    role="button"
                    htmlFor="custPicture"
                    className="flex justify-center bg-transparent"
                  >
                    <Title
                      text={"Edit"}
                      width={"50"}
                      className={"bg-pastalblue"}
                    >
                      <i className="fi fi-rs-pen-circle text-xs text-mediumgray hover:text-primaryblue"></i>
                    </Title>
                  </label>
                </div>
              </div>
            </div>
            <div className="w-full h-[60px] sm:h-[66px] md:h-[72px] relative">
              <div className="flex items-center justify-center lg:justify-start lg:gap-3">
                <div className="relative size-[60px] sm:size-[66px] md:size-[72px] div2">
                  <img
                    className="object-center rounded-full w-full h-full cursor-pointer"
                    src={image}
                    alt="Profile"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* progress performance */}
          {profileCompleteion && (
            <div className="hidden lg:block">
              <div className="flex items-center gap-2">
                <AuxiliaryText variant={"FieldLabel"}>
                  {completion}%
                </AuxiliaryText>
                <div className="w-[90px] xl:w-[100px] h-2 bg-pastalblue rounded-full">
                  <div
                    className={`h-2 bg-freshgreen rounded-full w-[${completion}px]`}
                  ></div>
                </div>
              </div>
              <Link to={"/customer-dashboard/account"}>
                <AuxiliaryText
                  variant={"Placeholder"}
                  className={"text-primaryblue"}
                >
                  Complete your profile
                </AuxiliaryText>
              </Link>
            </div>
          )}
        </div>
        <div className="mt-5">
          <div>
            <SpecialText 
              variant={"ProfileName"} 
              className={"text-offblack text-center lg:text-start"}
            >
              {displayName}
            </SpecialText>
          </div>
          {location && (
            <div className="mt-3">
              <SpecialText 
                variant={"OccupationLocation"} 
                className={"text-offblack text-center lg:text-start"}
              >
                {location}
              </SpecialText>
            </div>
          )}
          <div className="mt-1">
            <SpecialText 
              variant={"Date"} 
              className={"text-offblack text-center lg:text-start"}
            >
              Member Since {startDate ? new Date(startDate).toLocaleDateString('en-US', { month: 'short', year: 'numeric' }) : 'Mar 2025'}
            </SpecialText>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerProfile;
