import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessToken: null,
  id: null,
  personalEmail: null,
  firstName: null,
  lastName: null,
  location: null,
  startDate: null,
  abn: null,
  businessName: null,
  companyName: null,
  profilePicture: null,
  roleType: null,
  serviceCategory: null,
  userPlan: null,
  expireAt: null,
  isAuthenticated: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
      };
    },
    clearUserDetails: () => initialState,
  },
});

export const { setUserDetails, clearUserDetails } = userSlice.actions;
export default userSlice.reducer; 