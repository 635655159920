export const LoginQuery = async (graphqlService, variables) => {
    const Login_QUERY = `
        query LoginUser($email: String!, $password: String!) {
            loginUser(email: $email, password: $password) {
                access_token
                id_token
                scope
                expires_in
                token_type
                id
                personalEmail
                profilePicture
                firstName
                lastName
                businessName
                roleType
                primaryContactNumber
                secondaryContactNumber
                emergencyContact
                jobTitle
                location
                department
                startDate
                tradesPosterEmail
                abn
                businessName
                companyName
                serviceCategory {
                    id
                    name
                }
                userPlan {
                    id
                    userId
                    planId
                    startDate
                    trialEndDate
                    isTrialActive
                    isPaidActive
                    plan {
                        id
                        name
                        price
                        keywordsLimit
                        postsLimit
                        portfolioType
                        visibilityBoost
                        prioritySearch
                        analytics
                        certifications
                        successStories
                        trialDays
                    }
                }
            }
        }
    `;
    try {
        const response = await graphqlService.fetchData(Login_QUERY, variables);
        return response;
    } catch (error) {
        console.error("Query Error:", error);
        throw error;
    }
};