import React, { useEffect, useState } from "react";
import { useGraphQL } from "../context/GraphQLProvider";

const GET_ITEMS_QUERY = `
    query {
    characters(page: 1, limit: 2) {
            results {
                id
                name
                species
                image
            }
        }
    }
`;

const MyTestComponent = () => {
    const graphqlService = useGraphQL();
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        graphqlService.fetchData(GET_ITEMS_QUERY)
            .then((data) => {
                setItems(data.characters.results);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching data:", error);
                setLoading(false);
            });
    }, [graphqlService]);

    if (loading) return <p>Loading...</p>;

    return (
        <ul>
            {items.map((item) => (
                <li key={item.id}>
                    <img
                     className="cursor-pointer object-center w-[50px]"
                     src={item.image}/> |
                    {item.name}</li>
            ))}
        </ul>
    );
};

export default MyTestComponent;
