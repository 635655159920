import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import MainButton from "../../../components/common/MainButton/MainButton";
import Heading from "../../../components/common/Typography/Heading";
import Snippet from "../../../components/common/SnippetIcon/Snippet";
import WrittenStepper from "../../../components/common/StepIndicater/WrittenStepper";
import { UpdateUserMutation } from "../../../services/SignUp";
import { useGraphQL } from "../../../context/GraphQLProvider";
import Alerts from "../../../components/common/Alerts/Alerts";
import Spinner from "../../../components/common/Spinner";
import { fetchServiceCategories } from "../../../Redux/Slice/serviceCategoriesSlice";
import { setField, setStep } from "../../../Redux/Slice/SignUp";
import StepBackButton from "../../../components/common/StepBackButton/StepBackButton";
import { transformToUserInput } from "../../../utils/userInputUtils";

const SelectService = ({ onClickHandler }) => {
    const graphqlService = useGraphQL();
    const dispatch = useDispatch();
    const [mainCategory, setMainCategory] = useState(null);
    const [requestStart, setRequestStart] = useState(false);
    const InitialValues = useSelector((state) => state.signup);
    const { id, email, serviceCategoryId, step } = useSelector((state) => state.signup);
    const { categories, loading, error } = useSelector((state) => state.serviceCategories);

    console.log("mainCategory", mainCategory);

    useEffect(() => {
        if (categories.length === 0) {
            dispatch(fetchServiceCategories(graphqlService));
        }
    }, [dispatch, graphqlService, categories.length]);

    useEffect(() => {
        if (categories.length > 0 && serviceCategoryId && !mainCategory) {
            const selectedCategory = categories.find(category => category.id === serviceCategoryId);
            if (selectedCategory) {
                setMainCategory(selectedCategory);
            }
        }
    }, [categories, serviceCategoryId, mainCategory]);

    const handleSubmit = async () => {
        if (!mainCategory) {
            Alerts.error("Please select a service category");
            return;
        }

        setRequestStart(true);
        const userInput = transformToUserInput(InitialValues);
        userInput.userInput.serviceCategoryId = mainCategory.id;

        try {
            const response = await UpdateUserMutation(graphqlService, userInput);
            if (response.updateUserProfile?.serviceCategoryId === mainCategory.id) {
                dispatch(setField({ serviceCategoryId: mainCategory.id }));
                onClickHandler();
            }
        } catch (error) {
            Alerts.error(error.message);
        } finally {
            setRequestStart(false);
        }
    };

    return (
        <>
            {(loading || requestStart) && <Spinner />}
            <Heading
                variant={"h6"}
                className={"flex items-center justify-center text-center mt-[60px] xl:mt-[120px] text-offblack"}
            >
                Select your service
            </Heading>
            <div className="mt-10 xl:mt-[60px]">
                <div className="flex">
                    <Snippet
                        variant={"label"}
                        snippet={true}
                        star={true}
                        text={"Select the main category that best represents your business, for example, carpentry."}
                        label={"Select main service category"}
                    />
                </div>
                <div>
                    <Dropdown
                        value={mainCategory}
                        onChange={(e) => setMainCategory(e.value)}
                        options={categories}
                        optionLabel="name"
                        placeholder="Choose 1 main category"
                        filter
                        className={`custom-dropdown-signup bg-white text-offblack items-center focus:border block font-inter font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-[50px] sm:h-[50px] md:h-[54px] lg:h-[54px] xl:h-[56px] 2xl:h-[56px] py-1 px-2.5`}
                    />
                </div>
                <div className="flex items-center justify-between mt-10 w-full">
                    <StepBackButton onClick={() => dispatch(setStep(step - 1))} />
                    <MainButton
                        variant={"extrasmall"}
                        onClick={handleSubmit}
                        // disabled={loading || requestStart}
                    >
                        Next
                    </MainButton>
                </div>
            </div>
            <WrittenStepper
                currentStep={5}
                lastStep={8}
                currentLabel={"Select your service"}
                marginTop={"absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full"}
            />
        </>
    );
};

export default SelectService; 