import * as Yup from "yup";
// export const InitialValues = {
//   // username: "",
//   email: "",
//   password: "",
//   // repeatPassword: "",
// };
export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is Required")
    .label("Email"),
    password: Yup.string()
    .required("Please enter a password")
    .min(8, "Password too short")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]/,
      `Password must contain at least 
      one uppercase letter, 
      one lowercase letter, 
      one number and one special character`
  )
  // repeatPassword: Yup.string()
  //   .min(6)
  //   .when("password", {
  //     is: (val) => (val && val.length > 0 ? true : false),
  //     then: Yup.string().oneOf(
  //       [Yup.ref("password")],
  //       "Both password need to be the same"
  //     ),
  //   })
  //   .required("Confirm Password Required"),
});



// validationSchema: Yup.object({
//   email: Yup.string()
//       .min(8, 'Must be at least 8 characters')
//       .max(20, 'Must be less  than 20 characters')
//       .required('Email is required')
//       .test('Unique Email', 'Email already in use', // <- key, message
//           function (value) {
//               return new Promise((resolve, reject) => {
//                   axios.get(`http://localhost:8003/api/u/user/${value}/available`)
//                       .then((res) => {
//                           resolve(true)
//                       })
//                       .catch((error) => {
//                           if (error.response.data.content === "The email has already been taken.") {
//                               resolve(false);
//                           }
//                       })
//               })
//           }
//       ),
// }),

// export const verificationCodeInitialValues = {
//   verificationcode: "",
// };

export const verificationCodeSchema = Yup.object().shape({
  verificationCode: Yup.string()
    .length(6, 'Verification code must be exactly 6-digit')
    .required("Verification Code is Required"),
});

export const PersonalDetailsSchema = Yup.object().shape({
    firstName: Yup.string()
    .matches(/^[A-Za-z]+$/, 'First name can only contain letters')
    .min(2, 'First name must be at least 2 characters')
    .max(50, 'First name cannot exceed 50 characters')
    .required('First name is required'),

    lastName: Yup.string()
    .matches(/^[A-Za-z]+$/, 'Last name can only contain letters')
    .min(2, 'Last name must be at least 2 characters')
    .max(50, 'Last name cannot exceed 50 characters')
    .required('Last name is required'),
});

export const BusinessInformationSchema = Yup.object().shape({
  abn: Yup.string()
  .required('ABN is required'),

  businessName: Yup.string()
  .min(3, 'Last name must be at least 2 characters')
  .max(60, 'Last name cannot exceed 50 characters')
  .required('Business Name is required'),

  companyName: Yup.string()
  .min(3, 'Company name must be at least 3 characters')
  .max(60, 'Company name cannot exceed 60 characters'),
});

export const LocationSchema = Yup.object().shape({
  location: Yup.string()
    .min(5, 'Location must be at least 5 characters')
    .max(100, 'Location cannot exceed 100 characters')
    .required('Location is required'),
});
