import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GetServiceCategoriesQuery } from '../../services/ServiceCategories';

export const fetchServiceCategories = createAsyncThunk(
  'serviceCategories/fetchCategories',
  async (graphqlService) => {
    const response = await GetServiceCategoriesQuery(graphqlService);
    return response.serviceCategories.categories;
  }
);

const initialState = {
  categories: [],
  loading: false,
  error: null
};

const serviceCategoriesSlice = createSlice({
  name: 'serviceCategories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchServiceCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.categories = action.payload;
      })
      .addCase(fetchServiceCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default serviceCategoriesSlice.reducer; 