import React, { useState, useEffect } from "react";
import { Chips } from "primereact/chips";
import Snippet from "../../../../components/common/SnippetIcon/Snippet";
import { Field, ErrorMessage } from "formik";
import SpecialText from "../../../../components/common/Typography/SpecialText";
import InteractiveText from "../../../../components/common/Typography/InteractiveText";
import MainButton from "../../../../components/common/MainButton/MainButton";
import { useSelector } from "react-redux";

const Keywords = ({ setStep, setFieldValue, values }) => {
  const userPlan = useSelector((state) => state.user.userPlan?.plan?.name?.toLowerCase()) || 'basic';
  const maxTags = userPlan === 'pro' ? 12 : 5;
  const [selectedTags, setSelectedTags] = useState([]);
  const [originalSuggestions, setOriginalSuggestions] = useState([]);

  // Store original suggestions when component mounts
  useEffect(() => {
    setOriginalSuggestions(values.postTags || []);
  }, []);

  // Handle adding a keyword from suggestions to Chips
  const handleAddKeyword = (keyword) => {
    if (selectedTags.length < maxTags) {
      // Add to selected tags
      setSelectedTags([...selectedTags, keyword]);
      // Remove from suggested keywords
      const updatedSuggestions = values.postTags.filter(tag => tag !== keyword);
      setFieldValue("postTags", updatedSuggestions);
    }
  };

  return (
    <>
      <div className="w-fit mx-auto mt-[60px]">
        <Snippet
          variant={"label"}
          label={"Keywords"}
          text={`Use pre-filed keywords or add your own to boost visibility. (${maxTags} tags max for ${userPlan} plan)`}
          snippet={true}
          star={true}
        />
        <Chips
          id="addyourtags"
          placeholder="Tag your post with relevant keywords"
          className="custom-chips-bg placeholder-lightgray text-offblack px-2 min-h-[46px] sm:min-h-[50px] md:min-h-[54px] lg:min-h-[56px] w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] rounded-lg"
          value={selectedTags}
          onChange={(e) => {
            // Handle both adding and removing tags
            const addedTags = e.value.filter(tag => !selectedTags.includes(tag));
            const removedTags = selectedTags.filter(tag => !e.value.includes(tag));
            
            // Only add back tags that were originally in suggestions
            const suggestedTagsToAddBack = removedTags.filter(tag => 
              originalSuggestions.includes(tag)
            );
            
            if (suggestedTagsToAddBack.length > 0) {
              setFieldValue("postTags", [...values.postTags, ...suggestedTagsToAddBack]);
            }
            
            // Enforce keyword limit
            const newTags = e.value.slice(0, maxTags);
            setSelectedTags(newTags);
          }}
          separator=","
        />
        <SpecialText
          variant={"FootNoteDisclaimer"}
          className={"mt-2 text-alertred"}
        >
          <ErrorMessage name="postTags" component="div" />
        </SpecialText>

        {/* Suggested Keywords Section */}
        {values.postTags && values.postTags.length > 0 && (
          <div className="mt-4">
            <Snippet
              variant={"label"}
              label={"Suggested Keywords"}
            />
            <div className="flex flex-wrap gap-2 w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px]">
              {values.postTags.map((keyword, index) => (
                <div
                  key={index}
                  onClick={() => handleAddKeyword(keyword)}
                  className="px-3 py-1 rounded-full text-xs cursor-pointer transition-colors bg-pastalblue text-offblack hover:bg-primaryblue hover:text-white"
                >
                  {keyword}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Buttons and navigation */}
      <div className="flex items-center justify-between w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mt-10 mx-auto">
        <div onClick={() => setStep(2)}>
          <InteractiveText
            variant={"ActionLink"}
            className="text-mediumgray hover:text-primaryblue cursor-pointer"
          >
            Back
          </InteractiveText>
        </div>
        <div>
          <MainButton
            type="submit"
            onClick={() => {
              if (selectedTags.length > 0 && selectedTags.length <= maxTags) {
                setStep(4);
              }
            }}
            variant={"small"}
          >
            Next
          </MainButton>
        </div>
      </div>
    </>
  );
};

export default Keywords; 