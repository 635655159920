/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
// import { IconContext } from "react-icons";
// import { FaUserAlt as User } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import MainButton from "../common/MainButton/MainButton";
import Avtar from "../../assets/img/Avtar.png";
// import { IconContext } from "react-icons";
// import {
//   AiOutlineBell as NotificationButton,
//   AiOutlineMail as MessagesButton,
// } from "react-icons/ai";
// import "./header.css";
import useAuth from "../../Hooks/useAuth";
// import SecondaryHeading from "../common/Typography/SecondaryHeading";
import AuxiliaryText from "../common/Typography/AuxiliaryText";
import useClickOutside from "../../Hooks/useClickOutside";
// import Paragraph from "../common/Typography/Paragraph";
// import ButtonText from "../common/Typography/ButtonText";
import InteractiveText from "../common/Typography/InteractiveText";
import SpecialText from "../common/Typography/SpecialText";
import AuthButtons from '../common/AuthButtons/AuthButtons';
const Navbar = ({
  isMobile,
  toggleDrawer,
  setSignUpState,
  SignUpState,
  setSignIn,
  SignIn,
}) => {
  const location = useLocation();
  const [listOpen, setListOpen] = useState(false);
  const [postingOpen, setPostingOpen] = useState(false);
  const [subsOpen, setSubsOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);
  const [notificationsAlerts, setNotificationsAlerts] = useState(false);
  const [inboxOpen, setInboxOpen] = useState(false);
  const { auth, setAuth } = useAuth();
  const [IsHomePage, setIsHomePage] = useState(
    location.pathname === "/" ? true : false
  );
  const [categories, setCategories] = useState(
    location.hash === "#categorie" ? true : false
  );
 
  const [packages, setPackages] = useState(
    location === "/pricing" ? true : false
  );

  const { id: userId } = auth?.user ?? "";
  useEffect(() => {
    setIsHomePage(
      location.pathname === "/" && location.hash === "" ? true : false
    );
    setCategories(
      location.pathname === "/" && location.hash === "#categorie" ? true : false
    );
    setPackages(location.pathname === "/pricing" ? true : false);
  }, [location, IsHomePage, categories, packages]);

  const ref = useRef(null);
  useClickOutside(ref, () => {
    setNotificationsAlerts(false);
  });
  return (
    <>
      <nav
        className={`${
          isMobile
            ? "items-center flex-col overflow-hidden"
            : "items-center flex-row "
        } flex menuItems`}
      >
        <ul
          className={`${
            isMobile ? "items-center flex-col" : "items-center flex-row"
          } flex menuItems`}
        >
          <li className="lg:mr-8 mb-1 lg:mb-0 relative hover:text-primaryblue px-2 lg:px-0 py-2 lg:py-0 block ">
            <NavHashLink
              to="/#categorie"
              spy={true}
              smooth={true}
              offset={-88}
              duration={500}
              onClick={toggleDrawer}
              className={`cursor-pointer  ${
                categories ? " text-primaryblue " : ""
              }`}
            >
              <AuxiliaryText variant={"NavigationText"}>
                Categories
              </AuxiliaryText>
              {/* <MainButton variant={"medium"} className={"hidden lg:block"}>
                Categories
              </MainButton>
              <MainButton variant={"large"} className={"lg:hidden"}>
                Categories
              </MainButton> */}
            </NavHashLink>
          </li>
          <li className="lg:mr-8 mb-1 lg:mb-0 relative hover:text-primaryblue px-2 lg:px-0 py-2 lg:py-0  block ">
            <NavLink
              to={"/pricing"}
              onClick={toggleDrawer}
              className={`cursor-pointer  ${
                packages ? " text-primaryblue " : ""
              }`}
            >
              <AuxiliaryText variant={"NavigationText"}>
                Pricing
              </AuxiliaryText>
            </NavLink>
          </li>
          <li className="lg:mr-8 mb-1 lg:mb-0 relative hover:text-primaryblue px-2 lg:px-0 py-2 lg:py-0 lg:hidden ">
            <NavLink
              to={"/HelpCentre"}
              onClick={toggleDrawer}
              className={`cursor-pointer  ${
                packages ? " text-primaryblue " : ""
              }`}
            >
              <AuxiliaryText variant={"NavigationText"}>
                Help
              </AuxiliaryText>
            </NavLink>
          </li>
        </ul>
      </nav>
      {auth?.user?.emailConfirmed && (
        <ul className="flex items-center gap-5 ml-10">
          <li
            className="cursor-pointer hover:bg-transparent hidden lg:block relative"
            ref={ref}
          >
            <i
              className="fi fi-rs-bell size-4 cursor-pointer"
              onClick={() => setNotificationsAlerts(!notificationsAlerts)}
            ></i>
            {notificationsAlerts && (
              <div className="absolute z-10 w-[350px] origin-top-right rounded-xl bg-white text-offblack pt-5 pb-10 mt-3 -right-14 shadow-2xl">
                <div
                  class="w-0 h-0 border-l-[10px] border-l-transparent border-b-[15px] border-b-white border-r-[10px] border-r-transparent absolute left-[275px] -mt-8"
                ></div>
                {/* Heading */}
                <div className="flex items-center justify-between px-5 mb-5">
                  <AuxiliaryText variant={"FieldLabel"}>
                    Notifications
                  </AuxiliaryText>
                  <Link to={"/business-dashboard/inbox/notifications"}>
                    <InteractiveText
                      variant={"ActionLink"}
                      className={"text-primaryblue"}
                    >
                      View all
                    </InteractiveText>
                  </Link>
                </div>

                {/* Notifications list */}
                <div className="border-t border-t-lightgray mx-5">
                  <div className="border-b border-b-lightgray py-3 relative">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-x-2">
                        <img
                          src={Avtar}
                          className="w-6 h-6 rounded-full object-cover"
                        />
                        <AuxiliaryText variant={"MenuDropdown"} >
                          Trades poster
                        </AuxiliaryText>
                      </div>
                      <div className="flex items-center gap-2">
                        <i className="fi fi-ss-bullet text-base text-primaryblue pt-1"></i>
                        <SpecialText
                          variant={"Date"}
                          className={"text-mediumgray"}
                        >
                          1 minute ago
                        </SpecialText>
                      </div>
                    </div>
                    <AuxiliaryText
                      variant={"SubMenuDropdown"}
                      className={"h-[20px] overflow-hidden text-ellipsis mt-2"}
                    >
                      Ensure seamless transactions and keep your business
                      running smoothly with our straightforward billing section.
                      Add/ remove payment methods, track and maintain record of
                      all your pricing plans transactions with Trades Poster
                    </AuxiliaryText>
                  </div>
                  <div className="border-b border-b-lightgray py-3 relative">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-x-2">
                        <img
                          src={Avtar}
                          className="w-6 h-6 rounded-full object-cover"
                        />
                       <AuxiliaryText variant={"MenuDropdown"} >
                          Trades poster
                        </AuxiliaryText>
                      </div>
                      <div className="flex items-center gap-2">
                        <i className="fi fi-ss-bullet text-base text-primaryblue pt-1"></i>
                        <SpecialText
                          variant={"Date"}
                          className={"text-mediumgray"}
                        >
                          1 minute ago
                        </SpecialText>
                      </div>
                    </div>
                    <AuxiliaryText 
                      variant={"SubMenuDropdown"}
                      className={"h-[20px] overflow-hidden text-ellipsis mt-2"}
                    >
                      Ensure seamless transactions and keep your business
                      running smoothly with our straightforward billing section.
                      Add/ remove payment methods, track and maintain record of
                      all your pricing plans transactions with Trades Poster
                    </AuxiliaryText>
                  </div>
                  <div className="border-b border-b-lightgray py-3 relative">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-x-2">
                        <img
                          src={Avtar}
                          className="w-6 h-6 rounded-full object-cover"
                        />
                       <AuxiliaryText variant={"MenuDropdown"} >
                          Trades poster
                        </AuxiliaryText>
                      </div>
                      <div className="flex items-center gap-2">
                        <i className="fi fi-ss-bullet text-base text-primaryblue pt-1"></i>
                        <SpecialText
                          variant={"Date"}
                          className={"text-mediumgray"}
                        >
                          1 minute ago
                        </SpecialText>
                      </div>
                    </div>
                    <AuxiliaryText
                      variant={"SubMenuDropdown"}
                      className={"h-[20px] overflow-hidden text-ellipsis mt-2"}
                    >
                      Ensure seamless transactions and keep your business
                      running smoothly with our straightforward billing section.
                      Add/ remove payment methods, track and maintain record of
                      all your pricing plans transactions with Trades Poster
                    </AuxiliaryText>
                  </div>
                  <div className="border-b border-b-lightgray py-3 relative">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-x-2">
                        <img
                          src={Avtar}
                          className="w-6 h-6 rounded-full object-cover"
                        />
                      <AuxiliaryText variant={"MenuDropdown"} >
                          Trades poster
                        </AuxiliaryText>
                      </div>
                      <div className="flex items-center gap-2">
                        <i className="fi fi-ss-bullet text-base text-primaryblue pt-1"></i>
                        <SpecialText
                          variant={"Date"}
                          className={"text-mediumgray"}
                        >
                          1 minute ago
                        </SpecialText>
                      </div>
                    </div>
                    <AuxiliaryText 
                      variant={"SubMenuDropdown"}
                      className={"h-[20px] overflow-hidden text-ellipsis mt-2"}
                    >
                      Ensure seamless transactions and keep your business
                      running smoothly with our straightforward billing section.
                      Add/ remove payment methods, track and maintain record of
                      all your pricing plans transactions with Trades Poster
                    </AuxiliaryText>
                  </div>
                </div>
              </div>
            )}
          </li>
          <li className="cursor-pointer hover:bg-transparent hidden lg:block">
            <Link to={"/business-dashboard/inbox/chats"}>
              <i className="fi fi-rs-comment-alt-dots size-4 "></i>
            </Link>
          </li>
        </ul>
      )}

      <div className="flex items-center justify-center lg:hidden w-full">
        {!auth?.user ? (
          <>
            <div className="grid grid-cols-1">
              <AuthButtons
                variant="mobile"
                onSignIn={() => setSignIn(!SignIn)}
                onSignUp={() => {
                  toggleDrawer();
                  setSignUpState(!SignUpState);
                }}
                onRequestQuote={() =>{
                //   toggleDrawer();
                //   setSignUpState(!SignUpState);
                } }
              />
            </div>
          </>
        ) : (
          <div className="pt-4 pb-3 w-full flex flex-col justify-center">
            <div className="flex items-center justify-center">
              <div className="flex-shrink-0">
                <img
                  className="h-12 w-12 rounded-full"
                  src={Avtar}
                  alt="Tradeposter User"
                />
              </div>
            </div>
            <div className="mt-3 space-y-1 flex flex-col items-center">
              <Link
                to={`/poster-profile?userId=${userId}`}
                onClick={() => toggleDrawer()}
                className="block rounded-md py-2  hover:text-primaryblue  ease-in-out px-2"
              >
                <AuxiliaryText variant={"MenuDropdown"}>
                  Profile overview
                </AuxiliaryText>
              </Link>
              <Link
                to={`/business-dashboard/dashboard?userId=${userId}`}
                onClick={() => toggleDrawer()}
                className="block rounded-md py-2  hover:text-primaryblue  ease-in-out px-2"
              >
                <AuxiliaryText variant={"MenuDropdown"}>
                  Business dashboard
                </AuxiliaryText>
              </Link>
              <button
                onClick={() => {
                  setPostingOpen(!postingOpen);
                  setSubsOpen(false);
                  setListOpen(false);
                  setInboxOpen(false);
                  setHelpOpen(false);
                }}
                className="flex justify-between px-2 py-2 hover:text-primaryblue  ease-in-out w-[180px] my-1"
              >
                <AuxiliaryText variant={"MenuDropdown"}>Post</AuxiliaryText>
                <i className="fi fi-rs-angle-down text-sm text-offblack"></i>
              </button>
              {postingOpen && (
                <>
                  <ul className="text-center px-5">
                    <li>
                      <Link
                        to={`/business-dashboard/posts/create-post?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setPostingOpen(!postingOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Create post
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/business-dashboard/posts/drafts?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setPostingOpen(!postingOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Drafts
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/business-dashboard/posts/published?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setPostingOpen(!postingOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Published
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/business-dashboard/posts/bin?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setPostingOpen(!postingOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Bin
                        </AuxiliaryText>
                      </Link>
                    </li>
                  </ul>
                </>
              )}

              <button
                onClick={() => {
                  setSubsOpen(!subsOpen);
                  setListOpen(false);
                  setInboxOpen(false);
                  setHelpOpen(false);
                  setPostingOpen(false);
                }}
                className="flex justify-between px-2 py-2 hover:text-primaryblue  ease-in-out w-[180px] my-1"
              >
                <AuxiliaryText variant={"MenuDropdown"}>
                  Subscription
                </AuxiliaryText>
                <i className="fi fi-rs-angle-down text-sm text-offblack"></i>
              </button>
              {subsOpen && (
                <>
                  <ul className="text-center px-5">
                    <li>
                      <Link
                        to={`/business-dashboard/subscriptions/pricing?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setSubsOpen(!subsOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Pricing
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/business-dashboard/subscriptions/billing-details?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setSubsOpen(!subsOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Billing
                        </AuxiliaryText>
                      </Link>
                    </li>
                  </ul>
                </>
              )}

              <button
                onClick={() => {
                  setListOpen(!listOpen);
                  setInboxOpen(false);
                  setHelpOpen(false);
                  setSubsOpen(false);
                  setPostingOpen(false);
                }}
                className="flex justify-between px-2 py-2 hover:text-primaryblue  ease-in-out w-[180px] my-1"
              >
                <AuxiliaryText variant={"MenuDropdown"}>Settings</AuxiliaryText>
                <i className="fi fi-rs-angle-down text-sm text-offblack"></i>
              </button>
              {listOpen && (
                <>
                  <ul className="text-center px-5">
                    <li>
                      <Link
                        to={`/business-dashboard/settings/account?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setListOpen(!listOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Account
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/business-dashboard/settings/manage-portfolio?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setListOpen(!listOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Portfolio
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/business-dashboard/settings/certifications?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setListOpen(!listOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Certifications
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/business-dashboard/settings/associations?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setListOpen(!listOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Associations
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/business-dashboard/settings/social-profiles?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setListOpen(!listOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Social Media
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/business-dashboard/settings/business-contact?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setListOpen(!listOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Business contact
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/business-dashboard/settings/trading-hours?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setListOpen(!listOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Trading hours
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/business-dashboard/settings/payment-options?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setListOpen(!listOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Payment Method
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/business-dashboard/settings/notification-preferences?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setListOpen(!listOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Notifications
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/business-dashboard/settings/reset-password?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setListOpen(!listOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Reset password
                        </AuxiliaryText>
                      </Link>
                    </li>
                  </ul>
                </>
              )}
              <button
                onClick={() => {
                  setInboxOpen(!inboxOpen);
                  setHelpOpen(false);
                  setListOpen(false);
                  setSubsOpen(false);
                  setPostingOpen(false);
                }}
                className="flex justify-between px-2 py-2 hover:text-primaryblue  ease-in-out w-[180px] my-1"
              >
                <AuxiliaryText variant={"MenuDropdown"}>Inbox</AuxiliaryText>
                <i className="fi fi-rs-angle-down text-sm text-offblack"></i>
              </button>
              {inboxOpen && (
                <>
                  <ul className="text-center px-5">
                    <li>
                      <Link
                        to={`/business-dashboard/inbox/chats?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setInboxOpen(!inboxOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Messages
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/business-dashboard/inbox/notifications?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setInboxOpen(!inboxOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Notifications
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/business-dashboard/inbox/reviews?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setInboxOpen(!inboxOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Reviews
                        </AuxiliaryText>
                      </Link>
                    </li>
                  </ul>
                </>
              )}
              <button
                onClick={() => {
                  setHelpOpen(!helpOpen);
                  setInboxOpen(false);
                  setListOpen(false);
                  setSubsOpen(false);
                  setPostingOpen(false);
                }}
                className="flex justify-between px-2 py-2 hover:text-primaryblue  ease-in-out w-[180px] my-1"
              >
                <AuxiliaryText variant={"MenuDropdown"}>
                  Support
                </AuxiliaryText>
                <i className="fi fi-rs-angle-down text-sm text-offblack"></i>
              </button>
              {helpOpen && (
                <>
                  <ul className="text-center px-5">
                    <li>
                      <Link
                        to={`/AboutUs?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setHelpOpen(!helpOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          About
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/contact?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setHelpOpen(!helpOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Contact
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/Blogs?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setHelpOpen(!helpOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Blog
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/HelpCentre`}
                        onClick={() => {
                          toggleDrawer();
                          setHelpOpen(!helpOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Help centre
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/TermsConditions?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setHelpOpen(!helpOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Terms & Conditions
                        </AuxiliaryText>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/PrivacyPolicy?userId=${userId}`}
                        onClick={() => {
                          toggleDrawer();
                          setHelpOpen(!helpOpen);
                        }}
                        className="block px-2 py-2 hover:text-primaryblue  ease-in-out w-full my-1"
                      >
                        <AuxiliaryText variant={"SubMenuDropdown"}>
                          Privacy Policy
                        </AuxiliaryText>
                      </Link>
                    </li>
                  </ul>
                </>
              )}
              <Link
                to="/"
                onClick={() => {
                  setAuth({ user: null, roles: null, accessToken: "" });
                  localStorage.removeItem("LoginSession");
                  toggleDrawer();
                }}
                className="block rounded-md py-2  hover:text-primaryblue  ease-in-out px-2"
              >
                <AuxiliaryText variant={"MenuDropdown"}>Sign out</AuxiliaryText>
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
// <div className="w-8 h-8 bg-pink rounded-full mr-4 flex justify-center items-end">
//   <IconContext.Provider value={{ className: `text-xl mb-1` }}>
//     <User
//       onClick={() =>
//         setAuth({ user: null, roles: null, accessToken: "" })
//       }
//     />
//   </IconContext.Provider>
// </div>
