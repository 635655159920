import React from 'react';
import InteractiveText from "../Typography/InteractiveText";

const StepBackButton = ({ onClick, className = "" }) => {
  return (
    <div onClick={onClick}>
        <InteractiveText
            variant={"ActionLink"}
            className={`text-mediumgray hover:text-primaryblue hover:cursor-pointer ${className}`}
        >
            Back
        </InteractiveText>
    </div>
  );
};

export default StepBackButton; 