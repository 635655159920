import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Alerts = {
    success: (message) => {
        toast.success(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "bg-white",
            bodyClassName: "text-sm font-medium",
            icon: (
                <div className="flex items-center justify-center w-6 h-6 rounded-full bg-green-100">
                    <i className="fi fi-rs-check text-xs text-green-500"></i>
                </div>
            ),
            style: {
                border: '1px solid #E8E8E8',
                borderLeft: '4px solid #22C55E',
            },
        });
    },

    warning: (message) => {
        toast.warning(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "bg-white",
            bodyClassName: "text-sm font-medium",
            icon: (
                <div className="flex items-center justify-center w-6 h-6 rounded-full bg-yellow-100">
                    <i className="fi fi-rs-exclamation text-xs text-yellow-500"></i>
                </div>
            ),
            style: {
                border: '1px solid #E8E8E8',
                borderLeft: '4px solid #EAB308',
            },
        });
    },

    error: (message) => {
        toast.error(message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: "bg-white",
            bodyClassName: "text-sm font-medium",
            icon: (
                <div className="flex items-center justify-center w-6 h-6 rounded-full bg-red-100">
                    <i className="fi fi-rs-cross text-xs text-red-500"></i>
                </div>
            ),
            style: {
                border: '1px solid #E8E8E8',
                borderLeft: '4px solid #EF4444',
            },
        });
    },
};

export default Alerts; 