// Get Service Categories

export const GetServiceCategoriesQuery = async (graphqlService) => {
    try {
        const GetServiceCategories_QUERY = `
            query GetServiceCategories($filters: ServiceCategoryFilterInput) {
                serviceCategories(filters: $filters) {
                    categories {
                    id
                    name
                    description
                    }
                    totalCount
                }
            }
        `;
        const response = await graphqlService.fetchData(GetServiceCategories_QUERY);
        return response;
    } catch (error) {
        console.error("Query Error:", error);
        throw error;
    }
};