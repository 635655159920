import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../Hooks/useAuth";

/**
 * RequireAuth - A component that handles route protection based on user roles
 * 
 * @param {Object} props
 * @param {Array} props.allowedRoles - Array of roles that are allowed to access the route
 * @returns {JSX.Element} Protected route content or redirect
 */
const RequireAuth = ({ allowedRoles }) => {
  const { auth } = useAuth();
  const location = useLocation();

  // Check if user has required role
  const hasRequiredRole = auth?.roleType && allowedRoles.includes(auth.roleType);

  if (hasRequiredRole) {
    return <Outlet />;
  }

  // If user is authenticated but doesn't have required role
  if (auth?.accessToken) {
    return (
      <Navigate 
        to="/unauthorized" 
        state={{ from: location }} 
        replace 
      />
    );
  }
console.log("auth", auth)
  // If user is not authenticated
  return (
    <Navigate 
      to="/Login" 
      state={{ from: location }} 
      replace 
    />
  );
};

export default RequireAuth;
