import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { Field, ErrorMessage } from "formik";
import Snippet from "../../../../components/common/SnippetIcon/Snippet";
import InteractiveText from "../../../../components/common/Typography/InteractiveText";
import MainButton from "../../../../components/common/MainButton/MainButton";
import { LatestData } from "./Dataset";
import SpecialText from "../../../../components/common/Typography/SpecialText";
import { fetchServiceCategories } from "../../../../Redux/Slice/serviceCategoriesSlice";
import { useGraphQL } from "../../../../context/GraphQLProvider";
import TextField from "../../../../components/common/InputField/TextFiled";
import { updatePostData } from "../../../../Redux/Slice/createPostSlice";

const DefineCategory = ({ values, setFieldValue, setStep }) => {
  const dispatch = useDispatch();
  const graphqlService = useGraphQL();
  const { categories } = useSelector((state) => state.serviceCategories);
  const { serviceCategory } = useSelector((state) => state.user);

  // Fetch categories if not available
  useEffect(() => {
    if (categories.length === 0) {
      dispatch(fetchServiceCategories(graphqlService));
    }
  }, [dispatch, graphqlService, categories.length]);

  // Set default category based on user's serviceCategory
  useEffect(() => {
    if (categories.length > 0 && serviceCategory && !values.mainCategory) {
      const userCategory = categories.find(cat => cat.id === serviceCategory.id);
      if (userCategory) {
        setFieldValue('mainCategory', userCategory);
      }
      console.log(userCategory);
    }
  }, [categories, serviceCategory, setFieldValue, values.mainCategory]);

  const subListing = LatestData.find(
    (item) => item.primaryCategory === values.mainCategory.name
  );

  // Add this function to get dropdown options with custom option
  const getSubcategoryOptions = () => {
    const baseOptions = subListing ? subListing.subList : [];
    return [...baseOptions, { name: 'Custom Sub Category', id: 'custom' }];
  };

  const handleSubcategoryChange = (e) => {
    const selectedValue = e.value;
    
    // Clear any existing custom value when changing selection
    if (selectedValue.id !== 'custom') {
      setFieldValue('customSubcategory', '');
    }
    
    // setFieldValue("subCategory", selectedValue);

    // Only set tags for non-custom selections
    if (selectedValue.id !== 'custom' && subListing) {
      const selectedSubcategory = subListing.subList.find(
        (sub) => sub.name === selectedValue.name
      );
      if (selectedSubcategory) {
        setFieldValue("postTags", selectedSubcategory.keywords || []);
      }
    }

    setFieldValue("subCategory", selectedValue)
  };

  const handleNextClick = () => {
    if (values.mainCategory && values.subCategory && 
        (values.subCategory.id !== 'custom' || values.customSubcategory)) {
      // Update Redux store
      dispatch(updatePostData({
        mainCategory: values.mainCategory,
        subCategory: values.subCategory,
        customSubcategory: values.customSubcategory,
        postTags: values.postTags
      }));
      setStep(2);
    }
  };

  return (
    <div className="w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto mt-[60px]">
      <div>
        <div className="flex">
          <Snippet
            variant={"label"}
            label={"Primary Post Category"}
            // text={
            //   "Select your primary post category from the available options."
            // }
            star={true}
            // snippet={true}
          />
        </div>

        {/* Dropdown for Primary Category */}
        <Field name="mainCategory">
          {({ field, form }) => (
            <>
              <div className="custom-dropdown">
                <Dropdown
                  {...field}
                  value={values.mainCategory}
                  onChange={(e) => setFieldValue("mainCategory", e.value)}
                  options={categories}
                  optionLabel="name"
                  placeholder="Post primary category"
                  disabled={true}
                  panelClassName="custom-dropdown-panel"
                  className="custom-dropdown-field p-dropdown-trigger-custom text-offblack flex items-center font-inter font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px]"
                />
                {/* <i className="fi fi-rs-angle-down text-xs lg:text-sm text-offblack cursor-not-allowed custom-dropdown-icon"></i> */}
              </div>
              {form.touched.mainCategory && (
                <SpecialText
                  variant={"FootNoteDisclaimer"}
                  className="error-message text-alertred mt-2"
                >
                  {form.errors.mainCategory}
                </SpecialText>
              )}
            </>
          )}
        </Field>
      </div>

      <div className="mt-5">
        <div className="flex">
          <Snippet
            variant={"label"}
            label={"Select Post Sub Category"}
            text={"Select your post sub category from the available options."}
            star={true}
            snippet={true}
          />
        </div>

        {/* Dropdown for Sub Category */}
        <Field name="subCategory">
          {({ field, form }) => (
            <>
              <div className="custom-dropdown">
                <Dropdown
                  {...field}
                  value={values.subCategory}
                  onChange={handleSubcategoryChange}
                  options={getSubcategoryOptions()}
                  optionLabel="name"
                  placeholder="Post sub category"
                  panelClassName="custom-dropdown-panel"
                  className="custom-dropdown-field p-dropdown-trigger-custom text-offblack flex items-center font-inter font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px]"
                />
                <i className="fi fi-rs-angle-down text-xs lg:text-sm text-offblack hover:cursor-pointer custom-dropdown-icon"></i>
              </div>

              {/* Show input field when Custom Sub Category is selected */}
              {values.subCategory?.id === 'custom' && (
                <div className="mt-3">
                  <Field name="customSubcategory">
                    {({ field, form }) => (
                      <>
                        <TextField
                          {...field}
                          className={"lg:bg-pastalblue"}
                          variant={"large"}
                          value={field.value || ''}
                          placeholder="Enter custom sub category (25-40 characters)"
                        />
                        {form.touched.customSubcategory && form.errors.customSubcategory && (
                          <SpecialText
                            variant={"FootNoteDisclaimer"}
                            className="error-message text-alertred mt-2"
                          >
                            {form.errors.customSubcategory}
                          </SpecialText>
                        )}
                        {/* <SpecialText
                          variant={"FootNoteDisclaimer"}
                          className="text-mediumgray mt-1"
                        >
                          Use 25-40 characters with properly spelled words to describe your service category
                        </SpecialText> */}
                      </>
                    )}
                  </Field>
                </div>
              )}

              {form.touched.subCategory && form.errors.subCategory ? (
                <SpecialText
                  variant={"FootNoteDisclaimer"}
                  className="error-message text-alertred mt-2"
                >
                  {form.errors.subCategory}
                </SpecialText>
              ) : null}
            </>
          )}
        </Field>
      </div>

      <div className="flex items-center justify-end w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mt-10">
        {/* <div onClick={() => setStep(1)}>
          <InteractiveText
            variant={"ActionLink"}
            className="text-mediumgray hover:text-primaryblue cursor-pointer"
          >
            Back
          </InteractiveText>
        </div> */}
        <div>
          <MainButton
            type="button"
            onClick={handleNextClick}
            variant={"small"}
            disabled={
              !values.mainCategory || 
              !values.subCategory || 
              (values.subCategory.id === 'custom' && 
                (!values.customSubcategory || 
                 values.customSubcategory.length < 25 || 
                 values.customSubcategory.length > 40))
            }
          >
            Next
          </MainButton>
        </div>
      </div>
    </div>
  );
};

export default DefineCategory;
