import MyTestComponent from "../components/MyTestComponent";

const MyTestPage = () => {
  return (
    <>
      <MyTestComponent />
    </>
  );
};

export default MyTestPage;
