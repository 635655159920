import { createSlice } from "@reduxjs/toolkit";
import {InitialValues} from "../../FormValidations/SignUpSchema";

const initialState = {
  id: null,
  email: "",
  roleType: "",
  password: "",
  verificationCode: "",
  firstName: "",
  lastName: "",
  abn: "",
  businessName: "",
  companyName: "",
  location: "",
  startDate: "",
  profilePicture: null,
  serviceCategoryId: null,
  step: 1,
};

const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    setField: (state, action) => {
      return { ...state, ...action.payload };
    },
    setStep: (state, action) => {
      state.step = action.payload;
    },
    resetSignUp: () => initialState,
  },
});

export const { setField, setStep, resetSignUp } = signupSlice.actions;
export default signupSlice.reducer;