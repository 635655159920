import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {useDropzone} from 'react-dropzone';
import { useFormik } from "formik";
import Title from "../../../components/common/Title/Title";
import TextFiled from "../../../components/common/InputField/TextFiled";
import MainButton from "../../../components/common/MainButton/MainButton";
import ButtonText from "../../../components/common/Typography/ButtonText";
import Heading from "../../../components/common/Typography/Heading";
import Paragraph from "../../../components/common/Typography/Paragraph";
import AuxiliaryText from "../../../components/common/Typography/AuxiliaryText";
import Spinner from "../../../components/common/Spinner";
import InteractiveText from "../../../components/common/Typography/InteractiveText";
import SpecialText from "../../../components/common/Typography/SpecialText";
import { setField, setStep } from "../../../Redux/Slice/SignUp";
import { setFile, setError, clearFile } from "../../../Redux/Slice/FileUpload";
import { LocationSchema } from "../../../FormValidations/SignUpSchema";
import { UpdateUserMutation } from "../../../services/SignUp";
import { useGraphQL } from "../../../context/GraphQLProvider";
import ImageCropper from "../../../components/common/ImageCropper/ImageCropper";
import Modal from "../../../components/common/Modal/Modal";
import DropzoneUploader from "../../../components/common/DropzoneUploader/DropzoneUploader";
import Alerts from "../../../components/common/Alerts/Alerts";
import { transformToUserInput } from "../../../utils/userInputUtils";
import StepBackButton from "../../../components/common/StepBackButton/StepBackButton";

const AddProfilePicture = ({ onClickHandler }) => {
    const graphqlService = useGraphQL();
    const [RequestStart, setRequetStart] = useState(false);
    const dispatch = useDispatch();

    // const { originalFile, croppedFile, error } = useSelector((state) => state.files);
    const [showCropper, setShowCropper] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const InitialValues = useSelector((state) => state.signup);
    const { id, profilePicture, step } = useSelector((state) => state.signup);

    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);

    // const handleFileInputChange = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         setSelectedFile(file);

    //         // Generate a preview URL for the selected image
    //         const reader = new FileReader();
    //         reader.onload = () => {
    //             setImagePreview(reader.result);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // };

    // const handleUpload = async () => {
    //     if (!croppedFile) {
    //         dispatch(setError("Please crop and confirm the image before uploading"));
    //         return;
    //     }
    
    //     const formData = new FormData();
    //     formData.append("file", croppedFile);
    
    //     try {
    //         const response = await fetch("https://your-api-endpoint.com/upload", {
    //             method: "POST",
    //             body: formData,
    //         });
    
    //         if (!response.ok) throw new Error("Upload failed");
    
    //         alert("File uploaded successfully!");
    //         dispatch(clearFile());
    //         setIsModalOpen(false);
    //     } catch (err) {
    //         dispatch(setError("Upload error: " + err.message));
    //     }
    // };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setShowCropper(false);
        // dispatch(clearFile());
    };

    const handleUploadComplete = (fileDetails) => {
        dispatch(setField({profilePicture: fileDetails.url}));
        setRequetStart(true);
        const userInput = transformToUserInput(InitialValues);
        userInput.userInput.profilePicture = fileDetails.url;

        UpdateUserMutation(graphqlService, userInput)
            .then((response) => {
                console.log(response);
                if (response.updateUserProfile?.profilePicture === fileDetails.url) {
                    // dispatch(setField({profilePicture: fileDetails.url}));
                    setSelectedFile(fileDetails.file);
                    setIsModalOpen(false);
                }
            })
            .catch((error) => {
                Alerts.error(error);
            })
            .finally(() => {
                setRequetStart(false);
            });
    };

    return (
        <>
            {RequestStart && <Spinner />}
            <Heading
                variant={"h6"}
                className={
                "flex items-center justify-center text-center mt-[60px] xl:mt-[120px] text-offblack"
                }
            >
                Add profile picture
            </Heading>

            <div className="w-[320px] sm:w-[380px] md:w-[400px] mx-auto mt-10 xl:mt-[60px]">
                <div className="relative size-[192px] mx-auto">
                    <div className="size-[192px]">
                    {profilePicture && (
                        <img
                        className="object-center rounded-full w-full h-full cursor-pointer"
                        src={imagePreview || profilePicture}
                        alt={"custProfile"}
                        />
                    )}
                    {!profilePicture && (
                        <div className="object-center rounded-full w-full h-full cursor-pointer bg-white" />
                    )}
                    </div>
                    <div className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <div
                        className={`flex justify-center items-center rounded-full size-[30px] pt-[2px] relative ${
                        profilePicture && "bg-white"
                        }`}
                    >
                        <div>
                        <label
                            role="button"
                            htmlFor="custPicture"
                            className="flex justify-center bg-transparent"
                            onClick={() => setIsModalOpen(true)}
                        >
                            {!profilePicture ? (
                            <Title text={"Upload"} width={"50"}>
                                <i className="fi fi-rs-upload text-base text-mediumgray hover:text-primaryblue"></i>
                            </Title>
                            ) : (
                            <Title text={"Edit"} width={"50"}>
                                <i className="fi fi-rs-pen-circle text-sm text-mediumgray hover:text-primaryblue"></i>
                            </Title>
                            )}
                        </label>
                        </div>
                    </div>
                    </div>
                </div>

                <div className="flex items-center justify-between mt-10 w-full">
                    <StepBackButton onClick={() => dispatch(setStep(step - 1))} />
                    {/* <div onClick={onClickHandler}>
                        <ButtonText
                            variant={"SecondaryText"}
                            className={"hover:text-primaryblue hover:cursor-pointer"}
                        >
                            Skip for now
                        </ButtonText>
                    </div> */}
                    <MainButton
                        variant={"extrasmall"}
                        onClick={onClickHandler}
                    >
                        Next
                    </MainButton>
                </div>
            </div>

            <Modal 
                isOpen={isModalOpen} 
                onClose={handleModalClose}
            >
                <DropzoneUploader
                    isImage={true}
                    enableCropper={true}
                    acceptedFileTypes={{ "image/jpeg": [], "image/png": [] }}
                    maxFileSize={1 * 1024 * 1024}
                    onUploadComplete={handleUploadComplete}
                />
            </Modal>
        </>
    );
};

export default AddProfilePicture;
