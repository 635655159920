import React from "react";

const AuxiliaryText = ({ variant, children, className }) => {
  return (
    <>
      {/* o	Usage: Labelling top-rated tags or other elements within the infographic. */}
      {variant === "Infographic" && (
        <p
          className={`
          ${className} font-inter font-normal uppercase leading-[1.4] tracking-[0px] text-[11px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px]`}
        >
          {children}
        </p>
      )}
      {/* o	Usage: Pro tags only*/}
      {variant === "Protag" && (
        <p
          className={`
          ${className} font-inter font-bold uppercase leading-[1.4] tracking-[0px] text-[10px] sm:text-[10px] md:text-[11px] lg:text-[11px] xl:text-[11px] 2xl:text-[12px] 3xl:text-[12px]`}
        >
          {children}
        </p>
      )}
      {/* o	Usage:Navigation Text*/}
      {variant === "NavigationText" && (
        <p
          className={`
          ${className} font-inter font-bold uppercase leading-[1.4] tracking-[0.5px] text-[14px] sm:text-[14px] md:text-[15px] lg:text-[15px] xl:text-[15px] 2xl:text-[16px] 3xl:text-[16px]`}
        >
          {children}
        </p>
      )}
      {/* o	Usage: Menu items, dropdown lists •	Navigation Text (Menu, Dropdowns):*/}
      {variant === "MenuDropdown" && (
        <p
          className={`
          ${className} font-inter font-normal capitalize leading-[1.4] tracking-[0.5px] text-[13px] sm:text-[13px] md:text-[14px] lg:text-[14px] xl:text-[14px] 2xl:text-[15px] 3xl:text-[15px]`}
        >
          {children}
        </p>
      )}

      {/* o	Usage: Menu items, dropdown lists •	Navigation Text (Submenu Text): */}
      {variant === "SubMenuDropdown" && (
        <p
          className={`
          ${className} font-inter font-normal capitalize leading-[1.4] tracking-[0px] text-[12px] sm:text-[12px] md:text-[13px] lg:text-[13px] xl:text-[13px] 2xl:text-[14px] 3xl:text-[14px]`}
        >
          {children}
        </p>
      )}
      {/* o	Usage: Placeholder text for labels above input fields */}
      {variant === "FieldLabel" && (
        <p
          className={`
          ${className} font-inter font-normal leading-[1.4] tracking-[0px] text-[12px] sm:text-[12px] md:text-[13px] lg:text-[13px] xl:text-[13px] 2xl:text-[14px] 3xl:text-[14px]`}
        >
          {children}
        </p>
      )}
      {variant === "Placeholder" && (
        <p
          className={`
          ${className} font-inter font-normal leading-[1.4] tracking-[0px] text-[11px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px]`}
        >
          {children}
        </p>
      )}

      {/* o	Usage: Status indicators like online, busy, etc */}
      {variant === "StatusIndicator" && (
        <p
          className={`
          ${className} font-inter font-normal leading-[1.4] tracking-[0px] text-[9px] sm:text-[10px] md:text-[10px] lg:text-[11px] xl:text-[11px] 2xl:text-[12px]  `}
        >
          {children}
        </p>
      )}
    </>
  );
};

export default AuxiliaryText;
