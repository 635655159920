export const ResetPasswordMutation = async (graphqlService, variables) => {
    const RESET_PASSWORD_MUTATION = `
        mutation ResetPassword($token: String!, $newPassword: String!) {
            resetPassword(token: $token, newPassword: $newPassword) {
                success
                message
            }
        }
    `;
    try {
        const response = await graphqlService.mutateData(RESET_PASSWORD_MUTATION, variables);
        return response;
    } catch (error) {
        console.error("Mutation Error:", error);
        throw error;
    }
};