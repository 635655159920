import React, { useState } from "react";
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from "react-redux";
import { setFile, setError, clearFile } from "../../../Redux/Slice/FileUpload";
import { setField } from "../../../Redux/Slice/SignUp";
import ImageCropper from "../ImageCropper/ImageCropper";
import { useGraphQL } from '../../../context/GraphQLProvider';
import { FileUploadQuery } from "../../../services/FileUploadService";
import Alerts from "../Alerts/Alerts";
import CircularProgress from "../CircularProgress/CircularProgress";
import { gql } from '@apollo/client';

const DropzoneUploader = ({ 
    acceptedFileTypes = { "image/jpeg": [], "image/png": [] },
    maxFileSize = 1 * 1024 * 1024,
    isImage = true,
    enableCropper = false,
    onUploadComplete,
}) => {
    const dispatch = useDispatch();
    const graphqlService = useGraphQL();
    const { originalFile, croppedFile, error } = useSelector((state) => state.files);
    const [showCropper, setShowCropper] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);

    const handleFileProcessed = async (fileOrBlobUrl) => {
        if (!fileOrBlobUrl) {
            dispatch(setError("No file selected"));
            return;
        }

        try {
            setIsUploading(true);
            setUploadProgress(0);
            
            let file;
            if (isImage && enableCropper) {
                const response = await fetch(fileOrBlobUrl);
                const blob = await response.blob();
                file = new File([blob], 'Profile-image.jpg', { type: blob.type });
            } else {
                file = fileOrBlobUrl;
            }

            // Use FileUploadQuery service with progress tracking
            const uploadResponse = await FileUploadQuery(graphqlService, 
                { file }, 
                {
                    context: {
                        fetchOptions: {
                            onProgress: (progress) => {
                                const percentage = (progress.loaded / progress.total) * 100;
                                setUploadProgress(percentage);
                            },
                        },
                    }
                }
            );

            if (uploadResponse.uploadFile.success) {
                dispatch(setField({profilePicture: uploadResponse.uploadFile.url}));
                setUploadProgress(100);
                setTimeout(() => {
                    setIsUploading(false);
                    // Alerts.success("File uploaded successfully!");
                    onUploadComplete({
                        file: file,
                        url: uploadResponse.uploadFile.url,
                        fileName: uploadResponse.uploadFile.fileName,
                    });
                }, 500);
            } else {
                setIsUploading(false);
                Alerts.error(`Upload failed: ${uploadResponse.uploadFile.error}`);
            }
        } catch (error) {
            setIsUploading(false);
            Alerts.error(`Upload error: ${error.message}`);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({
        accept: acceptedFileTypes,
        maxSize: maxFileSize,
        onDrop: (acceptedFiles, rejectedFiles) => {
            if (rejectedFiles.length > 0) {
                const errorMessage = rejectedFiles[0]?.errors?.[0]?.message || "File upload failed";
                dispatch(setError(errorMessage));
                dispatch(clearFile());
                return;
            }

            const file = acceptedFiles[0];
            console.log(file);

            if (isImage && enableCropper) {
                const objectUrl = URL.createObjectURL(file);
                dispatch(setFile(objectUrl));
                setShowCropper(true);
                dispatch(setError(null));
            } else {
                handleFileProcessed(file);
            }
        },
    });

    const renderDropzone = () => (
        <div
            {...getRootProps()}
            className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer hover:border-primaryblue"
        >
            <input {...getInputProps()} />
            <p className="text-gray-600">
                Drag & drop {isImage ? 'an image' : 'a file'} here, or click to select
            </p>
            <p className="text-xs text-gray-500 mt-2">
                {`Maximum file size: ${maxFileSize / (1024 * 1024)}MB`}
            </p>
        </div>
    );

    const renderContent = () => {
        if (error) {
            return <p className="text-red-500 mt-2">{error}</p>;
        }

        if (isUploading) {
            return (
                <div className="flex flex-col items-center justify-center p-6">
                    <CircularProgress progress={uploadProgress} />
                    <p className="mt-4 text-gray-600">Uploading file...</p>
                </div>
            );
        }

        if (showCropper && isImage && enableCropper) {
            return (
                <ImageCropper 
                    onComplete={(croppedFileBlobURL) => {
                        setShowCropper(false);
                        handleFileProcessed(croppedFileBlobURL);
                    }} 
                />
            );
        }

        return renderDropzone();
    };

    return (
        <div className="w-full">
            {renderContent()}
        </div>
    );
};

export default DropzoneUploader; 