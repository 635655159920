import React from 'react';

const CircularProgress = ({ progress }) => {
    const radius = 45; // Increased from 30
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    return (
        <div className="relative inline-flex items-center justify-center w-40 h-40"> {/* Increased from w-24 h-24 */}
            <svg 
                className="transform -rotate-90 w-full h-full"
                viewBox="0 0 120 120" // Increased from 80 80
            >
                {/* Background circle */}
                <circle
                    className="stroke-gray-200"
                    cx="60" // Increased from 40
                    cy="60" // Increased from 40
                    r={radius}
                    strokeWidth="6" // Reduced from 8
                    fill="none"
                />
                {/* Progress circle */}
                <circle
                    className="stroke-primaryblue transition-[stroke-dashoffset] duration-300 ease-in-out"
                    cx="60" // Increased from 40
                    cy="60" // Increased from 40
                    r={radius}
                    strokeWidth="6" // Reduced from 8
                    fill="none"
                    strokeLinecap="round"
                    style={{
                        strokeDasharray: circumference,
                        strokeDashoffset: strokeDashoffset,
                    }}
                />
            </svg>
            <div className="absolute inset-0 flex items-center justify-center">
                <span className="text-xl font-semibold text-gray-700">
                    {Math.round(progress)}%
                </span>
            </div>
        </div>
    );
};

export default CircularProgress; 