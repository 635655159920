import { configureStore } from '@reduxjs/toolkit';
import signupReducer from './Slice/SignUp';
import userReducer from './Slice/userSlice';
import serviceCategoriesReducer from './Slice/serviceCategoriesSlice';

export const store = configureStore({
  reducer: {
    signup: signupReducer,
    user: userReducer,
    serviceCategories: serviceCategoriesReducer
  },
}); 