export const FileUploadQuery = async (graphqlService, variables, options = {}) => {
    const UPLOAD_FILE_MUTATION = `
            mutation UploadFile($file: Upload!) {
                uploadFile(file: $file) {
                    success
                    url
                    fileName
                    error
                }
            }
        `;
    try {
        const response = await graphqlService.mutateData(UPLOAD_FILE_MUTATION, variables, options);
        return response;
    } catch (error) {
        console.error("Mutation Error:", error);
        throw error;
    }
};