import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import TextFiled from "../../../components/common/InputField/TextFiled";
import MainButton from "../../../components/common/MainButton/MainButton";
import Snippet from "../../../components/common/SnippetIcon/Snippet";
import Heading from "../../../components/common/Typography/Heading";
import { Link } from "react-router-dom";
import InteractiveText from "../../../components/common/Typography/InteractiveText";
import ButtonText from "../../../components/common/Typography/ButtonText";
import Paragraph from "../../../components/common/Typography/Paragraph";
import SpecialText from "../../../components/common/Typography/SpecialText";
import WrittenStepper from "../../../components/common/StepIndicater/WrittenStepper";
import Title from "../../../components/common/Title/Title";
import { Dropdown } from "primereact/dropdown";
import Spinner from "../../../components/common/Spinner";
import Alerts from "../../../components/common/Alerts/Alerts";
import StepBackButton from "../../../components/common/StepBackButton/StepBackButton";

import { GetServiceCategoriesQuery } from "../../../services/ServiceCategories";
import { useGraphQL } from "../../../context/GraphQLProvider";
import { BusinessInformationSchema } from "../../../FormValidations/SignUpSchema";
import { UpdateUserMutation } from "../../../services/SignUp";
import { setField, setStep } from "../../../Redux/Slice/SignUp";
import SelectService from "../SelectService/SelectService";
import Location from "../../SignUp/Location/Location";
import AddProfilePicture from "../../SignUp/AddProfilePicture/AddProfilePicture";
import ProfilePreview from "../ProfilePreview/ProfilePreview";
import { transformToUserInput } from "../../../utils/userInputUtils";

const BusinessInfo = ({ onClickHandler }) => {
  const graphqlService = useGraphQL();
  const [RequestStart, setRequetStart] = useState(false);
  const dispatch = useDispatch();
  const InitialValues = useSelector((state) => state.signup);
  const { id, email, step } = useSelector((state) => state.signup);

  const [mainCategory, setMainCategory] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [servicesNames, setServicesNames] = useState([]);

  const handleStepChange = (newStep) => {
    dispatch(setStep(newStep));
  };

  const GetServiceCategories = () => {
    GetServiceCategoriesQuery(graphqlService)
        .then((response) => {
            setServicesNames(response.serviceCategories.categories);
        })
        .catch((err) => {
            console.error("Error fetching users:", err);
        });
  };

  const UpdateBusinessInformation = (gql, values) => {
    setRequetStart(true);
    dispatch(setField(values));
    const userInput = transformToUserInput(InitialValues);
    userInput.userInput.abn = values.abn;
    userInput.userInput.businessName = values.businessName;
    userInput.userInput.companyName = values.companyName;

    UpdateUserMutation(gql, userInput)
      .then((response) => {
        if (
          response.updateUserProfile?.abn === values.abn &&
          response.updateUserProfile?.businessName === values.businessName &&
          response.updateUserProfile?.companyName === values.companyName
        ) {
          handleStepChange(5);
          setRequetStart(false);
        }
      })
      .catch((error) => {
        Alerts.error(error);
      })
      .finally(() => {
        setRequetStart(false);
      });
  };

  const formik = useFormik({
    initialValues: InitialValues,
    validationSchema: BusinessInformationSchema,
    onSubmit: (values) => {
      UpdateBusinessInformation(graphqlService, values);
    },
  });

  useEffect(() => {
    if(servicesNames.length === 0) {
      GetServiceCategories();
    }
  }, []);

  // const servicesNames = [
  //   { name: "Air conditioning & Heating" },
  //   { name: "Architectural Services" },
  //   { name: "Bricklaying & Blockwork" },
  //   { name: "Cabinet Making" },
  //   { name: "Carpentry" },
  //   { name: "Caulking Services" },
  //   { name: "Cleaning Services" },
  //   { name: "Concrete & Paving" },
  //   { name: "Conveyancing" },
  //   { name: "Decking, Gazebo & Carports" },
  //   { name: "Demolition & Excavation" },
  //   { name: "Electrical Services" },
  //   { name: "Engineering Services" },
  //   { name: "Fencing & Gates" },
  //   { name: "Garden & Landscaping" },
  //   { name: "Glass & Glazing" },
  //   { name: "Handyman Services" },
  //   { name: "Inspections & Surveyors" },
  //   { name: "Insulation Services" },
  //   { name: "Interior Design" },
  //   { name: "Locksmiths" },
  //   { name: "Mould & Asbestos" },
  //   { name: "Moving & Delivery" },
  //   { name: "Painting & Decorating" },
  //   { name: "Pest Control" },
  //   { name: "Plumbing" },
  //   { name: "Pools & Spas" },
  //   { name: "Rendering" },
  //   { name: "Renovation & Restoration" },
  //   { name: "Roofing" },
  //   { name: "Scaffolding" },
  //   { name: "Shopfitting" },
  //   { name: "Skip Bins" },
  //   { name: "Solar Power" },
  //   { name: "Staircase Services" },
  //   { name: "Stonework" },
  //   { name: "Tiling" },
  //   { name: "Waterproofing" },
  // ];

  return (
    <>
      {RequestStart && <Spinner />}
      <div className="w-[320px] sm:w-[380px] md:w-[400px] mx-auto h-[550px] overflow-auto hidden-scrollbar lg:h-[800px] lg:overflow-hidden pb-[550px] lg:pb-0 relative">
        {step === 4 && (
          <>
            <Heading
              variant={"h6"}
              className={
                "flex items-center justify-center text-center mt-[60px] xl:mt-[120px] text-offblack"
              }
            >
              Business information
            </Heading>
            <div className="mt-10 xl:mt-[60px] w-full">
              <form>
                <div className="flex justify-center w-full">
                  <div className="w-full mx-auto">
                    <div>
                      <TextFiled
                        className={"lg:bg-white"}
                        variant={"general"}
                        label={"ABN"}
                        name="abn"
                        value={formik.values.abn}
                        placeholder={
                          "Enter your Australian Business Number (ABN)"
                        }
                        star={true}
                        onChange={formik?.handleChange}
                        error={formik.touched.abn && formik.errors.abn ? formik.errors.abn : null}
                      />
                      <div className="flex items-center justify-end text-primaryblue mt-2">
                        <Link
                          to={"https://abr.business.gov.au/"}
                          target="_blank"
                        >
                          <InteractiveText variant={"ActionLink"}>
                            Look up (ABN)
                          </InteractiveText>
                        </Link>
                      </div>
                    </div>
                    <div>
                      <TextFiled
                        variant={"general"}
                        label={"Business name"}
                        name="businessName"
                        value={formik.values.businessName}
                        placeholder={"Enter your business name"}
                        star={true}
                        onChange={formik?.handleChange}
                        error={formik.touched.businessName && formik.errors.businessName ? formik.errors.businessName : null}
                      />
                    </div>
                    <div className="mt-5">
                      <TextFiled
                        variant={"general"}
                        label={"Company name (Optional)"}
                        name="companyName"
                        value={formik.values.companyName}
                        placeholder={"Enter your company name"}
                        onChange={formik?.handleChange}
                        error={formik.touched.companyName && formik.errors.companyName ? formik.errors.companyName : null}
                      />
                    </div>

                    <div className="flex items-center justify-between mt-10 w-full ">

                      <StepBackButton onClick={() => handleStepChange(step - 1)} />

                      <MainButton
                        variant={"extrasmall"}
                        onClick={formik.handleSubmit
                        //   () => {
                        //   setStep(5);
                        // }
                      }
                      >
                        Next
                      </MainButton>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <WrittenStepper
              currentStep={step}
              lastStep={8}
              currentLabel={"Business information"}
              marginTop={"absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full"}
            />
          </>
        )}
        {step === 5 && (
          <>
            <SelectService 
              onClickHandler={() => {
                handleStepChange(6);
              }}
            />
            <WrittenStepper
              currentStep={step}
              lastStep={8}
              currentLabel={"Select your service"}
              marginTop={"absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full"}
            />
          </>
        )}
        {step === 6 && (
          <>
            <Location
              onClickHandler={() => {
                handleStepChange(7);
                setRequetStart(false);
              }}
            />
            <WrittenStepper
              currentStep={step}
              lastStep={8}
              currentLabel={"Set your location"}
              marginTop={"absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full"}
            />
          </>
        )}
        {step === 7 && (
          <>
            <AddProfilePicture
              onClickHandler={() => {
                handleStepChange(8);
              }}
            />
            <WrittenStepper
              currentStep={step}
              lastStep={8}
              currentLabel={"Upload your logo"}
              marginTop={"absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full"}
            />
          </>
          
        )}
        {step === 8 && (
          <>
            <ProfilePreview
              step={step}
              lastStep={8}
              onClickHandler={onClickHandler}
            />
            <WrittenStepper
              currentStep={step}
              lastStep={8}
              currentLabel={"Confirm & Preview profile"}
              marginTop={"absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full"}
            />
          </>
        )}
      </div>
    </>
  );
};

export default BusinessInfo;
