import React from 'react';
import MainButton from '../MainButton/MainButton';
import AuxiliaryText from '../Typography/AuxiliaryText';

const AuthButtons = ({
  variant = 'desktop', // 'desktop' or 'mobile'
  onSignIn,
  onSignUp,
  onRequestQuote,
  className = '',
  showSignIn = true,
  showSignUp = true,
  showRequestQuote = true,
  hoverColor = 'hover:text-primaryblue'
}) => {
  const buttonClasses = {
    desktop: {
      container: 'flex items-center',
      signIn: 'lg:mr-8 mb-6 lg:mb-0 cursor-pointer px-2 py-1',
      signUp: 'lg:mr-8 mb-6 lg:mb-0 cursor-pointer px-2 py-1',
      requestQuote: ''
    },
    mobile: {
      container: 'grid grid-cols-1',
      signIn: 'lg:mr-8 mb-1 lg:mb-0 cursor-pointer px-2 py-2 rounded-md text-center',
      signUp: 'lg:mr-8 mb-6 lg:mb-0 cursor-pointer px-2 py-2 rounded-md text-center',
      requestQuote: ''
    }
  };

  const classes = buttonClasses[variant];

  return (
    <div className={`${classes.container} ${className}`}>
      {showSignIn && (
        <span
          className={`${classes.signIn} ${hoverColor}`}
          onClick={onSignIn}
        >
          <AuxiliaryText variant="NavigationText">
            Sign in
          </AuxiliaryText>
        </span>
      )}

      {showSignUp && (
        <span
          className={`${classes.signUp} ${hoverColor}`}
          onClick={onSignUp}
        >
          <AuxiliaryText variant="NavigationText">
            Sign up
          </AuxiliaryText>
        </span>
      )}

      {showRequestQuote && (
        <MainButton
          variant="auto"
          onClick={onRequestQuote}
          className={classes.requestQuote}
        >
          Request a free Quote
        </MainButton>
      )}
    </div>
  );
};

export default AuthButtons; 