import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Flip, toast, ToastContainer } from "react-toastify";
import { useFormik } from "formik";
import MainButton from "../../../components/common/MainButton/MainButton";
import TextFiled from "../../../components/common/InputField/TextFiled";
import Heading from "../../../components/common/Typography/Heading";
import Paragraph from "../../../components/common/Typography/Paragraph";
import InteractiveText from "../../../components/common/Typography/InteractiveText";
import AuxiliaryText from "../../../components/common/Typography/AuxiliaryText";
import WrittenStepper from "../../../components/common/StepIndicater/WrittenStepper";
import SpecialText from "../../../components/common/Typography/SpecialText";
import Spinner from "../../../components/common/Spinner";

import { verificationCodeSchema } from "../../../FormValidations/SignUpSchema";
import { setField, resetForm } from "../../../Redux/Slice/SignUp";
import { SendOtpMutation, VerifyOtpMutation, RegisterUserMutation } from "../../../services/SignUp";
import { useGraphQL } from "../../../context/GraphQLProvider";

const Verify = ({ label, closePopup }) => {
  const graphqlService = useGraphQL();
  const dispatch = useDispatch();
  const [RequestStart, setRequetStart] = useState(false);
  const [step, setStep] = useState("A");
  const InitialValues = useSelector((state) => state.signup);
  const { id, email, password } = useSelector((state) => state.signup);
  // console.log('123');
  // console.log(InitialValues);
  // console.log('456');

  const ResendOtpUser = (gql, email) => {
    setRequetStart(true);
    SendOtpMutation(gql, {
      email,
    })
    .then((response) => {
        if (response.sendOtp === 'OTP sent successfully'){
          setRequetStart(false);
          toast.success("Verification Code Sent!", {
            position: toast.POSITION.BOTTOM_RIGHT,
            transition: Flip,
          });
        }
    })
    .catch((error) => {
      console.log("error", error);
    });
  };

  const RegisterUser = (gql, userInput) => {
    RegisterUserMutation(gql, userInput)
    .then((response) => {
      console.log(response);
        if (response.registerUser?.id){
          const userId = response.registerUser?.id
          dispatch(setField({id: userId}))
          setStep("B");
          setTimeout(closePopup, 3000);
          setRequetStart(false);
        }
    })
    .catch((error) => {
      console.log("error", error);
    })
  };

  const formik = useFormik(
      {
        initialValues: InitialValues,
        validationSchema: verificationCodeSchema,
        onSubmit: (values, { setErrors, setSubmitting }) => {
          setRequetStart(true);
          const currentDateTime = new Date().toISOString();
          dispatch(setField({ ...values, startDate: currentDateTime }));
          const otp = values.verificationCode;

          VerifyOtpMutation(graphqlService, { email, otp })
            .then((response) => {
              console.log(response);
              if (response.verifyOtp.message === 'OTP verified successfully') {
                const userInput = {
                  "userInput": {
                    "personalEmail": email,
                    "password": password,
                    "startDate": currentDateTime
                  }
                };
                RegisterUser(graphqlService, userInput);
              } else if (response.verifyOtp.message === 'Error verifying OTP') {
                setErrors({ verificationCode: "Invalid OTP" });
                setRequetStart(false);
              }
            })
            .catch((error) => {
              console.log("error", error);
              setRequetStart(false);
            });
        },
      }
    );

  return (
    <>
      {RequestStart && <Spinner />}
      <ToastContainer autoClose={2000} />
      {step === "A" && (
        <div className="w-[300px] sm:w-[350px] md:w-[380px] lg:w-[400px] mx-auto h-[550px] overflow-auto hidden-scrollbar lg:h-[800px] lg:overflow-hidden pb-[550px] lg:pb-0 relative">
          <Heading
            variant={"h6"}
            className={"text-center text-offblack mt-[60px] xl:mt-[120px]"}
          >
            Verify your email
          </Heading>
          <div className="mx-auto rounded-lg mb-[16px] w-full mt-10 xl:mt-[60px]">
            <div className="max-w-[400px] mx-auto">
              <Paragraph
                variant={"MainParagraph"}
                className={"text-offblack text-start"}
              >
                We've sent a verification link to your email. Please click the
                link or enter the 6-digit code to verify your account.
              </Paragraph>
            </div>
            <div className="mt-10 w-full">
              <TextFiled
                variant={"general"}
                label={label}
                name="verificationCode"
                placeholder={"Input the code here."}
                onChange={formik?.handleChange}
                value={formik?.values?.verificationCode}
              />
              {formik.touched.verificationCode && formik.errors.verificationCode ? (
                  <div className="text-alertred">
                    <SpecialText
                      variant={"FootNoteDisclaimer"}
                      className={"mt-1"}
                    >
                      {formik.errors.verificationCode}
                    </SpecialText>
                  </div>
                ) : null}
              <div className="flex items-center justify-between mt-1">
                <AuxiliaryText variant={"Infographic"} className={"mr-2"}>
                  Didn't get the code?
                </AuxiliaryText>
                <InteractiveText
                  variant={"ActionLink"}
                  className={"text-primaryblue cursor-pointer"}
                  onClick={() => {
                    ResendOtpUser(graphqlService,email)
                  }}
                >
                  Resend
                </InteractiveText>
              </div>
            </div>
            <div className="flex items-center justify-end mt-10">
              <MainButton
                variant={"extrasmall"}
                onClick={
                  // () => {
                  formik.handleSubmit
                  // setStep("B");
                  // setTimeout(closePopup, 3000);
                // }
              }
              >
                Next
              </MainButton>
            </div>
          </div>
          <WrittenStepper
            currentStep={2}
            lastStep={8}
            currentLabel={"Verify your email"}
            marginTop={"absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full"}
          />
        </div>
      )}

      {step === "B" && (
        <>
          <div className="h-[550px] overflow-auto hidden-scrollbar lg:h-[800px] lg:overflow-hidden pb-[550px] lg:pb-0 relative">
            <div className="flex items-center justify-center w-[300px] sm:w-[350px] md:w-[380px] lg:w-[400px] mt-[60px] xl:mt-[120px] mx-auto ">
              {/* <i className="fi fi-sr-check text-[30px] text-primaryblue mr-5"></i> */}
              <Heading variant={"h6"} className={"text-center text-offblack"}>
                Email verified !!!
              </Heading>
            </div>
            <div className="w-[300px] sm:w-[350px] md:w-[380px] lg:w-[400px] mx-auto mt-10 xl:mt-[60px]">
              <Paragraph
                variant={"MainParagraph"}
                className={"text-offblack text-start"}
              >
                Your business email has been successfully verified. You're now
                ready to receive updates and notifications directly to your
                inbox.
              </Paragraph>
            </div>
            <WrittenStepper
              currentStep={2}
              lastStep={8}
              currentLabel={"Verify your email"}
              marginTop={"absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full"}
            />
          </div>
        </>
      )}
    </>
  );
};

export default Verify;
