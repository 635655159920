import { IconContext } from "react-icons";
import { BsEyeFill as Eye, BsEyeSlashFill as SlashEye } from "react-icons/bs";
import InlineError from "../InlineError/InlineError";

export default function PasswordField({ className, error, ...restProps }) {
  const _passwordtype =
    restProps?.passwordtype === "true" ? "password" : "text";
  return (
    <div className="relative w-[300px] sm:w-[350px] md:w-[370px] lg:w-[370px]">
      <div className="relative h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px]">
        <input
          {...restProps}
          name={restProps?.name}
          type={_passwordtype}
          className={`bg-white focus:border focus:border-brightblue placeholder-lightgray placeholder:font-inter placeholder:font-normal placeholder:normal-case placeholder:leading-[1.4] placeholder:tracking-[0px] placeholder:text-[11px] placeholder:sm:text-[11px] placeholder:md:text-[12px] placeholder:lg:text-[12px] placeholder:xl:text-[11px] placeholder:2xl:text-[13px] placeholder:3xl:text-[13px] text-offblack font-inter font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[11px] 2xl:text-[13px] 3xl:text-[13px] rounded-lg w-full h-full block p-2.5 ${className}`}
        />
        <IconContext.Provider
          value={{
            className: `size-4 absolute right-4 top-4 sm:top-4 lg:right-3 xl:right-3 xl:top-5 cursor-pointer`,
          }}
        >
          {restProps.passwordtype === "true" ? (
            <SlashEye
              onClick={() =>
                restProps?.setpasswordtype(
                  restProps?.passwordtype === "true" ? "false" : "true"
                )
              }
            />
          ) : (
            <Eye
              onClick={() =>
                restProps?.setpasswordtype(
                  restProps?.passwordtype === "false" ? "true" : "false"
                )
              }
            />
          )}
        </IconContext.Provider>
      </div>
      <InlineError message={error} />
    </div>
  );
}
// className={`