import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AlertsContainer = () => {
    return (
        <ToastContainer/>
    );
};

export default AlertsContainer; 