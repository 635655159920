import React from "react";
import DashboardAdsCard from "../../../../components/common/AdsCard/DashboardAdsCard";
import DefaultImage from "../../../../assets/img/default-image.jpg";
import InteractiveText from "../../../../components/common/Typography/InteractiveText";
import MainButton from "../../../../components/common/MainButton/MainButton";

const PostReview = ({ formik, type, data, setStep, values }) => {
  console.log("Data", values)
  return (
    <>
      <div className="flex items-center justify-center w-fit mx-auto mt-[60px]">
      <DashboardAdsCard
          // key={index}
          // serviceName={formik?.values?.listTitle}
          // serviceDescription={formik?.values?.description}
          // category={formik?.values?.listCategory?.toString() ?? ""}
          type={type}
          // Images={[DefaultImage]}
          // rating={formik.listRating}
          postTitle={values.postTitle ?? ""}
          // companyLogo={data?.companyLogo ?? DefaultImage}
          serviceName={"Engineering Services"}
          serviceDescription={
            "In literary theory, whether this object is a work of literature, a street sign, an arrangement of buildings on a city block, or styles of clothing. It is a coherent set of signs that transmits some kind of informative message."
          }
          category={"Engineering Services"}
          // type={"Featured"}
          Images={[values.portfolioImages?.[0]?.url ?? null]}
          // rating={formik.listRating}
          // postTitle={"Building Experts"}
          companyLogo={data?.companyLogo ?? DefaultImage}
        />
      </div>
      <div className="flex items-center justify-between w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mt-10 mx-auto">
        <div  onClick={() => setStep(3)}>
          <InteractiveText
            variant={"ActionLink"}
            className={"text-mediumgray hover:text-primaryblue cursor-pointer"}
          >
            Back
          </InteractiveText>
        </div>
        <div>
          <MainButton
          type="button"
            variant={"small"}
          >
            Publish
          </MainButton>
        </div>
      </div>
    </>
  );
};

export default PostReview;
