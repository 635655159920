import { store } from '../Redux/store';

/**
 * Transforms form values into userInput format by removing specific fields and transforming email to personalEmail
 * @param {Object} currentValues - Current form values from the component
 * @param {Array} fieldsToRemove - Array of field names to remove from the form values
 * @returns {Object} The transformed userInput object
 */
export const transformToUserInput = (currentValues, fieldsToRemove = ['step', 'verificationCode']) => {
    // Create a copy of the form values
    const values = { ...currentValues };
    
    // Transform email to personalEmail
    if (values.email) {
        values.personalEmail = values.email;
        delete values.email;
    }
    
    // Remove specified fields
    fieldsToRemove.forEach(field => {
        delete values[field];
    });

    // Return the transformed object
    return {
        "userInput": values
    };
}; 