import React from "react";
import RangeSliderLocation from "../../../../components/common/InputField/RangeSliderLocation";
import TextFiled from "../../../../components/common/InputField/TextFiled";
import Snippet from "../../../../components/common/SnippetIcon/Snippet";
import InteractiveText from "../../../../components/common/Typography/InteractiveText";
import MainButton from "../../../../components/common/MainButton/MainButton";
import { Field, ErrorMessage } from "formik";
import SpecialText from "../../../../components/common/Typography/SpecialText";

const LocationandPortfolio = ({ setStep, setFieldValue, values }) => {
  return (
    <>
      <div className="w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto mt-[60px]">
        <div>
          <Snippet
            variant={"label"}
            label={"Customers Within"}
            text={
              "Determine a radius to target customers from your specified location."
            }
            snippet={true}
          />
        </div>
        <Field name="range">
          {({ field, form }) => (
            <div>
              <RangeSliderLocation
                {...field}
                starting={5}
                Maximum={10}
                units={"km"}
                values={values.range}
                value={values.range}
                onChange={(e) => setFieldValue("range", e.target.value)}
              />
              <SpecialText
                variant={"FootNoteDisclaimer"}
                className={"mt-2 text-alertred"}
              >
                {form.errors.range}
              </SpecialText>
            </div>
          )}
        </Field>

        <div className="mt-5">
          <Field name="location">
            {({ field, form }) => (
              <div>
                <TextFiled
                  {...field}
                  onChange={(e) => setFieldValue("location", e.target.value)}
                  value={values.location}
                  name="location"
                  className={"bg-white lg:bg-pastalblue"}
                  variant={"large"}
                  label={"Post on this Location"}
                  placeholder={
                    "Input the specific area, suburb, or postcode for your services"
                  }
                  star={true}
                />
                <SpecialText
                  variant={"FootNoteDisclaimer"}
                  className={"mt-2 text-alertred"}
                >
                  {form.errors.location}
                </SpecialText>
              </div>
            )}
          </Field>
        </div>
      </div>

      {/* Buttons and navigation */}
      <div className="flex items-center justify-between w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mt-10 mx-auto">
        <div onClick={() => setStep(3)}>
          <InteractiveText
            variant={"ActionLink"}
            className="text-mediumgray hover:text-primaryblue cursor-pointer"
          >
            Back
          </InteractiveText>
        </div>
        <div>
          <MainButton
            type="submit"
            onClick={() => {
              if (values.location && values.range) {
                setStep(5); // Move to portfolio step
              }
            }}
            variant={"small"}
          >
            Next
          </MainButton>
        </div>
      </div>
    </>
  );
};

export default LocationandPortfolio;
