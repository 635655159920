import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heading from "../../components/common/Typography/Heading";
import ReactTyped from "react-typed";
import { fetchServiceCategories } from "../../Redux/Slice/serviceCategoriesSlice";
import { useGraphQL } from "../../context/GraphQLProvider";

const MainBanner = () => {
  const graphqlService = useGraphQL();
  const dispatch = useDispatch();
  const { categories = [], loading } = useSelector((state) => state.serviceCategories);

  useEffect(() => {
    if (categories.length === 0 && !loading) {
      dispatch(fetchServiceCategories(graphqlService));
    }
  }, [dispatch, categories.length, loading, graphqlService]);

  const serviceNames = categories?.length > 0 
    ? categories.map(category => category.name)
    : ["Services..."];

  return (
    <>
      <div
        className={`w-[100%] h-screen sm:h-[1000px] lg:h-screen relative bg-no-repeat bg-center object-cover bg-cover main-banner`}
      >
        <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] h-full ">
          <Heading
            variant={"h1"}
            className={
              "text-start text-white w-full pt-[50vh] sm:pt-[50vh] md:pt-[50vh] lg:pt-[50vh] xl:pt-[50vh] 2xl:pt-[500px]"
            }
          >
            SEARCH. Connect. <br /> Complete.
          </Heading>
          <Heading
            variant={"h6"} className={"text-white"}>
            <ReactTyped
              strings={serviceNames}
              typeSpeed={100}
              backSpeed={100}
              loop
            />
          </Heading>
        </div>
      </div>
    </>
  );
};

export default MainBanner;
