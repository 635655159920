import React from "react";
import SecondaryHeading from "../Typography/SecondaryHeading";

const WrittenStepper = ({ currentStep, lastStep,currentLabel, marginTop }) => {
  return (
    <>
      <div className={`flex justify-center ${marginTop}`}>
        <SecondaryHeading variant={"MainParagraphHeading"} className={"text-offblack"}>
          Step {currentStep} to {lastStep} -{" "}
          {currentLabel}
        </SecondaryHeading>
      </div>
    </>
  );
};

export default WrittenStepper;
