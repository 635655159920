// import SpellChecker from 'spell-checker-js';

// const spellChecker = new SpellChecker();

export const validateText = (text) => {
  if (!text) return { isValid: false, message: 'Text is required' };

  // Create a temporary div element
  const tempDiv = document.createElement('div');
  tempDiv.contentEditable = true;
  tempDiv.spellcheck = true;
  tempDiv.textContent = text;
  document.body.appendChild(tempDiv);

  // Get the browser's spell check results
  const spellCheckResults = tempDiv.getElementsByClassName('spellcheck-error');
  const invalidWords = Array.from(spellCheckResults).map(el => el.textContent);

  // Clean up
  document.body.removeChild(tempDiv);

  if (invalidWords.length > 0) {
    return {
      isValid: false,
      message: `These words don't seem to be valid: ${invalidWords.join(', ')}`,
      suggestedMessage: 'Please check your spelling and ensure all words are correctly written.'
    };
  }

  return { isValid: true };
}; 