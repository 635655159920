import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mainCategory: "",
  subCategory: "",
  customSubcategory: "",
  postTitle: "",
  postTags: [],
  description: "",
  range: 10,
  location: "",
  portfolioImages: [],
};

const createPostSlice = createSlice({
  name: "createPost",
  initialState,
  reducers: {
    updatePostData: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetPostData: (state) => {
      return initialState;
    },
  },
});

export const { updatePostData, resetPostData } = createPostSlice.actions;
export default createPostSlice.reducer; 