import { ApolloClient, InMemoryCache, HttpLink, gql, split } from "@apollo/client";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";

class GraphQLService {
    constructor(apiUrl) {
        // Create both types of links
        const httpLink = new HttpLink({ uri: apiUrl });
        const uploadLink = createUploadLink({ uri: apiUrl });

        // Split the traffic between upload and regular requests
        const splitLink = split(
            // Predicate function to determine which link to use
            operation => {
                const isFileUpload = operation.variables && Object.values(operation.variables).some(value => value instanceof File);
                return isFileUpload;
            },
            uploadLink, // If true, use upload link
            httpLink   // If false, use http link
        );

        this.client = new ApolloClient({
            link: splitLink,
            cache: new InMemoryCache(),
        });
    }

    getClient() {
        return this.client;
    }

    async fetchData(query, variables = null) {
        try {
            const queryOptions = {
                query: gql`${query}`,
            };

            // Only add variables if they exist
            if (variables) {
                queryOptions.variables = variables;
            }

            const response = await this.client.query(queryOptions);
            return response.data;
        } catch (error) {
            console.error("GraphQL Error:", error);
            throw error;
        }
    }

    async mutateData(mutation, variables) {
        try {
            const response = await this.client.mutate({
                mutation: gql`${mutation}`,
                variables: variables,
            });
            return response.data;
        } catch (error) {
            console.error("GraphQL Mutation Error:", error);
            throw error;
        }
    }
}

export default GraphQLService;